import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  admin_get_school_terms,
  delete_school_term,
} from "../../../../redux/actions/course";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import AddSchoolTerm from "./components/AddSchoolTerm";
import EditSchoolTerm from "./components/EditSchoolTerm";

const SchoolTerms = (props) => {
  const { admin_school_terms, loading, userId } = props;
  const { startLoading, adminGetSchoolTerms, deleteSchoolTerm } = props;

  const [openAddSchoolTerm, setOpenAddSchoolTerm] = useState(false);
  const [openEditSchoolTerm, setOpenEditSchoolTerm] = useState(false);
  const [currentSchoolTerm, setCurrentSchoolTerm] = useState({});

  useEffect(() => {
    if (admin_school_terms?.length === 0 && userId) {
      startLoading();
      adminGetSchoolTerms(userId);
    }
  }, [userId, admin_school_terms?.length, adminGetSchoolTerms, startLoading]);

  const handleOpenEditSchoolTerm = (school_term) => {
    setCurrentSchoolTerm(school_term);
    setOpenEditSchoolTerm(true);
  };

  const handleDeleteSchoolTerm = (schoolTermId) => {
    if (
      window.confirm(
        "Deleted terms cannot be recovered, are you sure you want to continue?"
      )
    ) {
      startLoading();
      deleteSchoolTerm(userId, schoolTermId);
    }
  };

  return (
    <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
      <div class="table__parentHeader">
        <button
          className="add__button"
          onClick={() => setOpenAddSchoolTerm(true)}
        >
          Create Term
        </button>
        <h3>Maintain school terms</h3>
      </div>
      {loading && (
        <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />
      )}
      {admin_school_terms?.length > 0 ? (
        <table class="table__listing" rules="all" border="1">
          <thead>
            <tr class="table__listingHeader">
              <th>Name</th>
              <th>Status</th>
              <th>Starting</th>
              <th>Ending</th>
              <th>Edit</th>
              <th className="tc">Delete</th>
            </tr>
          </thead>
          <tbody>
            {admin_school_terms?.map((term) => (
              <tr class="table__listingItem" key={term?.id}>
                <td>{term?.name}</td>
                <td>
                  {term?.is_active ? (
                    <span className="green">Active</span>
                  ) : (
                    <span className="red">Inactive</span>
                  )}
                </td>
                <td>{moment(term?.starting_date).format("LL")}</td>
                <td>{moment(term?.ending_date).format("LL")}</td>
                <td
                  onClick={() => handleOpenEditSchoolTerm(term)}
                  className="dodgerblue button__sp bd"
                >
                  Edit
                </td>
                <td className="tc">
                  <i
                    class="bx bxs-trash-alt red button__sp"
                    onClick={() => handleDeleteSchoolTerm(term?.id)}
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <h4 class="not__available">No school terms created yet</h4>
      )}

      {/* child components */}
      {openAddSchoolTerm && (
        <AddSchoolTerm
          openAddSchoolTerm={openAddSchoolTerm}
          setOpenAddSchoolTerm={setOpenAddSchoolTerm}
        />
      )}
      {openEditSchoolTerm && (
        <EditSchoolTerm
          openEditSchoolTerm={openEditSchoolTerm}
          setOpenEditSchoolTerm={setOpenEditSchoolTerm}
          currentSchoolTerm={currentSchoolTerm}
          setCurrentSchoolTerm={setCurrentSchoolTerm}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    admin_school_terms: state?.course?.admin_school_terms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    adminGetSchoolTerms: (userId) => dispatch(admin_get_school_terms(userId)),
    deleteSchoolTerm: (userId, schoolTermId) =>
      dispatch(delete_school_term(userId, schoolTermId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolTerms);
