import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get_teacher_learning_areas } from "../../../redux/actions/course";
import { teacher_get_summative_assessments } from "../../../redux/actions/exam";
import { TOGGLE_LOADING } from "../../../redux/actions/types";
import SummativeAssessmentOptions from "./components/SummativeAssessmentOptions";

const SummativeAssessments = (props) => {
  const { userId, loading, teacher_learning_areas, summative_assessments } =
    props;
  const {
    startLoading,
    teacherGetSummativeAssessments,
    getTeacherLearningAreas,
  } = props;

  const [openSummativeAssessmentOptions, setOpenSummativeAssessmentOptions] =
    useState(false);

  useEffect(() => {
    if (userId && teacher_learning_areas?.length === 0) {
      startLoading();
      getTeacherLearningAreas(userId);
    }
  }, [
    startLoading,
    getTeacherLearningAreas,
    teacher_learning_areas?.length,
    userId,
  ]);

  useEffect(() => {
    if (userId && summative_assessments?.length === 0) {
      startLoading();
      teacherGetSummativeAssessments(userId);
    }
  }, [
    userId,
    summative_assessments?.length,
    teacherGetSummativeAssessments,
    startLoading,
  ]);

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div className="table__columns">
          {/* column 1 */}
          <div className="column">
            <h3>Perform / update an assessment</h3>
            <br />
            <div className="buttons">
              <button
                type="button"
                className="add__button"
                onClick={() => setOpenSummativeAssessmentOptions(true)}
              >
                Perform Assessment
              </button>
            </div>
          </div>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
      </div>
      {/* child components */}
      {openSummativeAssessmentOptions && (
        <SummativeAssessmentOptions
          openSummativeAssessmentOptions={openSummativeAssessmentOptions}
          setOpenSummativeAssessmentOptions={setOpenSummativeAssessmentOptions}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    teacher_learning_areas: state?.course?.teacher_learning_areas,
    summative_assessments: state?.exam?.summative_assessments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    teacherGetSummativeAssessments: (userId) =>
      dispatch(teacher_get_summative_assessments(userId)),
    getTeacherLearningAreas: (userId) =>
      dispatch(get_teacher_learning_areas(userId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummativeAssessments);
