import { useState } from "react";
import MaxDialog from "../../../../components/common/MaxDialog";
import UseRubric from "./UseRubric";

const RubricDetails = (props) => {
  const { openRubricDetails, currentRubric, currentGradeStreamLearningAreaId } =
    props;
  const { setOpenRubricDetails } = props;

  const [openUseRubric, setOpenUseRubric] = useState(false);

  return (
    <>
      <MaxDialog isOpen={openRubricDetails} maxWidth="1000px" maxHeight="85vh">
        <div class="dialog">
          <div class="dialog__rowSingleItem">
            <label for="">
              <u>Rubric Name</u>
            </label>
            <p>{currentRubric?.title}</p>
          </div>
          <div class="dialog__rowSingleItem">
            <label for="">
              <u>Performance Levels</u>
            </label>
          </div>
          <div class="dialog__row">
            <span>
              <label for="">Exceeds Expectations</label>
              <textarea value={currentRubric?.exceeds_expectations} readOnly />
            </span>
            <span>
              <label for="">Meets Expectations</label>
              <textarea value={currentRubric?.meets_expectations} readOnly />
            </span>
          </div>
          <div class="dialog__row">
            <span>
              <label for="">Approaches Expectations</label>
              <textarea
                value={currentRubric?.approaches_expectations}
                readOnly
              />
            </span>
            <span>
              <label for="">Below Expectations</label>
              <textarea value={currentRubric?.below_expectations} readOnly />
            </span>
          </div>
          <div class="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenRubricDetails(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="continue__button"
              onClick={() => setOpenUseRubric(true)}
            >
              Use Rubric
            </button>
          </div>
        </div>
      </MaxDialog>
      {/* child components */}
      {openUseRubric && (
        <UseRubric
          openUseRubric={openUseRubric}
          setOpenUseRubric={setOpenUseRubric}
          currentRubric={currentRubric}
          currentGradeStreamLearningAreaId={currentGradeStreamLearningAreaId}
        />
      )}
    </>
  );
};

export default RubricDetails;
