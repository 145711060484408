import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import {
  delete_grade_stream,
  teacher_get_grade_streams,
} from "../../../../redux/actions/course";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import AddGradeStream from "./components/AddGradeStream";
import GradeStreamLearningAreas from "./components/GradeStreamLearningAreas";
import EditGradeStream from "./components/EditGradeStream";

const GradeStreams = (props) => {
  const { loading, userId, grade_streams } = props;
  const { startLoading, teacherGetGradeStreams, deleteGradeStream } = props;

  const [openAddGradeStream, setOpenAddGradeStream] = useState(false);
  const [openEditGradeStream, setOpenEditGradeStream] = useState(false);
  const [openGradeStreamLearningAreas, setOpenGradeStreamLearningAreas] =
    useState(false);
  const [currentGradeStream, setCurrentGradeStream] = useState({});

  useEffect(() => {
    if (userId && grade_streams?.length === 0) {
      startLoading();
      teacherGetGradeStreams(userId);
    }
  }, [userId, grade_streams?.length, startLoading, teacherGetGradeStreams]);

  const handleOpenGradeStream = (grade_stream) => {
    setCurrentGradeStream(grade_stream);
    setOpenGradeStreamLearningAreas(true);
  };

  const handleOpenEditGradeStream = (grade_stream) => {
    setCurrentGradeStream(grade_stream);
    setOpenEditGradeStream(true);
  };

  const handleDeleteGradeStream = (gradeStreamId) => {
    if (
      window.confirm(
        `Once you delete a grade stream, you cannot recover it. Are you sure you want to continue?`
      )
    ) {
      startLoading();
      deleteGradeStream(userId, gradeStreamId);
    }
  };

  return (
    <>
      <div className="table__parent" id={loading && "formSubmitting"}>
        <div className="table__parentHeader">
          <button
            type="button"
            className="add__button"
            onClick={() => setOpenAddGradeStream(true)}
          >
            Add Grade Stream
          </button>
          <h3>My grade streams</h3>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {grade_streams?.length > 0 ? (
          <table className="table__listing" rules="all" border="1">
            <thead>
              <tr class="table__listingHeader">
                <th>Name</th>
                <th className="tc">Edit</th>
                <th className="tc">Learning Areas</th>
                <th className="tc">Delete</th>
              </tr>
            </thead>
            <tbody>
              {grade_streams?.map((grade_stream) => (
                <tr class="table__listingItem" key={grade_stream?.id}>
                  <td>{grade_stream?.name}</td>
                  <td
                    className="dodgerblue bd button__sp tc"
                    onClick={() => handleOpenEditGradeStream(grade_stream)}
                  >
                    edit
                  </td>
                  <td
                    className="dodgerblue bd button__sp tc"
                    onClick={() => handleOpenGradeStream(grade_stream)}
                  >
                    view
                  </td>
                  <td className="tc">
                    <i
                      className="bx bxs-trash-alt red button__sp"
                      onClick={() => handleDeleteGradeStream(grade_stream?.id)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 className="not__available">No grade streams added yet</h4>
        )}
      </div>
      {openAddGradeStream && (
        <AddGradeStream
          openAddGradeStream={openAddGradeStream}
          setOpenAddGradeStream={setOpenAddGradeStream}
        />
      )}
      {openGradeStreamLearningAreas && (
        <GradeStreamLearningAreas
          openGradeStreamLearningAreas={openGradeStreamLearningAreas}
          setOpenGradeStreamLearningAreas={setOpenGradeStreamLearningAreas}
          currentGradeStream={currentGradeStream}
        />
      )}
      {openEditGradeStream && (
        <EditGradeStream
          openEditGradeStream={openEditGradeStream}
          setOpenEditGradeStream={setOpenEditGradeStream}
          currentGradeStream={currentGradeStream}
          setCurrentGradeStream={setCurrentGradeStream}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    grade_streams: state?.course?.grade_streams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    teacherGetGradeStreams: (userId) =>
      dispatch(teacher_get_grade_streams(userId)),
    deleteGradeStream: (userId, gradeStreamId) =>
      dispatch(delete_grade_stream(userId, gradeStreamId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GradeStreams);
