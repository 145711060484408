import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get_teachers } from "../../../../redux/actions/auth";
import {
  admin_get_grade_levels,
  delete_grade_level,
} from "../../../../redux/actions/course";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import AddGradeLevel from "./components/AddGradeLevel";
import EditGradeLevel from "./components/EditGradeLevel";

const GradeLevels = (props) => {
  const { admin_grade_levels, userId, loading, teachers } = props;
  const {
    adminGetGradeLevels,
    startLoading,
    getTeachers,
    deleteGradeLevel,
    deleteGradeStream,
    deleteStreamName,
  } = props;

  const [openAddGradeLevel, setOpenAddGradeLevel] = useState(false);
  const [openEditGradeLevel, setOpenEditGradeLevel] = useState(false);

  const [currentGradeLevel, setCurrentGradeLevel] = useState({});

  useEffect(() => {
    if (userId && admin_grade_levels?.length === 0) {
      startLoading();
      adminGetGradeLevels(userId);
    }
  }, [startLoading, adminGetGradeLevels, userId, admin_grade_levels?.length]);

  useEffect(() => {
    if (userId && teachers?.length === 0) {
      startLoading();
      getTeachers(userId);
    }
  }, [startLoading, getTeachers, userId, teachers?.length]);

  const handleOpenEditGradeLevel = (grade_level) => {
    setCurrentGradeLevel(grade_level);
    setOpenEditGradeLevel(true);
  };

  const handleDeleteGradeLevel = (gradeLevelId) => {
    if (
      window.confirm(
        `Once you delete a grade level, you cannot recover it. Are you sure you want to continue?`
      )
    ) {
      startLoading();
      deleteGradeLevel(userId, gradeLevelId);
    }
  };
  const handleDeleteStreamName = (streamNameId) => {
    if (
      window.confirm(
        `Once you delete a stream name, you cannot recover it. Are you sure you want to continue?`
      )
    ) {
      startLoading();
      deleteStreamName(userId, streamNameId);
    }
  };
  const handleDeleteGradeStream = (gradeStreamId) => {
    if (
      window.confirm(
        `Once you delete a grade stream, you cannot recover it. Are you sure you want to continue?`
      )
    ) {
      startLoading();
      deleteGradeStream(userId, gradeStreamId);
    }
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div class="table__parentHeader">
          <button
            type="button"
            className="add__button"
            onClick={() => setOpenAddGradeLevel(true)}
          >
            Add Grade Level
          </button>
          <h3 className="tc dodgerblue ud">Grade levels</h3>
        </div>

        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}

        <table class="table__listing" rules="all" border="1">
          <thead>
            <tr class="table__listingHeader">
              <th>Name</th>
              <th className="tc">Status</th>
              <th className="tc">Edit</th>
              <th className="tc">Delete</th>
            </tr>
          </thead>
          <tbody>
            {admin_grade_levels?.map((grade_level) => (
              <tr class="table__listingItem" key={grade_level?.id}>
                <td>{grade_level?.name}</td>
                <td className="tc">
                  {grade_level?.is_active ? (
                    <span className="green">Active</span>
                  ) : (
                    <span className="red">Inactive</span>
                  )}
                </td>
                <td
                  className="dodgerblue bd button__sp tc"
                  onClick={() => handleOpenEditGradeLevel(grade_level)}
                >
                  Edit
                </td>
                <td className="tc">
                  <i
                    class="bx bxs-trash-alt red button__sp"
                    onClick={() => handleDeleteGradeLevel(grade_level?.id)}
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* child components */}
      {openAddGradeLevel && (
        <AddGradeLevel
          openAddGradeLevel={openAddGradeLevel}
          setOpenAddGradeLevel={setOpenAddGradeLevel}
        />
      )}
      {openEditGradeLevel && (
        <EditGradeLevel
          openEditGradeLevel={openEditGradeLevel}
          setOpenEditGradeLevel={setOpenEditGradeLevel}
          currentGradeLevel={currentGradeLevel}
          setCurrentGradeLevel={setCurrentGradeLevel}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    admin_grade_levels: state?.course?.admin_grade_levels,
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    teachers: state?.auth?.teachers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminGetGradeLevels: (userId) => dispatch(admin_get_grade_levels(userId)),
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    getTeachers: (userId) => dispatch(get_teachers(userId)),
    deleteGradeLevel: (userId, gradeLevelId) =>
      dispatch(delete_grade_level(userId, gradeLevelId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GradeLevels);
