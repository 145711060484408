import API from "../shared/axios";

// patch user data
export const updateUser = (updatedUser) =>
  API.patch(`/api/user/user-maintain-self/`, updatedUser);

export const adminResetUserPassword = (userId, body) =>
  API.patch(
    `/api/user/school-admin/admin-reset-user-password/${userId}/`,
    body
  );

export const editGradeLevel = (userId, body) =>
  API.patch(`/api/course/school-admin/maintain-grade-levels/${userId}/`, body);

export const editGradeStream = (userId, body) =>
  API.patch(
    `/api/course/teacher/teacher-maintain-grade-streams/${userId}/`,
    body
  );

export const editLearningArea = (userId, body) =>
  API.patch(
    `/api/course/school-admin/maintain-learning-areas/${userId}/`,
    body
  );

export const changeStaffsGroups = (userId, body) =>
  API.patch(`/api/user/school-admin/change-staffs-groups/${userId}/`, body);

export const editTeacher = (userId, body) =>
  API.patch(`/api/user/school-admin/maintain-teachers/${userId}/`, body);

export const editOtherStaff = (userId, body) =>
  API.patch(`/api/user/school-admin/maintain-other-staff/${userId}/`, body);

export const editSchoolTerm = (userId, body) =>
  API.patch(`/api/course/school-admin/maintain-school-terms/${userId}/`, body);
