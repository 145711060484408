import { useEffect, useState } from "react";
import { connect } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";
import MaxDialog from "../../../../../components/common/MaxDialog";
import { showError } from "../../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";
import AddSubStrand from "./AddSubStrand";
import AdminRubrics from "./AdminRubrics";
import EditSubStrand from "./EditSubStrand";

const AdminSubStrands = (props) => {
  const { openSubStrands, currentStrand, userId, loading } = props;
  const { setOpenSubStrands, startLoading, stopLoading } = props;

  const [openAddSubStrand, setOpenAddSubStrand] = useState(false);
  const [subStrands, setSubStrands] = useState([]);
  const [openRubrics, setOpenRubrics] = useState(false);
  const [currentSubStrand, setCurrentSubStrand] = useState({});
  const [openEditSubStrand, setOpenEditSubStrand] = useState(false);

  useEffect(() => {
    if (userId && currentStrand?.id) {
      startLoading();
      const fetchSubStrands = async () => {
        await API.get(
          `/api/course/school-admin/admin-get-sub-strands/${userId}/${currentStrand?.id}/`
        )
          .then((res) => {
            setSubStrands(res?.data?.sub_strands_data);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchSubStrands();
    }
  }, [currentStrand?.id, userId, startLoading, stopLoading]);

  const handleOpenRubrics = (subStrand) => {
    setCurrentSubStrand(subStrand);
    setOpenRubrics(true);
  };

  const handleOpenEditSubStrand = (sub_strand) => {
    setCurrentSubStrand(sub_strand);
    setOpenEditSubStrand(true);
  };

  const handleDeleteSubStrand = async (subStrandId) => {
    if (
      window.confirm(
        "Once a substrand is deleted, it cannot be recovered. Are you sure you want to continue?"
      )
    ) {
      startLoading();
      await API.delete(
        `/api/course/school-admin/delete-sub-strand/${userId}/${subStrandId}/`
      )
        .then((res) => {
          setSubStrands(subStrands?.filter((item) => item?.id !== subStrandId));
          window.alert(res?.data?.detail);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  const handleAddNewSubStrand = async (body, resetForm) => {
    startLoading();
    API.post(`/api/course/school-admin/maintain-sub-strands/${userId}/`, body)
      .then((res) => {
        setSubStrands([...subStrands, res?.data?.new_sub_strand_data]);
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  const handleEditSubStrand = async (body) => {
    startLoading();
    API.patch(`/api/course/school-admin/maintain-sub-strands/${userId}/`, body)
      .then((res) => {
        setSubStrands(
          subStrands?.map((item) =>
            item?.id === res?.data?.updated_sub_strand_data?.id
              ? res?.data?.updated_sub_strand_data
              : item
          )
        );
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MaxDialog isOpen={openSubStrands} maxWidth="1200px" maxHeight="85vh">
        <div class="dialog" id={loading ? "pageSubmitting" : ""}>
          <h3>{currentStrand?.title} Substrands</h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          {subStrands?.length > 0 ? (
            <table class="table__listing" rules="all" border="1">
              <thead>
                <tr class="table__listingHeader">
                  <th>#</th>
                  <th>Title</th>
                  <th class="tc">Status</th>
                  <th className="tc">Edit</th>
                  <th className="tc">Rubrics</th>
                  <th className="tc">Delete</th>
                </tr>
              </thead>
              <tbody>
                {subStrands?.map((item) => (
                  <tr class="table__listingItem" key={item?.id}>
                    <td>{item?.num}</td>
                    <td>{item?.title}</td>
                    <td class="tc">
                      {item?.is_active ? (
                        <span className="green">Active</span>
                      ) : (
                        <span className="red">Inactive</span>
                      )}
                    </td>
                    <td
                      className="dodgerblue button__sp bd tc"
                      onClick={() => handleOpenEditSubStrand(item)}
                    >
                      Edit
                    </td>
                    <td
                      className="tc dodgerblue bd button__sp"
                      onClick={() => handleOpenRubrics(item)}
                    >
                      Rubrics
                    </td>
                    <td className="tc">
                      <i
                        class="bx bxs-trash-alt red button__sp"
                        onClick={() => handleDeleteSubStrand(item?.id)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 class="not__available">No sub strands created yet</h4>
          )}
          <div class="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenSubStrands(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="continue__button"
              onClick={() => setOpenAddSubStrand(true)}
            >
              Add SubStrand
            </button>
          </div>
        </div>
      </MaxDialog>

      {/* child components */}
      {openAddSubStrand && (
        <AddSubStrand
          openAddSubStrand={openAddSubStrand}
          setOpenAddSubStrand={setOpenAddSubStrand}
          currentStrand={currentStrand}
          handleAddNewSubStrand={handleAddNewSubStrand}
        />
      )}
      {openEditSubStrand && (
        <EditSubStrand
          openEditSubStrand={openEditSubStrand}
          setOpenEditSubStrand={setOpenEditSubStrand}
          currentSubStrand={currentSubStrand}
          setCurrentSubStrand={setCurrentSubStrand}
          handleEditSubStrand={handleEditSubStrand}
        />
      )}
      {openRubrics && (
        <AdminRubrics
          openRubrics={openRubrics}
          setOpenRubrics={setOpenRubrics}
          currentSubStrand={currentSubStrand}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSubStrands);
