// import installed packages
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import styles
import "./App.scss";
import Header from "./components/common/Header/Header";
import Sidebar from "./components/common/Sidebar/Sidebar";
import { get_user } from "./redux/actions/auth";
import globals from "./shared/globals";

import PrivateRoute from "./shared/PrivateRoute";
// import components/pages

// import Footer from "./components/common/Footer";
import ActivateAccount from "./pages/ActivateAccount/ActivateAccount";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import Profile from "./pages/Profile/Profile";
import ResetPasswordConfirm from "./pages/ResetPasswordConfirm";
import GradeLevels from "./pages/SchoolAdmin/Academics/GradeLevels/GradeLevels";
import LearningAreas from "./pages/SchoolAdmin/Academics/LearningAreas/LearningAreas";
import SchoolTerms from "./pages/SchoolAdmin/Academics/SchoolTerms/SchoolTerms";
import OtherStaff from "./pages/SchoolAdmin/Users/OtherStaff/OtherStaff";
import Teachers from "./pages/SchoolAdmin/Users/Teachers/Teachers";
import FormativeAssessments from "./pages/Teacher/Assessments/FormativeAssessments";
import PendingSubmissions from "./pages/Teacher/Assessments/PendingSubmissions";
import SummativeAssessments from "./pages/Teacher/Assessments/SummativeAssessments";
import FormativeReports from "./pages/Teacher/Reports/FormativeReports/FormativeReports";
import SummativeReports from "./pages/Teacher/Reports/SummativeReports/SummativeReports";
import GradeStreams from "./pages/Teacher/DataAreas/GradeStreams/GradeStreams";
import Exams from "./pages/Teacher/DataAreas/Exams/Exams";
import Students from "./pages/Teacher/DataAreas/Students/Students";

// import redux API

function App(props) {
  const { users_groups, loggedIn } = props;
  const dispatch = useDispatch();
  const session_cookie = localStorage.getItem("session_cookie");

  useEffect(() => {
    // get user on page refresh
    if (session_cookie) {
      dispatch(get_user());
    }
  }, [dispatch, session_cookie]);

  return (
    <div id="body-pd" className="body-pd">
      {/* WhatsApp support icon */}

      <Router>
        <Header />
        {loggedIn && <Sidebar />}
        <div className="main-content">
          <Routes>
            {/* unauthenticated routes */}
            <Route exact path="/" element={<Home />} />

            <Route
              exact
              path="/user/password-reset/:password_token/"
              element={<ResetPasswordConfirm />}
            />
            <Route
              exact
              path="/user/activate/:activation_token/"
              element={<ActivateAccount />}
            />
            {/* authenticated routes */}
            <Route exact path="/" element={<PrivateRoute />}>
              {["School Admin"]?.some((allowed_group) =>
                users_groups?.includes(allowed_group)
              ) && (
                <>
                  <Route
                    exact
                    path="/school-admin/school-terms/"
                    element={<SchoolTerms />}
                  />
                  <Route
                    exact
                    path="/school-admin/grade-levels/"
                    element={<GradeLevels />}
                  />
                  <Route
                    exact
                    path="/school-admin/learning-areas/"
                    element={<LearningAreas />}
                  />

                  <Route
                    exact
                    path="/school-admin/teachers/"
                    element={<Teachers />}
                  />
                  <Route
                    exact
                    path="/school-admin/other-staff/"
                    element={<OtherStaff />}
                  />
                </>
              )}
              {["Teacher"]?.some((allowed_group) =>
                users_groups?.includes(allowed_group)
              ) && (
                <>
                  <Route
                    exact
                    path="/teacher/formative-assessments/"
                    element={<FormativeAssessments />}
                  />
                  <Route
                    exact
                    path="/teacher/summative-assessments/"
                    element={<SummativeAssessments />}
                  />
                  <Route
                    exact
                    path="/teacher/pending-submissions/"
                    element={<PendingSubmissions />}
                  />

                  <Route
                    exact
                    path="/teacher/formative-reports/"
                    element={<FormativeReports />}
                  />
                  <Route
                    exact
                    path="/teacher/summative-reports/"
                    element={<SummativeReports />}
                  />
                  <Route
                    exact
                    path="/teacher/grade-streams/"
                    element={<GradeStreams />}
                  />
                  <Route
                    exact
                    path="/teacher/students/"
                    element={<Students />}
                  />
                  <Route exact path="/teacher/exams/" element={<Exams />} />
                </>
              )}
              <Route exact path="/profile" element={<Profile />} />
            </Route>
            {/* not found route */}
            <Route exact path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
      <button
        style={{
          position: "fixed",
          right: "30px",
          background: "green",
          bottom: "10px",
          borderRadius: "50px",
        }}
      >
        <a
          href={`https://web.whatsapp.com/send?phone=${globals?.customerSupportNumber}&text=`}
          target="_blank"
          rel="noreferrer"
          style={{
            fontSize: "3rem",
            color: "white",
          }}
        >
          <i class="bx bxl-whatsapp"></i>
        </a>
      </button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    users_groups: state.auth.user.users_groups,
    loggedIn: state?.auth?.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
