import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { add_school_term } from "../../../../../redux/actions/course";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";

const AddSchoolTerm = (props) => {
  const { openAddSchoolTerm, loading, userId } = props;
  const { setOpenAddSchoolTerm, startLoading, addSchoolTerm } = props;

  const [termDetails, setTermDetails] = useState({
    name: "",
    starting_date: "",
    ending_date: "",
  });
  const { name, starting_date, ending_date } = termDetails;

  const handleChange = (e) => {
    setTermDetails({ ...termDetails, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setTermDetails({
      name: "",
      starting_date: "",
      ending_date: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    addSchoolTerm(userId, { ...termDetails }, resetForm);
  };

  return (
    <MinDialog isOpen={openAddSchoolTerm} maxWidth="500px">
      <form
        class="dialog"
        onSubmit={handleSubmit}
        id={loading ? "formSubmitting" : ""}
      >
        <h3>Enter term details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Name</label>
          <input
            type="text"
            name="name"
            onChange={handleChange}
            value={name}
            required
          />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Starting Date</label>
          <input
            type="date"
            name="starting_date"
            onChange={handleChange}
            value={starting_date}
            required
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Ending Date</label>
          <input
            type="date"
            name="ending_date"
            onChange={handleChange}
            value={ending_date}
            required
          />
        </div>
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenAddSchoolTerm(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    addSchoolTerm: (userId, body, resetForm) =>
      dispatch(add_school_term(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSchoolTerm);
