import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { showError } from "../../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";
import FormativeReportLearners from "./FormativeReportLearners";

const FormativeReportOptions = (props) => {
  const {
    teacher_learning_areas,
    userId,
    openFormativeReportOptions,
    loading,
  } = props;
  const { startLoading, stopLoading, setOpenFormativeReportOptions } = props;

  const [reportFilters, setReportFilters] = useState({
    gradeStreamLearningAreaId: "",
    starting_date: "",
    ending_date: "",
  });

  const { gradeStreamLearningAreaId, starting_date, ending_date } =
    reportFilters;

  const [learnersResults, setLearnersResults] = useState([]);
  const [currentLearningArea, setCurrentLearningArea] = useState({});
  const [openFormativeReportLearners, setOpenFormativeReportLearners] =
    useState(false);

  useEffect(() => {
    setCurrentLearningArea(
      teacher_learning_areas?.find(
        (learning_area) => learning_area?.id === gradeStreamLearningAreaId
      )
    );
  }, [gradeStreamLearningAreaId, teacher_learning_areas]);

  const handleChange = (e) => {
    setReportFilters({ ...reportFilters, [e.target.name]: e.target.value });
  };

  const sortResultsByPercentScore = (a, b) => {
    if (a?.percent_score < b?.percent_score) {
      return 1;
    }
    if (a?.percent_score > b?.percent_score) {
      return -1;
    }
    return 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.post(
      `/api/course/teacher/teacher-get-formative-learning-area-report/${userId}/`,
      reportFilters
    )
      .then((res) => {
        setLearnersResults(
          res?.data?.learners_results?.sort(sortResultsByPercentScore)
        );
        setOpenFormativeReportLearners(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };
  return (
    <>
      <MinDialog
        isOpen={openFormativeReportOptions}
        maxWidth="600px"
        maxHeight="85vh"
      >
        <form
          class="dialog"
          onSubmit={handleSubmit}
          id={loading && "formSubmitting"}
        >
          <h3>Select options to view formative report</h3>
          <div class="dialog__rowSingleItem">
            <label for="">Learning Area</label>
            <select
              name="gradeStreamLearningAreaId"
              onChange={handleChange}
              value={gradeStreamLearningAreaId}
              required
            >
              <option value="">--select--</option>
              {teacher_learning_areas?.map((item) => (
                <option value={item?.id}>
                  {item?.grade_stream_name} - {item?.learning_area_name}
                </option>
              ))}
            </select>
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <div class="dialog__rowSingleItem">
            <label for="">Starting Date</label>
            <input
              type="date"
              name="starting_date"
              onChange={handleChange}
              value={starting_date}
              required
            />
          </div>
          <div class="dialog__rowSingleItem">
            <label for="">Ending Date</label>
            <input
              type="date"
              name="ending_date"
              onChange={handleChange}
              value={ending_date}
              required
            />
          </div>

          <div class="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenFormativeReportOptions(false)}
            >
              Close
            </button>
            <button type="submit" className="continue__button">
              View
            </button>
          </div>
        </form>
      </MinDialog>
      {openFormativeReportLearners && (
        <FormativeReportLearners
          openFormativeReportLearners={openFormativeReportLearners}
          setOpenFormativeReportLearners={setOpenFormativeReportLearners}
          learnersResults={learnersResults}
          currentLearningArea={currentLearningArea}
          starting_date={starting_date}
          ending_date={ending_date}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    teacher_learning_areas: state?.course?.teacher_learning_areas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormativeReportOptions);
