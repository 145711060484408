import API from "../shared/axios";

export const deleteGradeLevel = (userId, gradeLevelId) =>
  API.delete(
    `/api/course/school-admin/delete-grade-level/${userId}/${gradeLevelId}/`
  );

export const deleteGradeStream = (userId, gradeStreamId) =>
  API.delete(
    `/api/course/teacher/delete-grade-stream/${userId}/${gradeStreamId}/`
  );

export const deleteSchoolTerm = (userId, schoolTermId) =>
  API.delete(
    `/api/course/school-admin/delete-school-term/${userId}/${schoolTermId}/`
  );

export const deleteLearningArea = (userId, learningAreaId) =>
  API.delete(
    `/api/course/school-admin/delete-learning-area/${userId}/${learningAreaId}/`
  );

export const deleteSummativeAssessment = (userId, assessmentId) =>
  API.delete(
    `/api/exam/teacher/teacher-delete-summative-assessment/${userId}/${assessmentId}/`
  );
