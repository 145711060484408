import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { add_student } from "../../../../../redux/actions/auth";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";

const AddStudent = (props) => {
  const { openAddStudent, loading, userId, grade_streams } = props;
  const { setOpenAddStudent, startLoading, addStudent } = props;

  const [studentDetails, setStudentDetails] = useState({
    first_name: "",
    last_name: "",
    surname: "",
    gender: "",
    admission_number: "",
    grade_stream: "",
  });
  const {
    first_name,
    last_name,
    surname,
    gender,
    admission_number,
    grade_stream,
  } = studentDetails;

  const handleChange = (e) => {
    setStudentDetails({ ...studentDetails, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setStudentDetails({
      first_name: "",
      last_name: "",
      surname: "",
      gender: "",
      admission_number: "",
      grade_stream: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    addStudent(userId, { ...studentDetails }, resetForm);
  };

  return (
    <MinDialog isOpen={openAddStudent} maxWidth="800px" maxHeight="80vh">
      <form
        className="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Enter student details</h3>
        <div class="dialog__row">
          <span>
            <label for="">First Name</label>
            <input
              type="text"
              name="first_name"
              onChange={handleChange}
              value={first_name}
              required
            />
          </span>
          <span>
            <label for="">Last Name</label>
            <input
              type="text"
              name="last_name"
              onChange={handleChange}
              value={last_name}
              required
            />
          </span>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Surname</label>
            <input
              type="text"
              name="surname"
              onChange={handleChange}
              value={surname}
              required
            />
          </span>
          <span>
            <label for="">Admission Number</label>
            <input
              type="text"
              name="admission_number"
              onChange={handleChange}
              value={admission_number}
              required
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__row">
          <span>
            <label for="">Gender</label>
            <select
              name="gender"
              onChange={handleChange}
              value={gender}
              required
            >
              <option value="">--select--</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </span>
          <span>
            <label for="">Grade Stream</label>
            <select
              name="grade_stream"
              onChange={handleChange}
              value={grade_stream}
              required
            >
              <option value="">--select--</option>
              {grade_streams?.map((grade_stream_item) => (
                <option
                  value={grade_stream_item?.id}
                  key={grade_stream_item?.id}
                >
                  {grade_stream_item?.name}
                </option>
              ))}
            </select>
          </span>
        </div>
        <div className="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenAddStudent(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    grade_streams: state?.course?.grade_streams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    addStudent: (userId, body, resetForm) =>
      dispatch(add_student(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStudent);
