import { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { showError } from "../../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";
import AdminStrands from "./AdminStrands";

const SelectStrandGrade = (props) => {
  const {
    openSelectStrandGrade,
    userId,
    filteredAdminGradeLevels,
    currentLearningArea,
  } = props;
  const { setOpenSelectStrandGrade, startLoading, stopLoading } = props;

  const [openStrands, setOpenStrands] = useState(false);
  const [strands, setStrands] = useState([]);
  const [currentGradeLevel, setCurrentGradeLevel] = useState({});

  const handleChange = (e) => {
    setCurrentGradeLevel(
      filteredAdminGradeLevels?.find((item) => item?.id === e.target.value)
    );
  };

  const handleOpenStrands = async (e) => {
    e.preventDefault();
    startLoading();
    await API.get(
      `/api/course/school-admin/admin-get-grade-level-strands/${userId}/${currentGradeLevel?.id}/${currentLearningArea?.id}/`
    )
      .then((res) => {
        setStrands(res?.data?.strands_data);
        setOpenStrands(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MinDialog
        isOpen={openSelectStrandGrade}
        maxWidth="600px"
        maxHeight="400px"
      >
        <form class="dialog" onSubmit={handleOpenStrands}>
          <h3>
            Choose grade level to view {currentLearningArea?.name} strands
          </h3>
          <div class="dialog__rowSingleItem">
            <label for="">Grade Level</label>
            <select
              name=""
              required
              onChange={handleChange}
              value={currentGradeLevel?.id}
            >
              <option value="">--select--</option>
              {filteredAdminGradeLevels?.map((level) => (
                <option value={level?.id} key={level?.id}>
                  {level?.name}
                </option>
              ))}
            </select>
          </div>
          <div class="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenSelectStrandGrade(false)}
            >
              Close
            </button>
            <button type="submit" className="continue__button">
              View Strands
            </button>
          </div>
        </form>
      </MinDialog>

      {/* child components */}
      {openStrands && (
        <AdminStrands
          openStrands={openStrands}
          setOpenStrands={setOpenStrands}
          currentGradeLevel={currentGradeLevel}
          currentLearningArea={currentLearningArea}
          strands={strands}
          setStrands={setStrands}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectStrandGrade);
