import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { showError } from "../../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";

const EditStudent = (props) => {
  const {
    openEditStudent,
    loading,
    userId,
    currentStudent,
    grade_streams,
    students,
    currentGradeStream,
  } = props;
  const {
    setOpenEditStudent,
    startLoading,
    setCurrentStudent,
    stopLoading,
    setStudents,
  } = props;

  const {
    first_name,
    last_name,
    surname,
    gender,
    admission_number,
    grade_stream,
  } = currentStudent;

  const handleChange = (e) => {
    if (e.target.name === "grade_stream") {
      const currentGradeStream = grade_streams?.find(
        (grade_stream_item) => grade_stream_item?.id === e.target.value
      );
      setCurrentStudent({
        ...currentStudent,
        [e.target.name]: e.target.value,
        grade_stream_name: currentGradeStream?.name,
      });
    } else if (e.target.name === "is_deactivated") {
      setCurrentStudent({
        ...currentStudent,
        [e.target.name]: e.target.checked,
      });
    } else {
      setCurrentStudent({ ...currentStudent, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.patch(
      `/api/user/teacher/teacher-maintain-students/${userId}/`,
      currentStudent
    )
      .then((res) => {
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MinDialog isOpen={openEditStudent} maxWidth="800px" maxHeight="80vh">
        <form
          class="dialog"
          id={loading ? "formSubmitting" : ""}
          onSubmit={handleSubmit}
        >
          <h3>Edit student details</h3>
          <div class="dialog__row">
            <span>
              <label for="">First Name</label>
              <input
                type="text"
                name="first_name"
                onChange={handleChange}
                value={first_name}
                required
              />
            </span>
            <span>
              <label for="">Last Name</label>
              <input
                type="text"
                name="last_name"
                onChange={handleChange}
                value={last_name}
                required
              />
            </span>
          </div>
          <div class="dialog__row">
            <span>
              <label for="">Surname</label>
              <input
                type="text"
                name="surname"
                onChange={handleChange}
                value={surname}
                required
              />
            </span>
            <span>
              <label for="">Admission Number</label>
              <input
                type="text"
                name="admission_number"
                onChange={handleChange}
                value={admission_number}
                required
              />
            </span>
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <div class="dialog__row">
            <span>
              <label for="">Gender</label>
              <select
                name="gender"
                onChange={handleChange}
                value={gender}
                required
              >
                <option value="">--select--</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </span>
            <span>
              <label for="">Grade Stream</label>
              <select
                name="grade_stream"
                onChange={handleChange}
                value={grade_stream}
                required
              >
                <option value="">--select--</option>
                {grade_streams?.map((grade_stream_item) => (
                  <option
                    value={grade_stream_item?.id}
                    key={grade_stream_item?.id}
                  >
                    {grade_stream_item?.name}
                  </option>
                ))}
              </select>
            </span>
          </div>

          <div class="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenEditStudent(false)}
            >
              Close
            </button>
            <button type="submit" className="continue__button">
              Update
            </button>
          </div>
        </form>
      </MinDialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    grade_streams: state?.course?.grade_streams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStudent);
