// types import
import * as actionTypes from "../actions/types";

import { updateObject } from "../utility";

const initialState = {
  signupForm: false,
  loginForm: false,
  loggedIn: false,
  forgotPasswordForm: false,
  resendActivationForm: false,
  resetPasswordConfirmForm: false,
  changePasswordForm: false,
  user: {},
  teachers: [],
  students: [],
};

const setTeachers = (state, payload) => {
  return updateObject(state, {
    teachers: payload,
  });
};

const addTeacher = (state, payload) => {
  return updateObject(state, {
    teachers: [...state?.teachers, payload],
  });
};

const editTeacher = (state, payload) => {
  return updateObject(state, {
    teachers: state?.teachers?.map((teacher) =>
      teacher?.id === payload?.id ? payload : teacher
    ),
  });
};

const changeStaffGroups = (state, payload) => {
  if (payload?.userType === "Teacher") {
    return updateObject(state, {
      teachers: state.teachers?.map((teacher) =>
        teacher?.id === payload?.updated_staff?.id
          ? payload?.updated_staff
          : teacher
      ),
    });
  } else {
    return state;
  }
};

const addStudent = (state, payload) => {
  return updateObject(state, {
    students: [...state?.students, payload],
  });
};
const editStudent = (state, payload) => {
  return updateObject(state, {
    students: state?.students?.map((student) =>
      student?.id === payload?.id ? payload : student
    ),
  });
};
const deleteStudent = (state, payload) => {
  return updateObject(state, {
    students: state?.students?.filter((student) => student?.id !== payload),
  });
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.OPEN_SIGNUP:
      return {
        ...state,
        signupForm: true,
      };
    case actionTypes.CLOSE_SIGNUP:
      return {
        ...state,
        signupForm: false,
      };
    case actionTypes.OPEN_LOGIN:
      return {
        ...state,
        loginForm: true,
      };
    case actionTypes.CLOSE_LOGIN:
      return {
        ...state,
        loginForm: false,
      };
    case actionTypes.AUTH_SUCCESS:
      return { ...state, user: payload, loggedIn: true };
    case actionTypes.OPEN_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordForm: true,
      };
    case actionTypes.CLOSE_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordForm: false,
      };
    case actionTypes.OPEN_RESEND_ACTIVATION:
      return {
        ...state,
        resendActivationForm: true,
      };
    case actionTypes.CLOSE_RESEND_ACTIVATION:
      return {
        ...state,
        resendActivationForm: false,
      };
    case actionTypes.OPEN_PASSWORD_RESET_CONFIRM:
      return {
        ...state,
        resetPasswordConfirmForm: true,
      };
    case actionTypes.CLOSE_PASSWORD_RESET_CONFIRM:
      return {
        ...state,
        resetPasswordConfirmForm: false,
      };
    case actionTypes.OPEN_CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordForm: true,
      };
    case actionTypes.CLOSE_CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordForm: false,
      };
    case actionTypes.LOGOUT:
      return initialState;
    case actionTypes.SET_TEACHERS:
      return setTeachers(state, payload);
    case actionTypes.ADD_TEACHER:
      return addTeacher(state, payload);
    case actionTypes.UPDATE_TEACHER:
      return editTeacher(state, payload);
    case actionTypes.CHANGE_STAFF_GROUPS:
      return changeStaffGroups(state, payload);
    case actionTypes.ADD_STUDENT:
      return addStudent(state, payload);
    case actionTypes.EDIT_STUDENT:
      return editStudent(state, payload);
    case actionTypes.DELETE_STUDENT:
      return deleteStudent(state, payload);
    default:
      return state;
  }
};

export default authReducer;
