import { connect } from "react-redux";
import "./Home.scss";

import cbc_image from "../../assets/images/cbc_image.jpg";
import { OPEN_LOGIN } from "../../redux/actions/types";

const Home = (props) => {
  const { loggedIn } = props;
  const { openLogin } = props;

  return (
    <div className="home">
      <div class="home__banner">
        <div className="banner__image">
          <img src={cbc_image} alt="" />
          {!loggedIn && (
            <button type="button" onClick={openLogin}>
              Login Now
            </button>
          )}
        </div>

        <div className="banner__description">
          <h2 className="tc">CBC assessments without hassle</h2>
          <div className="home__info">
            <h4>Features</h4>
            <br />
            <ul>
              <li>CBC assessments grading</li>
              <li>CBC reports i.e formative &amp; summative</li>
              <li>Students management</li>
              <li>Print CBC reports</li>
              <li>Export CBC reports to excel</li>
              <li>Student report cards</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state?.auth?.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openLogin: () => dispatch({ type: OPEN_LOGIN }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
