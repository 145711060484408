import { connect } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { get_teacher_learning_areas } from "../../../redux/actions/course";
import { TOGGLE_LOADING } from "../../../redux/actions/types";
import FormativeAssessmentOptions from "./components/FormativeAssessmentOptions";

const Formative = (props) => {
  const { userId, loading, teacher_learning_areas } = props;
  const { startLoading, getTeacherLearningAreas } = props;

  const [openFormativeAssessmentOptions, setOpenFormativeAssessmentOptions] =
    useState(false);

  useEffect(() => {
    if (userId && teacher_learning_areas?.length === 0) {
      startLoading();
      getTeacherLearningAreas(userId);
    }
  }, [
    startLoading,
    getTeacherLearningAreas,
    teacher_learning_areas?.length,
    userId,
  ]);

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div className="table__columns">
          <div className="column">
            <h3>Perform formative assessment</h3>
            <div className="buttons">
              <button
                type="button"
                onClick={() => setOpenFormativeAssessmentOptions(true)}
              >
                Perform Assessment
              </button>
            </div>
          </div>
        </div>

        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
      </div>

      {/* child components */}

      {openFormativeAssessmentOptions && (
        <FormativeAssessmentOptions
          openFormativeAssessmentOptions={openFormativeAssessmentOptions}
          setOpenFormativeAssessmentOptions={setOpenFormativeAssessmentOptions}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    teacher_learning_areas: state?.course?.teacher_learning_areas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    getTeacherLearningAreas: (userId) =>
      dispatch(get_teacher_learning_areas(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Formative);
