import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { showError } from "../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../redux/actions/types";
import API from "../../../shared/axios";
import UseRubric from "../Assessments/components/UseRubric";

const PendingSubmissions = (props) => {
  const { loading, userId } = props;
  const { startLoading, stopLoading } = props;

  const [openUseRubric, setOpenUseRubric] = useState(false);
  const [currentAssessment, setCurrentAssessment] = useState({});
  const [teacherPendingAssessments, setTeacherPendingAssessments] = useState(
    []
  );

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchData = async () => {
        await API.get(
          `/api/course/teacher/teacher-get-pending-assessments/${userId}/`
        )
          .then((res) => {
            setTeacherPendingAssessments(
              res?.data?.teacher_pending_assessments
            );
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, startLoading, stopLoading]);

  const handleOpenUseRubric = (assessment) => {
    setCurrentAssessment(assessment);
    setOpenUseRubric(true);
  };

  const handleResetAssessments = async (gradeStreamRubricTermId) => {
    if (window.confirm(`Are you sure you want to reset these assessments`)) {
      startLoading();
      await API.delete(
        `/api/course/teacher/maintain-pending-assessment/${userId}/${gradeStreamRubricTermId}/`
      )
        .then((res) => {
          setTeacherPendingAssessments(
            teacherPendingAssessments?.filter(
              (assessment) => assessment?.id !== gradeStreamRubricTermId
            )
          );
          window.alert(res?.data?.detail);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  const handleSubmitAssessment = async (gradeStreamRubricTermId) => {
    if (
      window.confirm(
        `Report cannot be edited after submission, are you sure you want to continue?`
      )
    ) {
      startLoading();
      await API.patch(
        `/api/course/teacher/maintain-pending-assessment/${userId}/${gradeStreamRubricTermId}/`
      )
        .then((res) => {
          setTeacherPendingAssessments(
            teacherPendingAssessments?.filter(
              (assessment) => assessment?.id !== gradeStreamRubricTermId
            )
          );
          window.alert(res?.data?.detail);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div class="table__parentHeader">
          <h3>Assessments awaiting submission</h3>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {teacherPendingAssessments?.length > 0 ? (
          <table className="table__listing" rules="all" border="1">
            <thead>
              <tr class="table__listingHeader">
                <th>Activity</th>
                <th>Learning Area</th>
                <th>Learners Assessed</th>
                <th colSpan={3} className="tc">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {teacherPendingAssessments?.map((item) => (
                <tr class="table__listingItem" key={item?.id}>
                  <td>{item?.rubric?.title}</td>
                  <td>{item?.learning_area}</td>
                  <td>{item?.learners_assessed}</td>
                  <td>
                    <i
                      class="bx bxs-trash-alt red button"
                      title="reset assessments"
                      onClick={() => handleResetAssessments(item?.id)}
                    ></i>
                  </td>
                  <td>
                    <i
                      class="bx bxs-folder-open green button"
                      title="edit assessments"
                      onClick={() => handleOpenUseRubric(item)}
                    ></i>
                  </td>
                  <td>
                    {" "}
                    <i
                      class="bx bxs-send dodgerblue button"
                      title="submit assessments to final report"
                      onClick={() => handleSubmitAssessment(item?.id)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No pending assessments</h4>
        )}
      </div>
      {/* child components */}
      <UseRubric
        openUseRubric={openUseRubric}
        setOpenUseRubric={setOpenUseRubric}
        currentRubric={currentAssessment?.rubric}
        currentGradeStreamLearningAreaId={
          currentAssessment?.grade_stream_learning_area
        }
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingSubmissions);
