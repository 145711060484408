// axios items
import * as api from "../../api/index";

// redux API items

import * as actionTypes from "../actions/types";
import { showError, stopLoading } from "./shared";

// shared items

// sign up user
export const signup_user = (newUser, resetForm) => async (dispatch) => {
  await api
    .signupUser(newUser)
    .then((res) => {
      // dispatch({ type: AUTH_SUCCESS, payload: data?.user });
      window.alert(res.data?.detail);
      resetForm();
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const add_student = (userId, body, resetForm) => async (dispatch) => {
  await api
    .addStudent(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.ADD_STUDENT,
        payload: res?.data?.new_student_data,
      });
      resetForm();
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// activate user account
export const activate_account = (body) => async (dispatch) => {
  const { activation_token, navigate } = body;
  await api
    .activateAccount(activation_token)
    .then((res) => {
      window.alert(res.data?.detail);
      dispatch({ type: actionTypes.OPEN_LOGIN });
    })
    .catch((err) => showError(err))
    .finally(() => {
      stopLoading(dispatch);
      navigate("/", { replace: true });
    });
};

// resend user account activation link
export const resend_activation = (email, resetForm) => async (dispatch) => {
  await api
    .resendActivation(email)
    .then((res) => {
      window.alert("Activation link sent to email.");
      resetForm();
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// login user
export const login = (loginData, resetForm) => async (dispatch) => {
  await api
    .signIn(loginData)
    .then((res) => {
      localStorage.setItem("session_cookie", res.data?.access);
      resetForm();
      // dispatch success message
      dispatch({ type: actionTypes.CLOSE_LOGIN });
      // get user details
      dispatch(get_user());
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// reset user password by sending an email with a reset link
export const reset_password = (email, resetForm) => async (dispatch) => {
  await api
    .resetPassword(email)
    .then((res) => {
      window.alert(res.data?.detail);
      resetForm();
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// set new user password
export const set_password =
  (newPasswords, password_token, navigate) => async (dispatch) => {
    await api
      .setPassword(newPasswords, password_token)
      .then((res) => {
        window.alert(res.data?.detail);
        dispatch({ type: actionTypes.CLOSE_PASSWORD_RESET_CONFIRM });
        navigate("/", { replace: true });
        dispatch({ type: actionTypes.OPEN_LOGIN });
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// patch/update user data
export const update_user = (updatedUser, userId) => async (dispatch) => {
  await api
    .updateUser(updatedUser, userId)
    .then((res) => {
      dispatch({ type: actionTypes.AUTH_SUCCESS, payload: res.data?.user });
      window.alert(res.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// user change password
export const change_password = (passwords, userId) => async (dispatch) => {
  await api
    .changePassword(passwords, userId)
    .then((res) => {
      window.alert(res.data?.detail);
      dispatch({ type: actionTypes.CLOSE_CHANGE_PASSWORD });
      dispatch(logout());
      dispatch({ type: actionTypes.OPEN_LOGIN });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// get user data
export const get_user = () => async (dispatch) => {
  await api
    .getUser()
    .then((res) => {
      dispatch({ type: actionTypes.AUTH_SUCCESS, payload: res.data?.user });
    })
    .catch((err) => {
      dispatch({ type: actionTypes.LOGOUT });
      localStorage.clear();
    });
};

export const get_teachers = (userId) => async (dispatch) => {
  await api
    .getTeachers(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_TEACHERS,
        payload: res?.data?.teachers_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const add_teacher = (userId, body, resetForm) => async (dispatch) => {
  await api
    .addTeacher(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.ADD_TEACHER,
        payload: res?.data?.new_teacher_data,
      });
      resetForm();
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const edit_teacher = (userId, body) => async (dispatch) => {
  await api
    .editTeacher(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_TEACHER,
        payload: res?.data?.updated_teacher_data,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const add_other_staff =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .addOtherStaff(userId, body)
      .then((res) => {
        // since we are not storing other staff in the redux state, no action is dispatched here
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

export const edit_other_staff = (userId, body) => async (dispatch) => {
  await api
    .editOtherStaff(userId, body)
    .then((res) => {
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const change_staffs_groups = (userId, body) => async (dispatch) => {
  await api
    .changeStaffsGroups(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes?.CHANGE_STAFF_GROUPS,
        payload: {
          userType: body["userType"],
          updated_staff: res?.data?.updated_staff,
        },
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// logout user
export const logout = () => async (dispatch) => {
  localStorage.clear();
  dispatch({ type: actionTypes.LOGOUT });
  window.location.replace("/"); // we use this to ensure page reloads, thus all state is cleared
};
