import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../../components/common/MaxDialog";
import CircularProgress from "@mui/material/CircularProgress";
import { showError } from "../../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";
import AddGradeStreamLearningArea from "./AddGradeStreamLearningArea";
import GradeStreamLearningAreaStudents from "./GradeStreamLearningAreaStudents";

const GradeStreamLearningAreas = (props) => {
  const {
    openGradeStreamLearningAreas,
    currentGradeStream,
    userId,
    learning_areas,
    loading,
  } = props;
  const { setOpenGradeStreamLearningAreas, startLoading, stopLoading } = props;

  const [gradeStreamLearningAreas, setGradeStreamLearningAreas] = useState([]);
  const [filteredLearningAreas, setFilteredLearningAreas] = useState([]);
  const [openAddGradeStreamLearningArea, setOpenAddGradeStreamLearningArea] =
    useState(false);
  const [
    openGradeStreamLearningAreaStudents,
    setOpenGradeStreamLearningAreaStudents,
  ] = useState(false);
  const [currentGradeStreamLearningArea, setCurrentGradeStreamLearningArea] =
    useState({});

  useEffect(() => {
    if (userId && currentGradeStream?.id) {
      const fetchData = async () => {
        await API.get(
          `/api/course/teacher/maintain-grade-stream-learning-areas/${userId}/${currentGradeStream?.id}/`
        )
          .then((res) => {
            setGradeStreamLearningAreas(
              res?.data?.grade_stream_learning_areas_data
            );
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [currentGradeStream?.id, userId, startLoading, stopLoading]);

  // filter learning areas to align them with grade stream susing level
  useEffect(() => {
    if (currentGradeStream?.level === "upper_primary") {
      setFilteredLearningAreas(
        learning_areas?.filter((item) => item?.level === "upper_primary")
      );
    } else if (currentGradeStream?.level === "lower_primary") {
      setFilteredLearningAreas(
        learning_areas?.filter(
          (item) =>
            item?.level === "lower_primary" ||
            item?.level === "pre_lower_primary"
        )
      );
    } else if (currentGradeStream?.level === "pre_primary") {
      setFilteredLearningAreas(
        learning_areas?.filter(
          (item) =>
            item?.level === "pre_primary" || item?.level === "pre_lower_primary"
        )
      );
    }
  }, [currentGradeStream?.level, learning_areas]);

  const handleOpenGradeStreamLearningAreaStudents = (
    grade_stream_learning_area
  ) => {
    setCurrentGradeStreamLearningArea(grade_stream_learning_area);
    setOpenGradeStreamLearningAreaStudents(true);
  };

  const handleDeleteGradeStreamLearningArea = async (
    gradeStreamLearningAreaId
  ) => {
    if (
      window.confirm(
        `Deleting this learning area is not reversible. Are you sure you want to continue?`
      )
    ) {
      startLoading();
      await API.delete(
        `/api/course/teacher/delete-grade-stream-learning-area/${userId}/${gradeStreamLearningAreaId}/`
      )
        .then((res) => {
          setGradeStreamLearningAreas(
            gradeStreamLearningAreas?.filter(
              (item) => item?.id !== gradeStreamLearningAreaId
            )
          );
          window.alert(res?.data?.detail);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  return (
    <>
      <MaxDialog
        isOpen={openGradeStreamLearningAreas}
        maxWidth="1200px"
        maxHeight="85vh"
      >
        <div className="dialog" id={loading && "formSubmitting"}>
          <h3>{currentGradeStream?.name} learning areas</h3>
          {gradeStreamLearningAreas?.length > 0 ? (
            <table className="table__listing" rules="all" border="1">
              <thead>
                <tr className="table__listingHeader">
                  <th>Name</th>
                  <th>Students</th>
                  <th className="tc">Delete</th>
                </tr>
              </thead>
              <tbody>
                {gradeStreamLearningAreas?.map((learning_area_item) => (
                  <tr
                    className="table__listingItem"
                    key={learning_area_item?.id}
                  >
                    <td>{learning_area_item?.name}</td>
                    <td
                      className="dodgerblue bd button__sp"
                      onClick={() =>
                        handleOpenGradeStreamLearningAreaStudents(
                          learning_area_item
                        )
                      }
                    >
                      view
                    </td>
                    <td className="tc">
                      <i
                        className="bx bxs-trash-alt red button__sp"
                        onClick={() =>
                          handleDeleteGradeStreamLearningArea(
                            learning_area_item?.id
                          )
                        }
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 className="not__available">No learning areas added yet</h4>
          )}
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <div className="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenGradeStreamLearningAreas(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="continue__button"
              onClick={() => setOpenAddGradeStreamLearningArea(true)}
            >
              Add Learning Area
            </button>
          </div>
        </div>
      </MaxDialog>
      {/* child components */}
      {openAddGradeStreamLearningArea && (
        <AddGradeStreamLearningArea
          openAddGradeStreamLearningArea={openAddGradeStreamLearningArea}
          setOpenAddGradeStreamLearningArea={setOpenAddGradeStreamLearningArea}
          currentGradeStream={currentGradeStream}
          gradeStreamLearningAreas={gradeStreamLearningAreas}
          setGradeStreamLearningAreas={setGradeStreamLearningAreas}
          filteredLearningAreas={filteredLearningAreas}
        />
      )}
      {openGradeStreamLearningAreaStudents && (
        <GradeStreamLearningAreaStudents
          openGradeStreamLearningAreaStudents={
            openGradeStreamLearningAreaStudents
          }
          setOpenGradeStreamLearningAreaStudents={
            setOpenGradeStreamLearningAreaStudents
          }
          currentGradeStreamLearningArea={currentGradeStreamLearningArea}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    learning_areas: state?.course?.learning_areas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GradeStreamLearningAreas);
