import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import MaxDialog from "../../../../../components/common/MaxDialog";
import { showError } from "../../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";

const GradeStreamLearningAreaStudents = (props) => {
  const {
    openGradeStreamLearningAreaStudents,
    currentGradeStreamLearningArea,
    loading,
    userId,
  } = props;
  const { setOpenGradeStreamLearningAreaStudents, startLoading, stopLoading } =
    props;

  const [learningAreaStudents, setLearningAreaStudents] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);

  useEffect(() => {
    if (userId && currentGradeStreamLearningArea?.id) {
      startLoading();
      const fetchStudents = async () => {
        await API.get(
          `/api/course/teacher/maintain-grade-stream-learning-area-students/${userId}/${currentGradeStreamLearningArea?.id}/`
        )
          .then((res) => {
            setLearningAreaStudents(res?.data?.grade_stream_students);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchStudents();
    }
  }, [startLoading, stopLoading, userId, currentGradeStreamLearningArea?.id]);

  const handleChange = (e, itemIndex) => {
    const arrayToMutate = [...learningAreaStudents];
    arrayToMutate[itemIndex] = {
      ...arrayToMutate[itemIndex],
      isChecked: e.target.checked,
    };
    setLearningAreaStudents(arrayToMutate);
  };

  const handleSelectAll = (e) => {
    setSelectedAll(e.target.checked);
    if (e.target.checked) {
      setLearningAreaStudents(
        learningAreaStudents?.map((item) => ({ ...item, isChecked: true }))
      );
    } else {
      setLearningAreaStudents(
        learningAreaStudents?.map((item) => ({ ...item, isChecked: false }))
      );
    }
  };

  const handleUpdateStudents = async (e) => {
    e.preventDefault();
    startLoading();
    await API.patch(
      `/api/course/teacher/maintain-grade-stream-learning-area-students/${userId}/${currentGradeStreamLearningArea?.id}/`,
      {
        // we only pick the active students
        activeStudents: learningAreaStudents
          ?.filter((student) => student?.isChecked === true)
          .map((item) => item?.id),
      }
    )
      .then((res) => {
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MaxDialog
      isOpen={openGradeStreamLearningAreaStudents}
      maxWidth="1000px"
      maxHeight="85vh"
    >
      <div className="dialog" id={loading && "formSubmitting"}>
        <h3>{currentGradeStreamLearningArea?.name} active students</h3>
        {learningAreaStudents?.length > 0 ? (
          <table className="table__listing" rules="all" border="1">
            <thead>
              <tr className="table__listingHeader">
                <th>Name</th>
                <th className="tc">
                  Active{" "}
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectedAll}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {learningAreaStudents?.map((student, index) => (
                <tr className="table__listingItem" key={student?.id}>
                  <td>{student?.name}</td>
                  <td className="tc">
                    <input
                      type="checkbox"
                      onChange={(e) => handleChange(e, index)}
                      checked={student?.isChecked}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 className="not__available">No students available</h4>
        )}
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenGradeStreamLearningAreaStudents(false)}
          >
            Close
          </button>
          <button
            type="button"
            className="continue__button"
            onClick={handleUpdateStudents}
          >
            Update
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GradeStreamLearningAreaStudents);
