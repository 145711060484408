import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { get_teacher_learning_areas } from "../../../../redux/actions/course";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";
import SummativeAssessmentStudents from "./SummativeAssessmentStudents";

const SummativeAssessmentOptions = (props) => {
  const {
    openSummativeAssessmentOptions,
    loading,
    userId,
    teacher_learning_areas,
    summative_assessments,
  } = props;
  const {
    setOpenSummativeAssessmentOptions,
    startLoading,
    stopLoading,
    getTeacherLearningAreas,
  } = props;

  const [assessmentDetails, setAssessmentDetails] = useState({
    assessmentId: "",
    gradeStreamLearningAreaId: "",
  });
  const { assessmentId, gradeStreamLearningAreaId } = assessmentDetails;

  const [openSummativeAssessmentStudents, setOpenSummativeAssessmentStudents] =
    useState(false);

  const [gradeStreamLearningAreaStudents, setGradeStreamLearningAreaStudents] =
    useState([]);
  const [currentGradeStreamLearningArea, setCurrentGradeStreamLearningArea] =
    useState({});
  const [currentSummativeAssessment, setCurrentSummativeAssessment] = useState(
    {}
  );

  const [
    summativeAssessmentLearningAreaData,
    setSummativeAssessmentLearningAreaData,
  ] = useState({});

  useEffect(() => {
    if (userId && teacher_learning_areas?.length === 0) {
      startLoading();
      getTeacherLearningAreas(userId);
    }
  }, [
    startLoading,
    getTeacherLearningAreas,
    teacher_learning_areas?.length,
    userId,
  ]);

  const handleChange = (e) => {
    setAssessmentDetails({
      ...assessmentDetails,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "gradeStreamLearningAreaId") {
      setCurrentGradeStreamLearningArea(
        teacher_learning_areas?.find((item) => item?.id === e.target.value)
      );
    } else if (e.target.name === "assessmentId") {
      setCurrentSummativeAssessment(
        summative_assessments?.find((item) => item?.id === e.target.value)
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.get(
      `/api/exam/teacher/teacher-get-ongoing-summative-assessment-learning-area-results/${userId}/${assessmentId}/${gradeStreamLearningAreaId}/`
    )
      .then((res) => {
        setGradeStreamLearningAreaStudents(
          res?.data?.grade_stream_learning_area_students
        );
        setSummativeAssessmentLearningAreaData(
          res?.data?.summative_assessment_learning_area_data
        );
        setOpenSummativeAssessmentStudents(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MinDialog
        isOpen={openSummativeAssessmentOptions}
        maxWidth="500px"
        maxHeight="500px"
      >
        <form
          class="dialog"
          onSubmit={handleSubmit}
          id={loading ? "formSubmitting" : ""}
        >
          <h3>Select options for summative assessment</h3>
          <div class="dialog__rowSingleItem">
            <label for="">Select Assessment</label>
            <select
              name="assessmentId"
              onChange={handleChange}
              value={assessmentId}
              required
            >
              <option value="">--select--</option>
              {summative_assessments?.map((assessment) => (
                <option value={assessment?.id} key={assessment?.id}>
                  {assessment?.name}
                </option>
              ))}
            </select>
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "40%" }}
            />
          )}
          <div class="dialog__rowSingleItem">
            <label for="">Learning Area</label>
            <select
              name="gradeStreamLearningAreaId"
              onChange={handleChange}
              value={gradeStreamLearningAreaId}
              required
            >
              <option value="">--select--</option>
              {teacher_learning_areas?.map((item) => (
                <option value={item?.id} key={item?.id}>
                  {item?.grade_stream_name} - {item?.learning_area_name}
                </option>
              ))}
            </select>
          </div>

          <div class="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenSummativeAssessmentOptions(false)}
            >
              Close
            </button>
            <button type="submit" className="continue__button">
              Continue
            </button>
          </div>
        </form>
      </MinDialog>
      {/* child components */}
      {openSummativeAssessmentStudents && (
        <SummativeAssessmentStudents
          openSummativeAssessmentStudents={openSummativeAssessmentStudents}
          setOpenSummativeAssessmentStudents={
            setOpenSummativeAssessmentStudents
          }
          gradeStreamLearningAreaStudents={gradeStreamLearningAreaStudents}
          setGradeStreamLearningAreaStudents={
            setGradeStreamLearningAreaStudents
          }
          summativeAssessmentHeading={`${currentGradeStreamLearningArea?.grade_stream_name} - ${currentGradeStreamLearningArea?.learning_area_name} - ${currentSummativeAssessment?.name}`}
          currentGradeStreamLearningAreaId={currentGradeStreamLearningArea?.id}
          currentSummativeAssessmentId={currentSummativeAssessment?.id}
          summativeAssessmentLearningAreaData={
            summativeAssessmentLearningAreaData
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    teacher_learning_areas: state?.course?.teacher_learning_areas,
    summative_assessments: state?.exam?.summative_assessments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
    getTeacherLearningAreas: (userId) =>
      dispatch(get_teacher_learning_areas(userId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummativeAssessmentOptions);
