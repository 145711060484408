import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";

const EditSubStrand = (props) => {
  const { openEditSubStrand, currentSubStrand, loading } = props;
  const { setOpenEditSubStrand, setCurrentSubStrand, handleEditSubStrand } =
    props;

  const { num, title, is_active } = currentSubStrand;

  const handleChange = (e) => {
    setCurrentSubStrand({
      ...currentSubStrand,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e) => {
    setCurrentSubStrand({
      ...currentSubStrand,
      [e.target.name]: !e.target.defaultChecked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleEditSubStrand(currentSubStrand);
  };

  return (
    <MinDialog isOpen={openEditSubStrand} maxWidth="500px" maxHeight="400px">
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Edit sub strand details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">SubStrand Number</label>
          <input
            type="text"
            name="num"
            onChange={handleChange}
            value={num}
            required
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">SubStrand Title</label>
          <input
            type="text"
            name="title"
            onChange={handleChange}
            value={title}
            required
          />
        </div>
        <div class="dialog__checkBoxListingItem">
          <input
            type="checkbox"
            name="is_active"
            onChange={handleCheckboxChange}
            defaultChecked={is_active}
          />
          <label for="">Uncheck this box to deactivate this substrand</label>
        </div>
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenEditSubStrand(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Update
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSubStrand);
