import React, { useState } from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import MinDialog from "../../../../../components/common/MinDialog";
import { get_teacher_learning_areas } from "../../../../../redux/actions/course";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";
import { showError } from "../../../../../redux/actions/shared";
import SummativeLearningAreaReport from "./SummativeLearningAreaReport";

const SelectLearningArea = (props) => {
  const {
    openSelectLearningArea,
    summative_assessments,
    teacher_learning_areas,
    loading,
    userId,
  } = props;
  const { setOpenSelectLearningArea, startLoading, stopLoading } = props;

  const [filteredOptionsNames, setFilteredOptionsNames] = useState({
    gradeStreamLearningAreaName: "",
    assessmentName: "",
  });
  const { gradeStreamLearningAreaName, assessmentName } = filteredOptionsNames;
  const [assessmentDetails, setAssessmentDetails] = useState({
    assessmentId: "",
    gradeStreamLearningAreaId: "",
  });
  const { assessmentId, gradeStreamLearningAreaId } = assessmentDetails;

  const [
    summativeLearningAreaResultDetails,
    setSummativeLearningAreaResultDetails,
  ] = useState({});
  const [
    summativeLearningAreaStudentsResults,
    setSummativeLearningAreaStudentsResults,
  ] = useState([]);
  const [openSummativeLearningAreaReport, setOpenSummativeLearningAreaReport] =
    useState(false);

  const handleChange = (e) => {
    if (e.target.name === "assessmentId") {
      setFilteredOptionsNames({
        ...filteredOptionsNames,
        assessmentName: summative_assessments?.find(
          (item) => item?.id === e.target.value
        )["name"],
      });
    } else if (e.target.name === "gradeStreamLearningAreaId") {
      const gradeStreamLearningAreaObj = teacher_learning_areas?.find(
        (item) => item?.id === e.target.value
      );
      setFilteredOptionsNames({
        ...filteredOptionsNames,
        gradeStreamLearningAreaName: `${gradeStreamLearningAreaObj["grade_stream_name"]} - ${gradeStreamLearningAreaObj["learning_area_name"]}`,
      });
    }
    setAssessmentDetails({
      ...assessmentDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.get(
      `/api/exam/teacher/teacher-get-final-completed-summative-learning-area-results/${userId}/${assessmentId}/${gradeStreamLearningAreaId}/`
    )
      .then((res) => {
        setSummativeLearningAreaResultDetails(
          res?.data?.summative_assessment_learning_area_data
        );
        setSummativeLearningAreaStudentsResults(
          res?.data?.students_learning_area_results
        );
        setOpenSummativeLearningAreaReport(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MinDialog
        isOpen={openSelectLearningArea}
        maxWidth="600px"
        maxHeight="85vh"
      >
        <form
          className="dialog"
          id={loading && "formSubmitting"}
          onSubmit={handleSubmit}
        >
          <h3>Select options to view final report</h3>
          <div className="dialog__rowSingleItem">
            <label for="">Assessment</label>
            <select
              name="assessmentId"
              onChange={handleChange}
              value={assessmentId}
              required
            >
              <option value="">--select--</option>
              {summative_assessments?.map((item) => (
                <option value={item?.id} key={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <div className="dialog__rowSingleItem">
            <label for="">Learning Area</label>
            <select
              name="gradeStreamLearningAreaId"
              onChange={handleChange}
              value={gradeStreamLearningAreaId}
              required
            >
              <option value="">--select--</option>
              {teacher_learning_areas?.map((item) => (
                <option value={item?.id} key={item?.id}>
                  {item?.grade_stream_name} - {item?.learning_area_name}
                </option>
              ))}
            </select>
          </div>
          <div className="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenSelectLearningArea(false)}
            >
              Close
            </button>
            <button type="submit" className="continue__button">
              Generate
            </button>
          </div>
        </form>
      </MinDialog>

      {/* child components */}
      {openSummativeLearningAreaReport && (
        <SummativeLearningAreaReport
          openSummativeLearningAreaReport={openSummativeLearningAreaReport}
          setOpenSummativeLearningAreaReport={
            setOpenSummativeLearningAreaReport
          }
          summativeLearningAreaResultDetails={
            summativeLearningAreaResultDetails
          }
          summativeLearningAreaStudentsResults={
            summativeLearningAreaStudentsResults
          }
          gradeStreamLearningAreaName={gradeStreamLearningAreaName}
          assessmentName={assessmentName}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    summative_assessments: state?.exam?.summative_assessments,
    teacher_learning_areas: state?.course?.teacher_learning_areas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
    getTeacherLearningAreas: (userId) =>
      dispatch(get_teacher_learning_areas(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectLearningArea);
