import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get_teacher_learning_areas } from "../../../../redux/actions/course";
import { teacher_get_summative_assessments } from "../../../../redux/actions/exam";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import SelectGradeStream from "./components/SelectGradeStream";
import SelectLearningArea from "./components/SelectLearningArea";

const SummativeReports = (props) => {
  const { userId, loading, summative_assessments, teacher_learning_areas } =
    props;
  const {
    startLoading,
    teacherGetSummativeAssessments,
    getTeacherLearningAreas,
  } = props;

  const [openSelectLearningArea, setOpenSelectLearningArea] = useState(false);
  const [openSelectGradeStream, setOpenSelectGradeStream] = useState(false);

  useEffect(() => {
    if (userId && teacher_learning_areas?.length === 0) {
      startLoading();
      getTeacherLearningAreas(userId);
    }
  }, [
    startLoading,
    getTeacherLearningAreas,
    teacher_learning_areas?.length,
    userId,
  ]);

  useEffect(() => {
    if (userId && summative_assessments?.length === 0) {
      startLoading();
      teacherGetSummativeAssessments(userId);
    }
  }, [
    userId,
    summative_assessments?.length,
    teacherGetSummativeAssessments,
    startLoading,
  ]);

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div className="table__parentHeader">
          <h3>View summative final report</h3>
        </div>
        <div className="table__columns">
          {/* column 1 */}
          <div className="column">
            <h3>Learning area results</h3>
            <br />
            <div className="buttons">
              <button
                type="button"
                className="add__button"
                onClick={() => setOpenSelectLearningArea(true)}
              >
                View Results
              </button>
            </div>
          </div>
          <div className="column">
            <h3>Overall results</h3>
            <br />
            <div className="buttons">
              <button
                type="button"
                className="add__button"
                onClick={() => setOpenSelectGradeStream(true)}
              >
                View Results
              </button>
            </div>
          </div>
        </div>
      </div>

      {openSelectLearningArea && (
        <SelectLearningArea
          openSelectLearningArea={openSelectLearningArea}
          setOpenSelectLearningArea={setOpenSelectLearningArea}
        />
      )}
      {openSelectGradeStream && (
        <SelectGradeStream
          openSelectGradeStream={openSelectGradeStream}
          setOpenSelectGradeStream={setOpenSelectGradeStream}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    summative_assessments: state?.exam?.summative_assessments,
    teacher_learning_areas: state?.course?.teacher_learning_areas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    teacherGetSummativeAssessments: (userId) =>
      dispatch(teacher_get_summative_assessments(userId)),
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
    getTeacherLearningAreas: (userId) =>
      dispatch(get_teacher_learning_areas(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummativeReports);
