import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { teacher_get_grade_streams } from "../../../../../redux/actions/course";
import { teacher_get_summative_assessments } from "../../../../../redux/actions/exam";
import { showError } from "../../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";
import OverallSummativeReport from "./OverallSummativeReport";

const SelectGradeStream = (props) => {
  const {
    openSelectGradeStream,
    userId,
    loading,
    grade_streams,
    summative_assessments,
  } = props;
  const {
    setOpenSelectGradeStream,
    startLoading,
    stopLoading,
    teacherGetSummativeAssessments,
    teacherGetGradeStreams,
  } = props;

  const [reportFilters, setReportFilters] = useState({
    gradeStreamId: "",
    assessmentId: "",
  });
  const { gradeStreamId, assessmentId } = reportFilters;
  const [reportData, setReportData] = useState({});
  const [openOverallSummativeReport, setOpenOverallSummativeReport] =
    useState(false);

  useEffect(() => {
    if (userId && summative_assessments?.length === 0) {
      startLoading();
      teacherGetSummativeAssessments(userId);
    }
  }, [
    userId,
    summative_assessments?.length,
    teacherGetSummativeAssessments,
    startLoading,
  ]);

  useEffect(() => {
    if (userId && grade_streams?.length === 0) {
      startLoading();
      teacherGetGradeStreams(userId);
    }
  }, [userId, grade_streams?.length, startLoading, teacherGetGradeStreams]);

  const handleChange = (e) => {
    setReportFilters({ ...reportFilters, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.get(
      `/api/exam/teacher/teacher-get-overall-grade-stream-summative-report/${userId}/${gradeStreamId}/${assessmentId}/`
    )
      .then((res) => {
        setReportData(res?.data);
        setOpenOverallSummativeReport(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MinDialog
        isOpen={openSelectGradeStream}
        maxWidth="600px"
        maxHeight="85vh"
      >
        <form class="dialog" onSubmit={handleSubmit}>
          <h3>Choose options to view overall performance</h3>
          <div class="dialog__rowSingleItem">
            <label for="">Select Grade</label>
            <select
              name="gradeStreamId"
              onChange={handleChange}
              value={gradeStreamId}
              required
            >
              <option value="">--select--</option>
              {grade_streams?.map((grade) => (
                <option value={grade?.id} key={grade?.id}>
                  {grade?.name}
                </option>
              ))}
            </select>
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <div className="dialog__rowSingleItem">
            <label for="">Select Assessment</label>
            <select
              name="assessmentId"
              onChange={handleChange}
              value={assessmentId}
              required
            >
              <option value="">--select--</option>
              {summative_assessments?.map((assessment) => (
                <option value={assessment?.id} key={assessment?.id}>
                  {assessment?.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenSelectGradeStream(false)}
            >
              Close
            </button>
            <button type="submit" className="continue__button">
              View
            </button>
          </div>
        </form>
      </MinDialog>
      {/* child components */}
      {openOverallSummativeReport && (
        <OverallSummativeReport
          openOverallSummativeReport={openOverallSummativeReport}
          setOpenOverallSummativeReport={setOpenOverallSummativeReport}
          reportData={reportData}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    grade_streams: state?.course?.grade_streams,
    summative_assessments: state?.exam?.summative_assessments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
    teacherGetGradeStreams: (userId) =>
      dispatch(teacher_get_grade_streams(userId)),
    teacherGetSummativeAssessments: (userId) =>
      dispatch(teacher_get_summative_assessments(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectGradeStream);
