import { combineReducers } from "redux";

import auth from "./auth";
import course from "./course";
import exam from "./exam";
import shared from "./shared";

export default combineReducers({
  auth,
  shared,
  course,
  exam,
});
