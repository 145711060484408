import * as api from "../../api";
import * as actionTypes from "../actions/types";

import { showError, stopLoading } from "../actions/shared";

export const add_school_term =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .addSchoolTerm(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_SCHOOL_TERM,
          payload: res?.data?.new_school_term_data,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

export const edit_school_term = (userId, body) => async (dispatch) => {
  await api
    .editSchoolTerm(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_SCHOOL_TERM,
        payload: res?.data?.updated_school_term_data,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const delete_school_term =
  (userId, schoolTermId) => async (dispatch) => {
    await api
      .deleteSchoolTerm(userId, schoolTermId)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_SCHOOL_TERM,
          payload: schoolTermId,
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

export const admin_get_school_terms = (userId) => async (dispatch) => {
  await api
    .adminGetSchoolTerms(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_ADMIN_SCHOOL_TERMS,
        payload: res?.data?.school_terms_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const admin_get_grade_levels = (userId) => async (dispatch) => {
  await api
    .adminGetGradeLevels(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_ADMIN_GRADE_LEVELS,
        payload: res?.data?.admin_grade_levels_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const admin_get_learning_areas = (userId) => async (dispatch) => {
  await api
    .adminGetLearningAreas(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_ADMIN_LEARNING_AREAS,
        payload: {
          admin_learning_areas: res?.data?.admin_learning_areas_data,
          static_school_terms: res?.data?.static_school_terms_data,
        },
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const get_learning_areas = (userId) => async (dispatch) => {
  await api
    .getLearningAreas(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_LEARNING_AREAS,
        payload: {
          learning_areas_data: res?.data?.learning_areas_data,
          learning_areas_strands_data: res?.data?.learning_areas_strands_data,
        },
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const get_teacher_learning_areas = (userId) => async (dispatch) => {
  await api
    .getTeacherLearningAreas(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_TEACHER_LEARNING_AREAS,
        payload: res?.data?.teacher_learning_areas_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const add_learning_area =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .addLearningArea(userId, body)
      .then((res) => {
        resetForm();
        dispatch({
          type: actionTypes.ADD_LEARNING_AREA,
          payload: res?.data?.new_learning_area_data,
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

export const edit_learning_area = (userId, body) => async (dispatch) => {
  await api
    .editLearningArea(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_LEARNING_AREA,
        payload: res?.data?.updated_learning_area_data,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const add_grade_level =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .addGradeLevel(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_GRADE_LEVEL,
          payload: res?.data?.new_grade_level_data,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

export const add_grade_stream =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .addGradeStream(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_GRADE_STREAM,
          payload: res?.data?.new_grade_stream_data,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

export const edit_grade_level = (userId, body) => async (dispatch) => {
  await api
    .editGradeLevel(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes?.EDIT_GRADE_LEVEL,
        payload: {
          updated_grade_level: res?.data?.updated_grade_level_data,
          // we then take the affected grade streams
          updated_grade_streams: res?.data?.updated_grade_streams_data,
        },
      });

      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const edit_grade_stream = (userId, body) => async (dispatch) => {
  await api
    .editGradeStream(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_GRADE_STREAM,
        payload: res?.data?.updated_grade_stream_data,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const delete_grade_level =
  (userId, gradeLevelId) => async (dispatch) => {
    await api
      .deleteGradeLevel(userId, gradeLevelId)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_GRADE_LEVEL,
          payload: gradeLevelId,
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

export const delete_grade_stream =
  (userId, gradeStreamId) => async (dispatch) => {
    await api
      .deleteGradeStream(userId, gradeStreamId)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_GRADE_STREAM,
          payload: gradeStreamId,
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

export const delete_learning_area =
  (userId, learningAreaId) => async (dispatch) => {
    await api
      .deleteLearningArea(userId, learningAreaId)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_LEARNING_AREA,
          payload: learningAreaId,
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

export const teacher_get_grade_streams = (userId) => async (dispatch) => {
  await api
    .teacherGetGradeStreams(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_GRADE_STREAMS,
        payload: {
          grade_streams: res?.data?.grade_streams,
          grade_levels: res?.data?.grade_levels,
          learning_areas: res?.data?.learning_areas,
        },
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};
