import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";
import TeacherStrands from "./TeacherStrands";

const FormativeAssessmentOptions = (props) => {
  const {
    openFormativeAssessmentOptions,
    teacher_learning_areas,
    userId,
    loading,
  } = props;
  const { setOpenFormativeAssessmentOptions, startLoading, stopLoading } =
    props;

  const [currentLearningArea, setCurrentLearningArea] = useState({});
  const [openStrands, setOpenStrands] = useState(false);

  const handleChange = (e) => {
    setCurrentLearningArea(
      teacher_learning_areas?.find((item) => item?.id === e.target.value)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.get(
      `/api/course/teacher/teacher-get-single-learning-area-strands/${userId}/${currentLearningArea?.learning_area_id}/${currentLearningArea?.grade_level_id}/`
    )
      .then((res) => {
        setCurrentLearningArea({
          ...currentLearningArea,
          strands: res?.data?.strands_data,
          sub_strands: res?.data?.sub_strands_data,
          rubrics: res?.data?.rubrics_data,
        });
        setOpenStrands(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };
  return (
    <>
      <MinDialog
        isOpen={openFormativeAssessmentOptions}
        maxWidth="600px"
        maxHeight="85vh"
      >
        <form class="dialog" onSubmit={handleSubmit}>
          <h3>Select learning area to open strands</h3>
          <div class="dialog__rowSingleItem">
            <label for="">Learning Area</label>
            <select
              onChange={handleChange}
              value={currentLearningArea?.id}
              required
            >
              <option value="">--select--</option>
              {teacher_learning_areas?.map((item) => (
                <option value={item?.id}>
                  {item?.grade_stream_name} - {item?.learning_area_name}
                </option>
              ))}
            </select>
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <div className="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenFormativeAssessmentOptions(false)}
            >
              Close
            </button>
            <button type="submit" className="continue__button">
              View
            </button>
          </div>
        </form>
      </MinDialog>
      {openStrands && (
        <TeacherStrands
          openStrands={openStrands}
          setOpenStrands={setOpenStrands}
          currentLearningArea={currentLearningArea}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    teacher_learning_areas: state?.course?.teacher_learning_areas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormativeAssessmentOptions);
