// material ui items
import Dialog from "@mui/material/Dialog";

const MediumDialog = (props) => {
  const handleBackdropClick = (e) => {
    // to ensure a user is able to click outside a dialog
    e.stopPropagation();
    return false;
  };

  return (
    <Dialog
      open={props.isOpen}
      maxWidth="md"
      style={{ maxWidth: props?.maxWidth, margin: "auto" }}
      fullWidth
      hideBackdrop
      disableEnforceFocus
      disableBackdropClick
      onBackdropClick={handleBackdropClick}
    >
      {props.children}
    </Dialog>
  );
};

export default MediumDialog;
