import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../../components/common/MaxDialog";

const AddRubric = (props) => {
  const { openAddRubric, currentSubStrand, loading } = props;
  const { setOpenAddRubric, handleAddNewRubric } = props;

  const [rubricDetails, setRubricDetails] = useState({
    title: "",
    exceeds_expectations: "",
    meets_expectations: "",
    approaches_expectations: "",
    below_expectations: "",
  });
  const {
    title,
    exceeds_expectations,
    meets_expectations,
    approaches_expectations,
    below_expectations,
  } = rubricDetails;

  const resetForm = () => {
    setRubricDetails({
      title: "",
      exceeds_expectations: "",
      meets_expectations: "",
      approaches_expectations: "",
      below_expectations: "",
    });
  };

  const handleChange = (e) => {
    setRubricDetails({ ...rubricDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddNewRubric(
      { ...rubricDetails, sub_strand: currentSubStrand?.id },
      resetForm
    );
  };

  return (
    <MaxDialog isOpen={openAddRubric} maxWidth="1000px" maxHeight="80vh">
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>New Rubric Details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Rubric Title</label>
          <input
            type="text"
            name="title"
            onChange={handleChange}
            value={title}
            required
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">
            <u>Performance Levels</u>
          </label>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Exceeds Expectations</label>
            <textarea
              name="exceeds_expectations"
              onChange={handleChange}
              value={exceeds_expectations}
              required
            />
          </span>
          <span>
            <label for="">Meets Expectations</label>
            <textarea
              name="meets_expectations"
              onChange={handleChange}
              value={meets_expectations}
              required
            />
          </span>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Approaches Expectations</label>
            <textarea
              name="approaches_expectations"
              onChange={handleChange}
              value={approaches_expectations}
              required
            />
          </span>
          <span>
            <label for="">Below Expectations</label>
            <textarea
              name="below_expectations"
              onChange={handleChange}
              value={below_expectations}
              required
            />
          </span>
        </div>
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenAddRubric(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Submit
          </button>
        </div>
      </form>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRubric);
