import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { edit_teacher } from "../../../../../redux/actions/auth";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";

const EditTeacher = (props) => {
  const { openEditTeacher, currentTeacher, loading, userId } = props;
  const { setOpenEditTeacher, setCurrentTeacher, editTeacher, startLoading } =
    props;

  const {
    first_name,
    last_name,
    surname,
    email,
    gender,
    phone_number,
    is_suspended,
    is_deactivated,
  } = currentTeacher;

  const handleChange = (e) => {
    if (
      e.target.name === "is_suspended" ||
      e.target.name === "is_deactivated"
    ) {
      setCurrentTeacher({
        ...currentTeacher,
        [e.target.name]: e.target.checked,
      });
    } else {
      setCurrentTeacher({ ...currentTeacher, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    editTeacher(userId, currentTeacher);
  };

  return (
    <>
      <MinDialog isOpen={openEditTeacher} maxWidth="800px" maxHeight="80vh">
        <form
          class="dialog"
          id={loading ? "formSubmitting" : ""}
          onSubmit={handleSubmit}
        >
          <h3>Edit teacher details</h3>
          <div class="dialog__row">
            <span>
              <label for="">First Name</label>
              <input
                type="text"
                name="first_name"
                onChange={handleChange}
                value={first_name}
                required
              />
            </span>
            <span>
              <label for="">Last Name</label>
              <input
                type="text"
                name="last_name"
                onChange={handleChange}
                value={last_name}
                required
              />
            </span>
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <div class="dialog__row">
            <span>
              <label for="">Surname</label>
              <input
                type="text"
                name="surname"
                onChange={handleChange}
                value={surname}
                required
              />
            </span>
            <span>
              <label for="">Email</label>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                value={email}
                required
              />
            </span>
          </div>
          <div class="dialog__row">
            <span>
              <label for="">Phone Number</label>
              <input
                type="text"
                name="phone_number"
                onChange={handleChange}
                value={phone_number}
                required
              />
            </span>
            <span>
              <label for="">Gender</label>
              <select
                name="gender"
                onChange={handleChange}
                value={gender}
                required
              >
                <option value="">--select--</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </span>
          </div>
          <div class="dialog__checkBoxListingItem">
            <input
              type="checkbox"
              name="is_suspended"
              onChange={handleChange}
              defaultChecked={is_suspended}
            />
            <label for="">
              Tick this box to suspend this user from the system
            </label>
          </div>
          <div class="dialog__checkBoxListingItem">
            <input
              type="checkbox"
              name="is_deactivated"
              onChange={handleChange}
              defaultChecked={is_deactivated}
            />
            <label for="">
              Tick this box to deactivate this user permanently
            </label>
          </div>
          <div class="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenEditTeacher(false)}
            >
              Close
            </button>
            <button type="submit" className="continue__button">
              Update
            </button>
          </div>
        </form>
      </MinDialog>
      {/* child components */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    editTeacher: (userId, body) => dispatch(edit_teacher(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTeacher);
