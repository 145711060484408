import React, { useState } from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import MinDialog from "../../../../../components/common/MinDialog";
import { showError } from "../../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";
import GradeStreamStudents from "./GradeStreamStudents";

const GradeStreamStudentsOptions = (props) => {
  const { openGradeStreamStudentsOptions, grade_streams, userId, loading } =
    props;
  const { setOpenGradeStreamStudentOptions, startLoading, stopLoading } = props;

  const [currentGradeStream, setCurrentGradeStream] = useState({});
  const [students, setStudents] = useState([]);
  const [openGradeStreamStudents, setOpenGradeStreamStudents] = useState(false);

  const handleChange = (e) => {
    setCurrentGradeStream(
      grade_streams?.find((item) => item?.id === e.target.value)
    );
  };

  const handleSubmit = async (e) => {
    startLoading();
    e.preventDefault();
    await API.get(
      `/api/user/teacher/teacher-get-grade-stream-students/${userId}/${currentGradeStream?.id}/`
    )
      .then((res) => {
        setStudents(res?.data?.students_data);
        setOpenGradeStreamStudents(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MinDialog
        isOpen={openGradeStreamStudentsOptions}
        maxWidth="500px"
        maxHeight="85vh"
      >
        <form
          className="dialog"
          id={loading && "formSubmitting"}
          onSubmit={handleSubmit}
        >
          <h3>Select grade stream to view students</h3>
          <div className="dialog__rowSingleItem">
            <label htmlFor="">Grade stream</label>
            <select name="" onChange={handleChange}>
              <option value="">--select--</option>
              {grade_streams?.map((item) => (
                <option value={item?.id} key={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <div className="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenGradeStreamStudentOptions(false)}
            >
              Close
            </button>
            <button type="submit" className="continue__button">
              View
            </button>
          </div>
        </form>
      </MinDialog>
      {/* child components */}

      {openGradeStreamStudents && (
        <GradeStreamStudents
          openGradeStreamStudents={openGradeStreamStudents}
          setOpenGradeStreamStudents={setOpenGradeStreamStudents}
          students={students}
          setStudents={setStudents}
          currentGradeStream={currentGradeStream}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    grade_streams: state?.course?.grade_streams,
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GradeStreamStudentsOptions);
