import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get_teacher_learning_areas } from "../../../../redux/actions/course";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import FormativeReportOptions from "./components/FormativeReportOptions";

const FormativeReports = (props) => {
  const { loading, userId, teacher_learning_areas } = props;
  const { startLoading, getTeacherLearningAreas } = props;

  const [openFormativeReportOptions, setOpenFormativeReportOptions] =
    useState(false);

  useEffect(() => {
    if (userId && teacher_learning_areas?.length === 0) {
      startLoading();
      getTeacherLearningAreas(userId);
    }
  }, [
    startLoading,
    getTeacherLearningAreas,
    teacher_learning_areas?.length,
    userId,
  ]);

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div className="table__columns">
          <div className="column">
            <h3>Formative learning area report</h3>
            <div className="buttons">
              <button
                type="button"
                onClick={() => setOpenFormativeReportOptions(true)}
              >
                Open
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* child components */}

      {openFormativeReportOptions && (
        <FormativeReportOptions
          openFormativeReportOptions={openFormativeReportOptions}
          setOpenFormativeReportOptions={setOpenFormativeReportOptions}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    teacher_learning_areas: state?.course?.teacher_learning_areas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    getTeacherLearningAreas: (userId) =>
      dispatch(get_teacher_learning_areas(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormativeReports);
