import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";

const EditStrand = (props) => {
  const { openEditStrand, currentStrand, loading } = props;
  const {
    setOpenEditStrand,
    setCurrentStrand,
    handleEditStrand,
    static_school_terms,
  } = props;

  const { title, num, is_active, static_school_term } = currentStrand;

  const handleChange = (e) => {
    setCurrentStrand({ ...currentStrand, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setCurrentStrand({
      ...currentStrand,
      [e.target.name]: !e.target.defaultChecked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleEditStrand(currentStrand);
  };

  return (
    <MinDialog isOpen={openEditStrand} maxWidth="500px" maxHeight="400px">
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Edit strand details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Select strand term</label>
          <select
            name="static_school_term"
            onChange={handleChange}
            value={static_school_term}
            required
          >
            <option value="">--select--</option>
            {static_school_terms?.map((item) => (
              <option value={item?.id} key={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Strand Number</label>
          <input
            type="text"
            name="num"
            onChange={handleChange}
            value={num}
            required
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Strand Title</label>
          <input
            type="text"
            name="title"
            onChange={handleChange}
            value={title}
            required
          />
        </div>
        <div class="dialog__checkBoxListingItem">
          <input
            type="checkbox"
            name="is_active"
            onChange={handleCheckboxChange}
            defaultChecked={is_active}
          />
          <label for="">Uncheck this box to deactivate this strand</label>
        </div>
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenEditStrand(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Update
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    static_school_terms: state?.course?.static_school_terms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStrand);
