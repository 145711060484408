import { CircularProgress } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { edit_grade_stream } from "../../../../../redux/actions/course";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";

const EditGradeStream = (props) => {
  const { openEditGradeStream, currentGradeStream, loading, userId } = props;
  const {
    setOpenEditGradeStream,
    setCurrentGradeStream,
    startLoading,
    editGradeStream,
    grade_levels,
  } = props;

  const { grade_level, name, is_active } = currentGradeStream;

  const handleChange = (e) => {
    setCurrentGradeStream({
      ...currentGradeStream,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e) => {
    setCurrentGradeStream({
      ...currentGradeStream,
      [e.target.name]: !e.target.defaultChecked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    editGradeStream(userId, currentGradeStream);
  };

  return (
    <MinDialog isOpen={openEditGradeStream} maxWidth="500px" maxHeight="400px">
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Edit grade stream</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Grade Stream Name</label>
          <input
            type="text"
            name="name"
            onChange={handleChange}
            value={name}
            required
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Grade Level</label>
          <select
            name="grade_level"
            value={grade_level}
            onChange={handleChange}
            required
          >
            <option value="">--select--</option>
            {grade_levels?.map((grade_level_item) => (
              <option value={grade_level_item?.id} key={grade_level_item?.id}>
                {grade_level_item?.name}
              </option>
            ))}
          </select>
        </div>

        <div class="dialog__checkBoxListingItem">
          <input
            type="checkbox"
            name="is_active"
            onChange={handleCheckboxChange}
            defaultChecked={is_active}
          />
          <label for="">Uncheck this box to deactivate this grade stream</label>
        </div>
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenEditGradeStream(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Update
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    grade_levels: state?.course?.grade_levels,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    editGradeStream: (userId, body) =>
      dispatch(edit_grade_stream(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGradeStream);
