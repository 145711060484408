const SchoolAdminLinks = ({ Link, pathname, toggleSubmenu, openSubMenu }) => {
  return (
    <>
      <Link
        to="#"
        className="nav__link"
        onClick={() => toggleSubmenu("Academics")}
      >
        <i className="bx bxs-school"></i>
        <span className="nav__name">Academics</span>
      </Link>
      {/* academic sub menus */}
      {openSubMenu === "Academics" && (
        <>
          <Link
            to="/school-admin/school-terms/"
            className={
              `${pathname}` === "/school-admin/school-terms/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; School Terms</span>
          </Link>
          <Link
            to="/school-admin/grade-levels/"
            className={
              `${pathname}` === "/school-admin/grade-levels/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Grade Levels</span>
          </Link>
          <Link
            to="/school-admin/learning-areas/"
            className={
              `${pathname}` === "/school-admin/learning-areas/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Learning Areas</span>
          </Link>
        </>
      )}

      <Link to="#" className="nav__link" onClick={() => toggleSubmenu("Users")}>
        <i className="bx bxs-school"></i>
        <span className="nav__name">Users</span>
      </Link>
      {openSubMenu === "Users" && (
        <>
          <Link
            to="/school-admin/teachers/"
            className={
              `${pathname}` === "/school-admin/teachers/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Teachers</span>
          </Link>
          <Link
            to="/school-admin/other-staff/"
            className={
              `${pathname}` === "/school-admin/other-staff/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Other Staff</span>
          </Link>
        </>
      )}
    </>
  );
};

export default SchoolAdminLinks;
