import * as actionTypes from "../actions/types";
import { updateObject } from "../utility";

const initialState = {
  admin_school_terms: [],
  school_terms: [],
  admin_grade_levels: [],
  grade_streams: [],
  grade_levels: [],
  admin_learning_areas: [],
  static_school_terms: [],
  learning_areas: [],
  teacher_learning_areas: [],
};

const setAdminSchoolTerms = (state, payload) => {
  return updateObject(state, {
    admin_school_terms: payload,
  });
};

const setSchoolTerms = (state, payload) => {
  return updateObject(state, {
    school_terms: payload,
  });
};

const addSchoolTerm = (state, payload) => {
  return updateObject(state, {
    admin_school_terms: [...state?.admin_school_terms, payload],
  });
};

const editSchoolTerm = (state, payload) => {
  return updateObject(state, {
    admin_school_terms: state?.admin_school_terms?.map((school_term) =>
      school_term?.id === payload?.id ? payload : school_term
    ),
  });
};
const deleteSchoolTerm = (state, payload) => {
  return updateObject(state, {
    admin_school_terms: state?.admin_school_terms?.filter(
      (school_term) => school_term?.id !== payload
    ),
  });
};

const setAdminGradeLevels = (state, payload) => {
  return updateObject(state, {
    admin_grade_levels: payload,
  });
};

const setAdminLearningAreas = (state, payload) => {
  return updateObject(state, {
    admin_learning_areas: payload?.admin_learning_areas,
    static_school_terms: payload?.static_school_terms,
  });
};

const setLearningAreas = (state, payload) => {
  return updateObject(state, {
    learning_areas: payload?.learning_areas_data,
  });
};

const setTeacherLearningAreas = (state, payload) => {
  return updateObject(state, {
    teacher_learning_areas: payload,
  });
};

const addLearningArea = (state, payload) => {
  return updateObject(state, {
    admin_learning_areas: [...state?.admin_learning_areas, payload],
  });
};

const addGradeLevel = (state, payload) => {
  return updateObject(state, {
    admin_grade_levels: [...state?.admin_grade_levels, payload],
  });
};

const editGradeLevel = (state, payload) => {
  return updateObject(state, {
    admin_grade_levels: state?.admin_grade_levels?.map((item) =>
      item?.id === payload?.updated_grade_level?.id
        ? payload?.updated_grade_level
        : item
    ),

    // we then update the affected grade streams
    admin_grade_streams: state?.admin_grade_streams?.map((item) => {
      const found = payload?.updated_grade_streams?.find(
        (updatedItem) => updatedItem?.id === item?.id
      );
      return found ? found : item;
    }),
  });
};

const addGradeStream = (state, payload) => {
  return updateObject(state, {
    grade_streams: [...state?.grade_streams, payload],
  });
};

const editGradeStream = (state, payload) => {
  return updateObject(state, {
    grade_streams: state?.grade_streams?.map((grade_stream) =>
      grade_stream?.id === payload?.id ? payload : grade_stream
    ),
  });
};

const editLearningArea = (state, payload) => {
  return updateObject(state, {
    admin_learning_areas: state?.admin_learning_areas?.map((learning_area) =>
      learning_area?.id === payload?.id ? payload : learning_area
    ),
  });
};

const deleteGradeLevel = (state, payload) => {
  return updateObject(state, {
    admin_grade_levels: state?.admin_grade_levels?.filter(
      (grade_level) => grade_level?.id !== payload
    ),
  });
};

const deleteGradeStream = (state, payload) => {
  return updateObject(state, {
    grade_streams: state?.grade_streams?.filter(
      (grade_stream) => grade_stream?.id !== payload
    ),
  });
};

const deleteLearningArea = (state, payload) => {
  return updateObject(state, {
    admin_learning_areas: state?.admin_learning_areas?.filter(
      (learning_area) => learning_area?.id !== payload
    ),
  });
};

const setTeacherGradeStreams = (state, payload) => {
  return updateObject(state, {
    grade_streams: payload?.grade_streams,
    grade_levels: payload?.grade_levels,
    learning_areas: payload?.learning_areas,
  });
};

const courseReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_ADMIN_SCHOOL_TERMS:
      return setAdminSchoolTerms(state, payload);
    case actionTypes.SET_SCHOOL_TERMS:
      return setSchoolTerms(state, payload);
    case actionTypes.ADD_SCHOOL_TERM:
      return addSchoolTerm(state, payload);
    case actionTypes.EDIT_SCHOOL_TERM:
      return editSchoolTerm(state, payload);
    case actionTypes.DELETE_SCHOOL_TERM:
      return deleteSchoolTerm(state, payload);
    case actionTypes.SET_ADMIN_GRADE_LEVELS:
      return setAdminGradeLevels(state, payload);
    case actionTypes.SET_ADMIN_LEARNING_AREAS:
      return setAdminLearningAreas(state, payload);
    case actionTypes.SET_LEARNING_AREAS:
      return setLearningAreas(state, payload);
    case actionTypes.SET_TEACHER_LEARNING_AREAS:
      return setTeacherLearningAreas(state, payload);
    case actionTypes.ADD_LEARNING_AREA:
      return addLearningArea(state, payload);
    case actionTypes.ADD_GRADE_LEVEL:
      return addGradeLevel(state, payload);
    case actionTypes.EDIT_GRADE_LEVEL:
      return editGradeLevel(state, payload);
    case actionTypes.ADD_GRADE_STREAM:
      return addGradeStream(state, payload);
    case actionTypes.EDIT_GRADE_STREAM:
      return editGradeStream(state, payload);
    case actionTypes.EDIT_LEARNING_AREA:
      return editLearningArea(state, payload);
    case actionTypes.DELETE_GRADE_LEVEL:
      return deleteGradeLevel(state, payload);
    case actionTypes.DELETE_GRADE_STREAM:
      return deleteGradeStream(state, payload);
    case actionTypes.DELETE_LEARNING_AREA:
      return deleteLearningArea(state, payload);
    case actionTypes.SET_GRADE_STREAMS:
      return setTeacherGradeStreams(state, payload);
    default:
      return state;
  }
};

export default courseReducer;
