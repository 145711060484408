import moment from "moment";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import MaxDialog from "../../../../../components/common/MaxDialog";
import ReportHeader from "../../../../../shared/ReportHeader/ReportHeader";

const StudentOverallReportCard = (props) => {
  const {
    openStudentOverallReportCard,
    currentStudentResult,
    summativeAssessmentDetails,
  } = props;
  const { setOpenStudentOverallReportCard } = props;

  const {
    admission_number,
    student_name,
    class_level_position,
    class_stream_position,
    learning_areas_results,
    grade_stream_name,
    grade_stream_students_number,
    marks_scored,
  } = currentStudentResult;
  const {
    term_start_date,
    term_end_date,
    name,
    summative_assessment_total_points,
    grade_level_students_number,
  } = summativeAssessmentDetails;

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: "Student overall report card",
  });

  return (
    <MaxDialog
      isOpen={openStudentOverallReportCard}
      maxWidth="1200px"
      maxHeight="85vh"
    >
      <div class="dialog">
        <table
          class="table__listing"
          rules="all"
          border="1"
          ref={printArea}
          id="student_report_card"
        >
          <ReportHeader />
          <tr>
            <th colSpan="50%">
              Grade Stream: <span class="purple">{grade_stream_name}</span>{" "}
            </th>
            <th colSpan="50%" style={{ padding: "5px" }}>
              School Term:{" "}
              <span class="purple">
                {moment(term_start_date).format("LL")} to{" "}
                {moment(term_end_date).format("LL")}
              </span>{" "}
            </th>
          </tr>
          <tr>
            <th colSpan="50%" style={{ padding: "5px" }}>
              Admission No: <span class="purple">{admission_number}</span>{" "}
            </th>
            <th colSpan="50%">
              Student Name: <span className="purple">{student_name}</span>
            </th>
          </tr>
          <tr>
            <th colSpan="50%" style={{ padding: "5px" }}>
              Assessment: <span class="purple">{name}</span>{" "}
            </th>
            <th colSpan="50%">
              Overall Position:{" "}
              <span class="purple">
                {class_stream_position} out of {grade_stream_students_number}
              </span>
            </th>
          </tr>
          <thead>
            <tr class="table__listingHeader">
              <th>Learning Area</th>
              <th className="tc" colSpan="25%">
                Marks Scored
              </th>
              <th className="tc" colSpan="25%">
                Position{" "}
                <span style={{ fontSize: "0.7rem", color: "#f2e70f" }}>
                  (out of {grade_stream_students_number})
                </span>
              </th>
              <th colSpan="25%">Comments</th>
            </tr>
          </thead>
          <tbody>
            {learning_areas_results?.map((result_item) => (
              <tr class="table__listingItem" key={result_item?.id}>
                <td>{result_item?.learning_area_name}</td>
                <td className="tc" colSpan="25%">
                  {result_item?.marks_scored} / {result_item?.total_points}
                </td>

                <td className="tc" colSpan="25%">
                  {result_item?.class_stream_position}
                </td>
                <td colSpan="25%">{result_item?.comments}</td>
              </tr>
            ))}
            <tr>
              <th colSpan="100%">...</th>
            </tr>
            <tr className="table__listingFooter">
              <th colSpan="100%" className="tc">
                Total Marks: {marks_scored} out of{" "}
                {summative_assessment_total_points}
              </th>
            </tr>
          </tbody>
        </table>
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenStudentOverallReportCard(false)}
          >
            Close
          </button>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button export_to_excel_button"
            table="student_report_card"
            filename="student_report_card"
            sheet="student_report_card"
            buttonText="Export To Excel"
          />
          <button
            type="button"
            className="continue__button"
            onClick={handlePrint}
          >
            Print/Save
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

export default StudentOverallReportCard;
