import { useEffect, useState } from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import MaxDialog from "../../../../components/common/MaxDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";
import TeacherSubStrands from "./TeacherSubStrands";

const TeacherStrands = (props) => {
  const { openStrands, currentLearningArea, userId, loading } = props;
  const { setOpenStrands, startLoading, stopLoading } = props;
  const { strands, grade_stream_name, learning_area_name } =
    currentLearningArea;

  const [openSubStrands, setOpenSubStrands] = useState(false);

  const [currentStrand, setCurrentStrand] = useState({});
  const [filterTerm, setFilterTerm] = useState("all");
  const [filteredStrands, setFilteredStrands] = useState([]);

  useEffect(() => {
    if (filterTerm === "all") {
      setFilteredStrands(strands);
    } else {
      setFilteredStrands(
        strands?.filter((item) => item?.static_school_term === filterTerm)
      );
    }
  }, [filterTerm, strands]);

  const handleOpenSubStrands = async (strand) => {
    startLoading();
    await API.get(
      `/api/course/teacher/teacher-get-substrands/${userId}/${strand?.id}/`
    )
      .then((res) => {
        setCurrentStrand({
          ...strand,
          sub_strands: res?.data?.sub_strands_data,
        });
        setOpenSubStrands(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MaxDialog isOpen={openStrands} maxWidth="1200px" maxHeight="85vh">
        <div class="dialog" id={loading && "formSubmitting"}>
          <h3>
            {grade_stream_name} {learning_area_name}
          </h3>
          <br />
          <p className="tc">
            <strong>Section: </strong>Strands
          </p>
          <br />
          <div className="dialog__rowSingleItem" style={{ maxWidth: "300px" }}>
            <label htmlFor="">Filter strands by term</label>
            <select
              name=""
              onChange={(e) => setFilterTerm(e.target.value)}
              value={filterTerm}
            >
              <option value="all">all</option>
              <option value="term_1">Term 1</option>
              <option value="term_2">Term 2</option>
              <option value="term_3">Term 3</option>
            </select>
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          {filteredStrands?.length > 0 ? (
            <table class="table__listing" rules="all" border="1">
              <thead>
                <tr class="table__listingHeader">
                  <th>#</th>
                  <th>Title</th>
                  <th>SubStrands</th>
                </tr>
              </thead>
              <tbody>
                {filteredStrands?.map((strand) => (
                  <tr class="table__listingItem" key={strand?.id}>
                    <td>{strand?.num}</td>
                    <td>{strand?.title}</td>
                    <td
                      className="dodgerblue bd button__sp"
                      onClick={() => handleOpenSubStrands(strand)}
                    >
                      SubStrands
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 class="not__available">No strands available</h4>
          )}
          <div class="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenStrands(false)}
            >
              Close
            </button>
          </div>
        </div>
      </MaxDialog>

      {/* child components */}
      {openSubStrands && (
        <TeacherSubStrands
          openSubStrands={openSubStrands}
          setOpenSubStrands={setOpenSubStrands}
          sub_strands={currentStrand?.sub_strands}
          currentGradeStreamLearningAreaId={currentLearningArea?.id}
          gradeStreamLearningAreaName={`${grade_stream_name} - ${learning_area_name}`}
          currentStrandName={currentStrand?.title}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherStrands);
