import API from "../shared/axios";

// get user data
export const getUser = () => API.get("/api/user/user-maintain-self/");

export const adminGetSchoolTerms = (userId) =>
  API.get(`/api/course/school-admin/maintain-school-terms/${userId}/`);

export const getTeachers = (userId) =>
  API.get(`/api/user/school-admin/maintain-teachers/${userId}/`);

export const adminGetGradeLevels = (userId) =>
  API.get(`/api/course/school-admin/maintain-grade-levels/${userId}/`);

export const getGradeStreams = (userId) =>
  API.get(`/api/course/get-grade-streams/${userId}/`);

export const adminGetLearningAreas = (userId) =>
  API.get(`/api/course/school-admin/maintain-learning-areas/${userId}/`);

export const getLearningAreas = (userId) =>
  API.get(`/api/course/get-learning-areas/${userId}/`);

export const getTeacherLearningAreas = (userId) =>
  API.get(`/api/course/teacher/get-teacher-learning-areas/${userId}/`);

export const adminGetSummativeAssessments = (userId) =>
  API.get(`/api/exam/school-admin/maintain-summative-assessments/${userId}/`);

export const teacherGetSummativeAssessments = (userId) =>
  API.get(`/api/exam/teacher/maintain-summative-assessments/${userId}/`);

export const teacherGetGradeStreams = (userId) =>
  API.get(`/api/course/teacher/teacher-maintain-grade-streams/${userId}/`);
