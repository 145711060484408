// import installed packages
import { connect } from "react-redux";

// import styles

import "./Header.scss";
// import material ui items
// import shared/global items
import { showNavbar } from "../../../shared/scripts";
// import components/pages

// import redux API
import { OPEN_LOGIN } from "../../../redux/actions/types";

import ForgotPassword from "../../users/ForgotPassword";
import Login from "../../users/Login";
import ReactivateAccount from "../../users/ReactivateAccount";
import Signup from "../../users/Signup";

const Header = (props) => {
  const {
    first_name,
    loggedIn,
    loginForm,
    signupForm,
    resendActivationForm,
    forgotPasswordForm,
  } = props;
  const { openLogin } = props;
  const session_cookie = localStorage.getItem("session_cookie");

  return (
    <>
      <header className="header" id="header">
        <div className="header__left">
          {loggedIn && (
            <>
              <i
                className="bx bx-menu showBtn dodgerblue"
                id="mobile__menuBtnShow"
                onClick={showNavbar}
              ></i>
              <i
                className="bx bx-window-close hideBtn red"
                onClick={showNavbar}
                id="mobile__menuBtnHide"
              ></i>
            </>
          )}

          <h1>Funzo Letu</h1>
        </div>

        {session_cookie && (
          <div className="header__right">
            <h6>{first_name}</h6>
          </div>
        )}

        {!loggedIn && (
          <div className="header__right">
            <span className="button" onClick={openLogin}>
              Login
            </span>
          </div>
        )}
      </header>
      {/* components */}
      {loginForm && <Login />}
      {signupForm && <Signup />}
      {forgotPasswordForm && <ForgotPassword />}
      {resendActivationForm && <ReactivateAccount />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    first_name: state.auth.user?.first_name,
    loginForm: state.auth?.loginForm,
    signupForm: state.auth?.signupForm,
    forgotPasswordForm: state.auth?.forgotPasswordForm,
    resendActivationForm: state.auth?.resendActivationForm,
    loggedIn: state?.auth?.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openLogin: () => dispatch({ type: OPEN_LOGIN }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
