import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { connect } from "react-redux";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";
import GroupsAllocated from "../Shared/GroupsAllocated";
import AddOtherStaff from "./components/AddOtherStaff";
import EditOtherStaff from "./components/EditOtherStaff";

const OtherStaff = (props) => {
  const { loading, userId } = props;
  const { startLoading, stopLoading } = props;

  const [staffPhone, setStaffPhone] = useState("");
  const [currentStaff, setCurrentStaff] = useState(null);

  const [openAddOtherStaff, setOpenAddOtherStaff] = useState(false);
  const [openGroupsAllocated, setOpenGroupsAllocated] = useState(false);
  const [openEditOtherStaff, setOpenEditOtherStaff] = useState(false);

  const searchOtherStaff = async (e) => {
    e.preventDefault();
    startLoading();
    await API.post(`/api/user/school-admin/search-other-staff/${userId}/`, {
      staffPhone,
    })
      .then((res) => {
        setCurrentStaff(res?.data?.staff_data);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  const handleOpenGroups = (staff) => {
    setOpenGroupsAllocated(true);
    setCurrentStaff(staff);
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div class="table__parentHeader">
          <button
            type="button"
            className="add__button"
            onClick={() => setOpenAddOtherStaff(true)}
          >
            Add Other Staff
          </button>
          <h3>Search staff by phone number</h3>
          <form className="dialog" onSubmit={searchOtherStaff}>
            <div class="dialog__rowSingleItem" style={{ maxWidth: "200px" }}>
              <label for="">Staff Phone</label>
              <input
                type="text"
                name=""
                onChange={(e) => setStaffPhone(e.target.value)}
                value={staffPhone}
                required
              />
            </div>
            <button type="submit" className="add__button">
              Search
            </button>
          </form>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeftL: "45%" }}
          />
        )}
        {currentStaff !== null && (
          <table class="table__listing" rules="all" border="1">
            <thead>
              <tr class="table__listingHeader">
                <th>Name</th>
                <th>Login Status</th>
                <th>Account Status</th>
                <th>Edit</th>
                <th>Groups</th>
              </tr>
            </thead>
            <tbody>
              <tr class="table__listingItem">
                <td>
                  {currentStaff?.first_name} {currentStaff?.last_name}
                </td>
                <td>
                  {currentStaff?.is_suspended && (
                    <span className="red">suspended</span>
                  )}
                  {!currentStaff?.is_suspended && (
                    <span className="green">active</span>
                  )}
                </td>
                <td>
                  {currentStaff?.is_deactivated && (
                    <span className="red">Inactive</span>
                  )}
                  {!currentStaff?.is_deactivated && (
                    <span className="green">active</span>
                  )}
                </td>
                <td
                  className="dodgerblue button__sp bd"
                  onClick={() => setOpenEditOtherStaff(true)}
                >
                  Edit
                </td>
                <td
                  onClick={() => handleOpenGroups(currentStaff)}
                  className="dodgerblue bd button__sp"
                >
                  Groups
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      {/* child components */}
      {openAddOtherStaff && (
        <AddOtherStaff
          openAddOtherStaff={openAddOtherStaff}
          setOpenAddOtherStaff={setOpenAddOtherStaff}
        />
      )}
      {openEditOtherStaff && (
        <EditOtherStaff
          openEditOtherStaff={openEditOtherStaff}
          setOpenEditOtherStaff={setOpenEditOtherStaff}
          currentStaff={currentStaff}
          setCurrentStaff={setCurrentStaff}
        />
      )}
      {openGroupsAllocated && (
        <GroupsAllocated
          openGroupsAllocated={openGroupsAllocated}
          setOpenGroupsAllocated={setOpenGroupsAllocated}
          currentStaff={currentStaff}
          userType="Other_Staff"
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherStaff);
