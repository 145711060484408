import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { add_other_staff } from "../../../../../redux/actions/auth";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";

const AddOtherStaff = (props) => {
  const { openAddOtherStaff, userId, loading } = props;
  const { setOpenAddOtherStaff, addOtherStaff, startLoading } = props;

  const [staffDetails, setStaffDetails] = useState({
    first_name: "",
    last_name: "",
    surname: "",
    email: "",
    gender: "",
    phone_number: "",
    password: "",
    confirm_password: "",
  });

  const {
    first_name,
    last_name,
    surname,
    email,
    phone_number,
    gender,
    password,
    confirm_password,
  } = staffDetails;

  const handleChange = (e) => {
    setStaffDetails({ ...staffDetails, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setStaffDetails({
      first_name: "",
      last_name: "",
      surname: "",
      email: "",
      gender: "",
      phone_number: "",
      password: "",
      confirm_password: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirm_password) {
      return window.alert(
        "Request failed, password and confirm password should match"
      );
    }
    startLoading();
    addOtherStaff(userId, staffDetails, resetForm);
  };

  return (
    <MinDialog isOpen={openAddOtherStaff} maxWidth="800px" maxHeight="85vh">
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Enter staff details</h3>
        <div class="dialog__row">
          <span>
            <label for="">First Name</label>
            <input
              type="text"
              name="first_name"
              onChange={handleChange}
              value={first_name}
              required
            />
          </span>
          <span>
            <label for="">Last Name</label>
            <input
              type="text"
              name="last_name"
              onChange={handleChange}
              value={last_name}
              required
            />
          </span>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Surname</label>
            <input
              type="text"
              name="surname"
              onChange={handleChange}
              value={surname}
              required
            />
          </span>
          <span>
            <label for="">
              Email<span className="green">(Optional)</span>
            </label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              value={email}
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__row">
          <span>
            <label for="">Phone Number</label>
            <input
              type="text"
              name="phone_number"
              onChange={handleChange}
              value={phone_number}
              required
            />
          </span>
          <span>
            <label for="">Gender</label>
            <select
              name="gender"
              onChange={handleChange}
              value={gender}
              required
            >
              <option value="">--select--</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </span>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Password</label>
            <input
              type="password"
              name="password"
              onChange={handleChange}
              value={password}
              required
            />
          </span>
          <span>
            <label for="">Confirm Password</label>
            <input
              type="password"
              name="confirm_password"
              onChange={handleChange}
              value={confirm_password}
              required
            />
          </span>
        </div>
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenAddOtherStaff(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    addOtherStaff: (userId, body, resetForm) =>
      dispatch(add_other_staff(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOtherStaff);
