import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../components/common/MaxDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";

const UseRubric = (props) => {
  const {
    openUseRubric,
    currentRubric,
    userId,
    currentGradeStreamLearningAreaId,
    loading,
  } = props;
  const { setOpenUseRubric, startLoading, stopLoading } = props;

  const [gradeStreamStudents, setGradeStreamStudents] = useState([]);
  const [rubricAssessedOn, setRubricAssessedOn] = useState(""); // date the rubric was assessed
  const [rubricSubmitted, setRubricSubmitted] = useState(""); // keeps status if rubric is submitted to final report
  const [rubricTermId, setRubricTermId] = useState("");
  const [schoolTermId, setSchoolTermId] = useState("");
  const [newRubricAssessment, setNewRubricAssessment] = useState("");

  useEffect(() => {
    if (userId && currentGradeStreamLearningAreaId) {
      const fetchData = async () => {
        startLoading();
        await API.get(
          `/api/course/teacher/assess-students-rubrics/${userId}/${currentGradeStreamLearningAreaId}/${currentRubric?.id}/`
        )
          .then((res) => {
            setGradeStreamStudents(res?.data?.grade_stream_students);
            setSchoolTermId(res?.data?.schoolTermId);
            if (res?.data?.rubricTermExists) {
              setRubricTermId(res?.data?.rubricTermId);
              setRubricAssessedOn(res?.data?.created_on);
              setRubricSubmitted(res?.data?.rubric_submitted);
              setNewRubricAssessment(false);
            } else if (!res?.data?.rubricTermExists) {
              setNewRubricAssessment(true);
              // set new date for new rubric assessment, note running moment() gives us today's day
              setRubricAssessedOn(moment().format("YYYY-MM-DD"));
            }
          })
          .catch((err) => {
            showError(err);
            setOpenUseRubric(false);
          })
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [
    startLoading,
    stopLoading,
    userId,
    currentGradeStreamLearningAreaId,
    currentRubric?.id,
    setOpenUseRubric,
  ]);

  // this handleChange uses custom comments input by the teacher

  // const handleChange = (e, studentIndex) => {
  //   const arrayToMutate = [...gradeStreamStudents];
  //   if (e.target.name === "comments") {
  //     // we set the comments value
  //     arrayToMutate[studentIndex] = {
  //       ...arrayToMutate[studentIndex],
  //       comments: e.target.value,
  //     };
  //   } else {
  //     // we set the performance level
  //     arrayToMutate[studentIndex] = {
  //       ...arrayToMutate[studentIndex],
  //       performance_level: e.target.value,
  //     };
  //   }
  //   setGradeStreamStudents(arrayToMutate);
  // };

  // this handleChange uses comments based on description of performance level
  const handleChange = (e, studentIndex) => {
    let comments;
    if (e.target.value === "exceeds_expectations") {
      comments = currentRubric?.exceeds_expectations;
    } else if (e.target.value === "meets_expectations") {
      comments = currentRubric?.meets_expectations;
    } else if (e.target.value === "approaches_expectations") {
      comments = currentRubric?.approaches_expectations;
    } else if (e.target.value === "below_expectations") {
      comments = currentRubric?.below_expectations;
    }
    const arrayToMutate = [...gradeStreamStudents];

    // we set the performance level
    arrayToMutate[studentIndex] = {
      ...arrayToMutate[studentIndex],
      performance_level: e.target.value,
      comments,
    };
    setGradeStreamStudents(arrayToMutate);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (gradeStreamStudents?.length > 0) {
      startLoading();
      let body;
      if (newRubricAssessment) {
        body = {
          gradeStreamStudents,
          rubricAssessedOn,
          schoolTermId,
          newRubricAssessment,
        };
      } else if (!newRubricAssessment) {
        body = {
          gradeStreamStudents,
          rubricAssessedOn,
          schoolTermId,
          newRubricAssessment,
          rubricTermId,
        };
      }
      await API.post(
        `/api/course/teacher/assess-students-rubrics/${userId}/${currentGradeStreamLearningAreaId}/${currentRubric?.id}/`,
        body
      )
        .then((res) => {
          // we set this here to activating useEffect again to get the updated data instead of rewriting backend functions, note this is a dependency in the [] in useeffect
          setOpenUseRubric(false);
          window.alert(res?.data.detail);
        })
        .catch((err) => showError(err))
        .finally(() => {
          // we set this here to activating useEffect again to get the updated data instead of rewriting backend functions, note this is a dependency in the [] in useeffect
          setOpenUseRubric(true);
          stopLoading();
        });
    }
  };

  return (
    <MaxDialog isOpen={openUseRubric} maxWidth="1200px" maxHeight="85vh">
      <form
        class="dialog"
        onSubmit={handleSubmit}
        id={loading ? "formSubmitting" : ""}
      >
        <h3>{currentRubric?.title}</h3>
        <p style={{ textAlign: "center" }}>
          <strong>KEY: EE</strong> - Exceeds Expectations, <strong>ME</strong> -
          Meets Expectations, <strong>AE</strong> - Approaches Expectations,{" "}
          <strong>BE</strong>- Below Expectations
        </p>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {gradeStreamStudents?.length > 0 ? (
          <>
            <div className="dialog__row">
              <span>
                <label htmlFor="">Rubric Assessed On</label>
                <input
                  type="date"
                  value={rubricAssessedOn}
                  onChange={(e) => setRubricAssessedOn(e.target.value)}
                  required
                />
              </span>
            </div>
            <table class="table__listing" rules="all" border="1">
              <thead>
                <tr class="table__listingHeader">
                  <th>Student</th>
                  <th
                    className="tc"
                    title={currentRubric?.exceeds_expectations}
                  >
                    EE
                  </th>
                  <th className="tc" title={currentRubric?.meets_expectations}>
                    ME
                  </th>
                  <th
                    className="tc"
                    title={currentRubric?.approaches_expectations}
                  >
                    AE
                  </th>
                  <th className="tc" title={currentRubric?.below_expectations}>
                    BE
                  </th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>
                {gradeStreamStudents?.map((student, index) => (
                  <tr class="table__listingItem" key={student?.id}>
                    <td>{student?.name}</td>
                    <td className="tc">
                      <input
                        type="radio"
                        name={student?.id}
                        value="exceeds_expectations"
                        defaultChecked={
                          student?.performance_level === "exceeds_expectations"
                        }
                        onChange={(e) => handleChange(e, index)}
                      />
                    </td>
                    <td className="tc">
                      <input
                        type="radio"
                        name={student?.id}
                        value="meets_expectations"
                        defaultChecked={
                          student?.performance_level === "meets_expectations"
                        }
                        onChange={(e) => handleChange(e, index)}
                      />
                    </td>
                    <td className="tc">
                      <input
                        type="radio"
                        name={student?.id}
                        value="approaches_expectations"
                        defaultChecked={
                          student?.performance_level ===
                          "approaches_expectations"
                        }
                        onChange={(e) => handleChange(e, index)}
                      />
                    </td>
                    <td className="tc">
                      <input
                        type="radio"
                        name={student?.id}
                        value="below_expectations"
                        defaultChecked={
                          student?.performance_level === "below_expectations"
                        }
                        onChange={(e) => handleChange(e, index)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        name="comments"
                        // onChange={(e) => handleChange(e, index)}
                        value={student?.comments}
                        readOnly
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <h4 class="not__available">No students available</h4>
        )}
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenUseRubric(false)}
          >
            Close
          </button>
          {!rubricSubmitted ? (
            <button type="submit" className="continue__button">
              Submit
            </button>
          ) : (
            <button
              type="button"
              className="continue__button"
              style={{ cursor: "not-allowed", opacity: "1" }}
            >
              Already Submitted
            </button>
          )}
        </div>
      </form>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UseRubric);
