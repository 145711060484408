import * as api from "../../api";
import * as actionTypes from "../actions/types";
import { showError, stopLoading } from "./shared";

export const add_summative_assessment =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .addSummativeAssessment(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_SUMMATIVE_ASSESSMENT,
          payload: res?.data?.new_summative_assessment_data,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

export const teacher_get_summative_assessments =
  (userId) => async (dispatch) => {
    await api
      .teacherGetSummativeAssessments(userId)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_SUMMATIVE_ASSESSMENTS,
          payload: res?.data?.summative_assessments_data,
        });
        dispatch({
          type: actionTypes.SET_SCHOOL_TERMS,
          payload: res?.data?.school_terms_data,
        });
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

export const delete_summative_assessment =
  (userId, assessmentId) => async (dispatch) => {
    await api
      .deleteSummativeAssessment(userId, assessmentId)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_SUMMATIVE_ASSESSMENT,
          payload: assessmentId,
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };
