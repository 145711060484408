import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { add_summative_assessment } from "../../../../../redux/actions/exam";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";

const AddAssessment = (props) => {
  const { openAddAssessment, school_terms, loading, userId } = props;
  const { setOpenAddAssessment, startLoading, addSummativeAssessment } = props;

  const [assessmentDetails, setAssessmentDetails] = useState({
    name: "",
    school_term: "",
  });
  const { name, school_term } = assessmentDetails;

  const handleChange = (e) => {
    setAssessmentDetails({
      ...assessmentDetails,
      [e.target.name]: e.target.value,
    });
  };

  const resetForm = () => {
    setAssessmentDetails({
      name: "",
      school_term: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    addSummativeAssessment(userId, assessmentDetails, resetForm);
  };

  return (
    <MinDialog isOpen={openAddAssessment} maxWidth="500px" maxHeight="400px">
      <form
        class="dialog"
        onSubmit={handleSubmit}
        id={loading ? "formSubmitting" : ""}
      >
        <h3>Enter summative assessment details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Name</label>
          <input
            type="text"
            name="name"
            onChange={handleChange}
            value={name}
            required
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">School Term</label>
          <select
            name="school_term"
            onChange={handleChange}
            value={school_term}
            required
          >
            <option value="">--select--</option>
            {school_terms?.map((term) => (
              <option value={term?.id} key={term?.id}>
                {term?.name}
              </option>
            ))}
          </select>
        </div>
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenAddAssessment(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    school_terms: state?.course?.school_terms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    addSummativeAssessment: (userId, body, resetForm) =>
      dispatch(add_summative_assessment(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAssessment);
