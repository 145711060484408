import React from "react";
import { connect } from "react-redux";

import "./ReportHeader.scss";

const ReportHeader = (props) => {
  const {
    school_name,
    school_mobile_1,
    school_mobile_2,
    school_address,
    school_email_1,
  } = props;
  return (
    <thead className="report__header">
      <tr>
        <th colSpan="100%" className="school__name">
          {school_name}
        </th>
      </tr>
      <tr>
        <td colSpan="100%">
          <strong>Address:</strong> {school_address}
        </td>
      </tr>
      <tr>
        <td colSpan="100%">
          <strong>Mobile:</strong> {school_mobile_1}, {school_mobile_2}
        </td>
      </tr>
      <tr>
        <td colSpan="100%">
          <strong>Email:</strong> {school_email_1}
        </td>
      </tr>
    </thead>
  );
};

const mapStateToProps = (state) => {
  return {
    school_name: state?.auth?.user?.school_details?.school_name,
    school_mobile_1: state?.auth?.user?.school_details?.school_mobile_1,
    school_mobile_2: state?.auth?.user?.school_details?.school_mobile_2,
    school_address: state?.auth?.user?.school_details?.school_address,
    school_email_1: state?.auth?.user?.school_details?.school_email_1,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportHeader);
