import { useState } from "react";
import MaxDialog from "../../../../components/common/MaxDialog";
import RubricDetails from "./RubricDetails";

const TeacherRubrics = (props) => {
  const {
    rubrics,
    openRubrics,
    currentGradeStreamLearningAreaId,
    gradeStreamLearningAreaName,
    currentSubStrandName,
    currentStrandName,
  } = props;
  const { setOpenRubrics } = props;

  const [openRubricDetails, setOpenRubricDetails] = useState(false);
  const [currentRubric, setCurrentRubric] = useState({});

  const handleOpenRubricDetails = (rubric) => {
    setCurrentRubric(rubric);
    setOpenRubricDetails(true);
  };

  return (
    <>
      <MaxDialog isOpen={openRubrics} maxWidth="1200px" maxHeight="80vh">
        <div class="dialog">
          <h3>
            {gradeStreamLearningAreaName} - {currentSubStrandName} - Rubrics
          </h3>
          <div className="tc" style={{ lineHeight: "25px" }}>
            <p>
              <strong>Strand: </strong>
              {currentStrandName}
            </p>
            <p>
              <strong>SubStrand: </strong>
              {currentSubStrandName}
            </p>
            <p>
              <strong>Section: </strong>Rubrics
            </p>
          </div>
          {rubrics?.length > 0 ? (
            <table class="table__listing" rules="all" border="1">
              <thead>
                <tr class="table__listingHeader">
                  <th>Title</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {rubrics?.map((rubric) => (
                  <tr class="table__listingItem" key={rubric?.id}>
                    <td>{rubric?.title}</td>
                    <td
                      className="dodgerblue bd button__sp"
                      onClick={() => handleOpenRubricDetails(rubric)}
                    >
                      Details
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 class="not__available">No rubrics available</h4>
          )}
          <div class="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenRubrics(false)}
            >
              Close
            </button>
          </div>
        </div>
      </MaxDialog>

      {/* child components */}
      {openRubricDetails && (
        <RubricDetails
          openRubricDetails={openRubricDetails}
          setOpenRubricDetails={setOpenRubricDetails}
          currentRubric={currentRubric}
          currentGradeStreamLearningAreaId={currentGradeStreamLearningAreaId}
        />
      )}
    </>
  );
};

export default TeacherRubrics;
