import { useState, useEffect } from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../../components/common/MaxDialog";
import CircularProgress from "@mui/material/CircularProgress";
import { showError } from "../../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";
import AddStrand from "./AddStrand";
import AdminSubStrands from "./AdminSubStrands";
import EditStrand from "./EditStrand";

const AdminStrands = (props) => {
  const {
    strands,
    openStrands,
    currentGradeLevel,
    currentLearningArea,
    loading,
    userId,
  } = props;
  const { setOpenStrands, startLoading, setStrands, stopLoading } = props;

  const [openAddStrand, setOpenAddStrand] = useState(false);
  const [openSubStrands, setOpenSubStrands] = useState(false);
  const [currentStrand, setCurrentStrand] = useState({});
  const [openEditStrand, setOpenEditStrand] = useState(false);
  const [filterTerm, setFilterTerm] = useState("all");
  const [filteredStrands, setFilteredStrands] = useState([]);

  useEffect(() => {
    if (filterTerm === "all") {
      setFilteredStrands(strands);
    } else {
      setFilteredStrands(
        strands?.filter((item) => item?.static_school_term === filterTerm)
      );
    }
  }, [filterTerm, strands]);

  const handleOpenSubStrands = (strand) => {
    setCurrentStrand(strand);
    setOpenSubStrands(true);
  };

  const handleOpenEditStrand = (strand) => {
    setCurrentStrand(strand);
    setOpenEditStrand(true);
  };

  const handleDeleteStrand = async (strandId) => {
    if (
      window.confirm(
        "Once a strand is deleted, it cannot be recovered. Are you sure you want to continue?"
      )
    ) {
      startLoading();
      await API.delete(
        `/api/course/school-admin/delete-strand/${userId}/${strandId}/`
      )
        .then((res) => {
          setStrands(strands?.filter((item) => item?.id !== strandId));
          window.alert(res?.data?.detail);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  const handleAddNewStrand = async (body, resetForm) => {
    startLoading();
    API.post(`/api/course/school-admin/maintain-strands/${userId}/`, body)
      .then((res) => {
        setStrands([...strands, res?.data?.new_strand_data]);
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  const handleEditStrand = async (body) => {
    startLoading();
    API.patch(`/api/course/school-admin/maintain-strands/${userId}/`, body)
      .then((res) => {
        setStrands(
          strands?.map((item) =>
            item?.id === res?.data?.updated_strand_data?.id
              ? res?.data?.updated_strand_data
              : item
          )
        );
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MaxDialog isOpen={openStrands} maxWidth="1200px" maxHeight="85vh">
        <div class="dialog" id={loading ? "pageSubmitting" : ""}>
          <h3>
            {currentLearningArea?.name} Strands for {currentGradeLevel?.name}
          </h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <div className="dialog__rowSingleItem" style={{ maxWidth: "300px" }}>
            <label htmlFor="">Filter strands by term</label>
            <select
              name=""
              onChange={(e) => setFilterTerm(e.target.value)}
              value={filterTerm}
            >
              <option value="all">all</option>
              <option value="term_1">Term 1</option>
              <option value="term_2">Term 2</option>
              <option value="term_3">Term 3</option>
            </select>
          </div>
          {filteredStrands?.length > 0 ? (
            <table class="table__listing" rules="all" border="1">
              <thead>
                <tr class="table__listingHeader">
                  <th>#</th>
                  <th>Title</th>
                  <th class="tc">Status</th>
                  <th className="tc">SubStrands</th>
                  <th className="tc">Edit</th>
                  <th className="tc">Delete</th>
                </tr>
              </thead>
              <tbody>
                {filteredStrands?.map((strand) => (
                  <tr class="table__listingItem" key={strand?.id}>
                    <td>{strand?.num}</td>
                    <td>{strand?.title}</td>
                    <td class="tc">
                      {strand?.is_active ? (
                        <span className="green">Active</span>
                      ) : (
                        <span className="red">Inactive</span>
                      )}
                    </td>
                    <td
                      className="tc dodgerblue button__sp bd"
                      onClick={() => handleOpenSubStrands(strand)}
                    >
                      SubStrands
                    </td>
                    <td
                      className="dodgerblue button__sp bd tc"
                      onClick={() => handleOpenEditStrand(strand)}
                    >
                      Edit
                    </td>
                    <td className="tc">
                      <i
                        class="bx bxs-trash-alt red button__sp"
                        onClick={() => handleDeleteStrand(strand?.id)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 class="not__available">No strands available</h4>
          )}
          <div class="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenStrands(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="continue__button"
              onClick={() => setOpenAddStrand(true)}
            >
              Add Strand
            </button>
          </div>
        </div>
      </MaxDialog>
      {/* child components */}
      {openAddStrand && (
        <AddStrand
          openAddStrand={openAddStrand}
          setOpenAddStrand={setOpenAddStrand}
          currentGradeLevel={currentGradeLevel}
          currentLearningArea={currentLearningArea}
          handleAddNewStrand={handleAddNewStrand}
        />
      )}
      {openEditStrand && (
        <EditStrand
          openEditStrand={openEditStrand}
          setOpenEditStrand={setOpenEditStrand}
          currentStrand={currentStrand}
          setCurrentStrand={setCurrentStrand}
          handleEditStrand={handleEditStrand}
        />
      )}
      {openSubStrands && (
        <AdminSubStrands
          openSubStrands={openSubStrands}
          setOpenSubStrands={setOpenSubStrands}
          currentStrand={currentStrand}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminStrands);
