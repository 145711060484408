// import installed packages
import { useState } from "react";
import { connect } from "react-redux";

// import styles

// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
// import components/pages
import MinDialog from "../common/MinDialog";

// import redux API
import { login } from "../../redux/actions/auth";
import {
  CLOSE_LOGIN,
  OPEN_FORGOT_PASSWORD,
  TOGGLE_LOADING,
} from "../../redux/actions/types";

const Login = (props) => {
  const { loading, loginForm } = props; // extract state from props
  const { startLoading, loginUser, closeLogin, openPasswordReset } = props; // extract dispatch action from props
  const [loginData, setLoginData] = useState({
    email_or_phone_number: "",
    password: "",
  });

  // destructuring
  const { password, email_or_phone_number } = loginData;

  // reset form values
  const resetForm = () => {
    setLoginData({
      email_or_phone_number: "",
      password: "",
    });
  };

  // function to close login form
  const closeLoginForm = () => {
    closeLogin();
    resetForm();
  };

  const handleLogin = (e) => {
    e.preventDefault();
    startLoading();
    // call the signup action creator
    loginUser(loginData, resetForm);
  };

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  // open password reset form
  const openPasswordResetForm = () => {
    openPasswordReset();
    closeLogin();
  };

  return (
    <>
      <MinDialog isOpen={loginForm} maxHeight="85vh" maxWidth="600px">
        <form
          className="dialog"
          id={loading ? "formSubmitting" : ""}
          onSubmit={handleLogin}
        >
          <h3>Login here</h3>

          <div className="dialog__rowSingleItem">
            <label htmlFor="">Email or Phone Number</label>
            <input
              type="text"
              name="email_or_phone_number"
              onChange={handleChange}
              value={email_or_phone_number}
              required
            />
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "40%" }}
            />
          )}
          <div className="dialog__rowSingleItem">
            <label htmlFor="">Password</label>
            <input
              type="password"
              name="password"
              onChange={handleChange}
              value={password}
              required
            />
          </div>
          <div className="form__Buttons">
            <button
              type="button"
              onClick={closeLoginForm}
              className="close__button"
            >
              Close
            </button>
            <button type="submit" className="continue__button">
              Login
            </button>
          </div>

          <div className="extra__formButtons">
            <label
              htmlFor=""
              className="button"
              onClick={openPasswordResetForm}
            >
              Forgot Password
            </label>
          </div>
        </form>
      </MinDialog>
      {/* components */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.shared?.loading,
    loginForm: state.auth.loginForm,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    loginUser: (loginData, resetForm) => dispatch(login(loginData, resetForm)),
    closeLogin: () => dispatch({ type: CLOSE_LOGIN }),
    openPasswordReset: () => dispatch({ type: OPEN_FORGOT_PASSWORD }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
