import React, { useRef } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import MaxDialog from "../../../components/common/MaxDialog";
import ReportHeader from "../../../shared/ReportHeader/ReportHeader";

const PrintAllStudentReportCards = (props) => {
  const {
    openPrintAllStudentReportCards,
    summativeAssessmentDetails,
    studentResults,
  } = props;
  const { setOpenPrintAllStudentReportCards } = props;

  const {
    term_start_date,
    term_end_date,
    name,
    summative_assessment_total_points,
  } = summativeAssessmentDetails;

  // printing and saving report
  const printArea = useRef();

  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: "Student overall report card",
  });

  return (
    <MaxDialog
      isOpen={openPrintAllStudentReportCards}
      maxWidth="1400px"
      maxHeight="90vh"
    >
      <div className="dialog">
        <div ref={printArea}>
          {studentResults?.map((currentStudentResult) => (
            <table
              class="table__listing"
              rules="all"
              border="1"
              id="student_report_card"
              style={{ pageBreakAfter: "always" }}
            >
              <ReportHeader />
              <tr>
                <th colSpan="50%" style={{ padding: "5px" }}>
                  School Term:{" "}
                  <span class="purple">
                    {moment(term_start_date).format("LL")} to{" "}
                    {moment(term_end_date).format("LL")}
                  </span>{" "}
                </th>
                <th colSpan="50%">
                  Grade Stream:{" "}
                  <span class="purple">
                    {currentStudentResult?.grade_stream_name}
                  </span>{" "}
                </th>
              </tr>
              <tr>
                <th colSpan="33%" style={{ padding: "5px" }}>
                  Admission No:{" "}
                  <span class="purple">
                    {currentStudentResult?.admission_number}
                  </span>{" "}
                </th>
                <th colSpan="33%">
                  Student Name:{" "}
                  <span className="purple">
                    {currentStudentResult?.student_name}
                  </span>
                </th>
                <th colSpan="33%">
                  Assessment: <span class="purple">{name}</span>{" "}
                </th>
              </tr>
              <tr>
                <th colSpan="50%">
                  Position:{" "}
                  <span class="purple">
                    {currentStudentResult?.class_stream_position} out of{" "}
                    {currentStudentResult?.grade_stream_students_number}
                  </span>
                </th>
              </tr>
              <thead>
                <tr class="table__listingHeader">
                  <th colSpan="25%">Learning Area</th>
                  <th className="tc" colSpan="25%">
                    Marks Scored
                  </th>

                  <th className="tc" colSpan="25%">
                    Position <br />
                    <span style={{ fontSize: "0.7rem", color: "#f2e70f" }}>
                      out of{" "}
                      {currentStudentResult?.grade_stream_students_number}
                    </span>
                  </th>
                  <th colSpan="25%">Comments</th>
                </tr>
              </thead>
              <tbody>
                {currentStudentResult?.learning_areas_results?.map(
                  (result_item) => (
                    <tr class="table__listingItem" key={result_item?.id}>
                      <td colSpan="25%">{result_item?.learning_area_name}</td>
                      <td className="tc" colSpan="25%">
                        {result_item?.marks_scored} /{" "}
                        {result_item?.total_points}
                      </td>

                      <td className="tc" colSpan="25%">
                        {result_item?.class_stream_position}
                      </td>
                      <td colSpan="25%">{result_item?.comments}</td>
                    </tr>
                  )
                )}
                <tr>
                  <th colSpan="100%">---</th>
                </tr>
                <tr className="table__listingFooter">
                  <th colSpan="100%">
                    Class Teacher Comments:{" "}
                    <span style={{ fontSize: "0.95rem", color: "black" }}>
                      {currentStudentResult?.comments}
                    </span>
                  </th>
                </tr>
                <tr>
                  <th colSpan="100%">---</th>
                </tr>
                <tr className="table__listingFooter">
                  <th colSpan="100%" className="tc">
                    Total Marks:{" "}
                    <span style={{ fontSize: "0.95rem", color: "black" }}>
                      {currentStudentResult?.marks_scored} out of{" "}
                      {summative_assessment_total_points}
                    </span>
                  </th>
                </tr>
              </tbody>
              <tr>
                <th
                  colSpan="100%"
                  style={{ backgroundColor: "white", padding: "25px" }}
                >
                  {/* these blank quotes are important to ensure space between reports is visible */}{" "}
                </th>
              </tr>
            </table>
          ))}
        </div>
        <div className="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenPrintAllStudentReportCards(false)}
          >
            Close
          </button>
          <button
            type="button"
            className="continue__button"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

export default PrintAllStudentReportCards;
