import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { showError } from "../../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";

const AddLearningArea = (props) => {
  const {
    openAddGradeStreamLearningArea,
    currentGradeStream,
    filteredLearningAreas,
    loading,
    gradeStreamLearningAreas,
    userId,
  } = props;
  const {
    setOpenAddGradeStreamLearningArea,
    startLoading,
    stopLoading,
    setGradeStreamLearningAreas,
  } = props;

  const [learningAreaId, setLearningAreaId] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.post(
      `/api/course/teacher/maintain-grade-stream-learning-areas/${userId}/${currentGradeStream?.id}/`,
      { learningAreaId }
    )
      .then((res) => {
        setGradeStreamLearningAreas([
          ...gradeStreamLearningAreas,
          res?.data?.new_grade_stream_learning_area_data,
        ]);
        setLearningAreaId("");
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog
      isOpen={openAddGradeStreamLearningArea}
      maxWidth="600px"
      maxHeight="85vh"
    >
      <form
        className="dialog"
        onSubmit={handleSubmit}
        id={loading ? "formSubmitting" : ""}
      >
        <h3>New learning area for {currentGradeStream?.name}</h3>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Learning Area</label>
          <select
            name=""
            onChange={(e) => setLearningAreaId(e.target.value)}
            value={learningAreaId}
            required
          >
            <option value="">--select--</option>
            {filteredLearningAreas?.map((learning_area_item) => (
              <option
                value={learning_area_item?.id}
                key={learning_area_item?.id}
              >
                {learning_area_item?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenAddGradeStreamLearningArea(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLearningArea);
