import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { add_teacher } from "../../../../../redux/actions/auth";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";

const AddTeacher = (props) => {
  const { openAddTeacher, loading, userId } = props;
  const { setOpenAddTeacher, startLoading, addTeacher } = props;

  const [teacherDetails, setTeacherDetails] = useState({
    first_name: "",
    last_name: "",
    surname: "",
    gender: "",
    email: "",
    phone_number: "",
  });
  const { first_name, last_name, surname, gender, phone_number, email } =
    teacherDetails;

  const handleChange = (e) => {
    setTeacherDetails({ ...teacherDetails, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setTeacherDetails({
      first_name: "",
      last_name: "",
      surname: "",
      gender: "",
      email: "",
      phone_number: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    addTeacher(userId, teacherDetails, resetForm);
  };

  return (
    <MinDialog isOpen={openAddTeacher} maxWidth="900px" maxHeight="80vh">
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>New teacher details</h3>
        <div class="dialog__row">
          <span>
            <label for="">First Name</label>
            <input
              type="text"
              name="first_name"
              onChange={handleChange}
              value={first_name}
              required
            />
          </span>
          <span>
            <label for="">Last Name</label>
            <input
              type="text"
              name="last_name"
              onChange={handleChange}
              value={last_name}
              required
            />
          </span>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Surname</label>
            <input
              type="text"
              name="surname"
              onChange={handleChange}
              value={surname}
              required
            />
          </span>
          <span>
            <label for="">Email</label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              value={email}
              required
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__row">
          <span>
            <label for="">Phone Number</label>
            <input
              type="text"
              name="phone_number"
              onChange={handleChange}
              value={phone_number}
              required
            />
          </span>
          <span>
            <label for="">Gender</label>
            <select
              name="gender"
              onChange={handleChange}
              value={gender}
              required
            >
              <option value="">--select--</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </span>
        </div>

        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenAddTeacher(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    addTeacher: (userId, body, resetForm) =>
      dispatch(add_teacher(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTeacher);
