import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { teacher_get_grade_streams } from "../../../../redux/actions/course";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import AddStudent from "./components/AddStudent";
import GradeStreamStudentsOptions from "./components/GradeStreamStudentsOptions";
import UpgradeStudents from "./components/UpgradeStudents";
import API from "../../../../shared/axios";
import { showError } from "../../../../redux/actions/shared";
import EditStudent from "./components/EditStudent";

const Students = (props) => {
  const { userId, loading, grade_streams } = props;
  const { startLoading, teacherGetGradeStreams, stopLoading } = props;

  const [openAddStudent, setOpenAddStudent] = useState(false);
  const [openGradeStreamStudentsOptions, setOpenGradeStreamStudentOptions] =
    useState(false);
  const [openUpgradeStudents, setOpenUpgradeStudents] = useState(false);
  const [admissionNumber, setAdmissionNumber] = useState("");
  const [currentStudent, setCurrentStudent] = useState(null);
  const [openEditStudent, setOpenEditStudent] = useState(false);

  useEffect(() => {
    if (userId && grade_streams?.length === 0) {
      startLoading();
      teacherGetGradeStreams(userId);
    }
  }, [userId, grade_streams?.length, startLoading, teacherGetGradeStreams]);

  const searchStudent = async (e) => {
    e.preventDefault();
    startLoading();
    await API.post(`/api/user/teacher/search-student/${userId}/`, {
      admissionNumber,
    })
      .then((res) => {
        setCurrentStudent(res?.data?.student_data);
        setOpenEditStudent(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <div className="table__parent" id={loading && "formSubmitting"}>
        <div className="table__columns">
          <div className="column">
            <h3>Grade stream students</h3>
            <div className="buttons">
              <button
                type="button"
                onClick={() => setOpenGradeStreamStudentOptions(true)}
              >
                View
              </button>
              <button
                className="add__button"
                type="button"
                onClick={() => setOpenAddStudent(true)}
              >
                Add Student
              </button>
            </div>
          </div>
          <div className="column">
            <h3>Search &amp; edit student</h3>
            <form onSubmit={searchStudent}>
              <div style={{ textAlign: "center" }}>
                <input
                  type="text"
                  name=""
                  onChange={(e) => setAdmissionNumber(e.target.value)}
                  value={admissionNumber}
                  style={{
                    borderRadius: "20px",
                    border: "1px solid gray",
                    padding: "5px 10px",
                  }}
                  placeholder="Admission number"
                  required
                />
              </div>
              <div className="buttons">
                <button type="submit">Search</button>
              </div>
            </form>
          </div>
          <div className="column">
            <h3>Change grade stream students</h3>
            <div className="buttons">
              <button
                type="button"
                onClick={() => setOpenUpgradeStudents(true)}
              >
                Open
              </button>
            </div>
          </div>
        </div>

        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
      </div>
      {/* child components */}
      {openAddStudent && (
        <AddStudent
          openAddStudent={openAddStudent}
          setOpenAddStudent={setOpenAddStudent}
        />
      )}

      {openGradeStreamStudentsOptions && (
        <GradeStreamStudentsOptions
          openGradeStreamStudentsOptions={openGradeStreamStudentsOptions}
          setOpenGradeStreamStudentOptions={setOpenGradeStreamStudentOptions}
        />
      )}
      {openUpgradeStudents && (
        <UpgradeStudents
          openUpgradeStudents={openUpgradeStudents}
          setOpenUpgradeStudents={setOpenUpgradeStudents}
        />
      )}
      {openEditStudent && (
        <EditStudent
          openEditStudent={openEditStudent}
          setOpenEditStudent={setOpenEditStudent}
          currentStudent={currentStudent}
          setCurrentStudent={setCurrentStudent}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    students: state?.auth?.students,
    grade_streams: state?.course?.grade_streams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
    teacherGetGradeStreams: (userId) =>
      dispatch(teacher_get_grade_streams(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Students);
