import React, { useState } from "react";
import { connect } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";
import MinDialog from "../../../../../components/common/MinDialog";
import { add_grade_level } from "../../../../../redux/actions/course";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";

const AddGradeLevel = (props) => {
  const { openAddGradeLevel, loading, userId } = props;
  const { setOpenAddGradeLevel, startLoading, addGradeLevel } = props;

  const [gradeLevelDetails, setGradeLevelDetails] = useState({
    name: "",
    level: "",
  });
  const { name, level } = gradeLevelDetails;

  const handleChange = (e) => {
    setGradeLevelDetails({
      ...gradeLevelDetails,
      [e.target.name]: e.target.value,
    });
  };

  const resetForm = () => {
    setGradeLevelDetails({
      name: "",
      level: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    addGradeLevel(userId, gradeLevelDetails, resetForm);
  };

  return (
    <MinDialog isOpen={openAddGradeLevel} maxWidth="500px" maxHeight="400px">
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Enter grade level details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Grade Level Name/Number</label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={handleChange}
            required
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Grade level type</label>
          <select name="level" onChange={handleChange} value={level} required>
            <option value="">--select--</option>
            <option value="pre_primary">Pre Primary</option>
            <option value="lower_primary">Lower Primary</option>
            <option value="upper_primary">Upper Primary</option>
          </select>
        </div>
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenAddGradeLevel(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    addGradeLevel: (userId, body, resetForm) =>
      dispatch(add_grade_level(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGradeLevel);
