import React, { useRef } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import MaxDialog from "../../../../../components/common/MaxDialog";
import ReportHeader from "../../../../../shared/ReportHeader/ReportHeader";
import { formatWithCommas } from "../../../../../shared/sharedFunctions";

const SummativeLearningAreaReport = (props) => {
  const {
    openSummativeLearningAreaReport,
    summativeLearningAreaResultDetails,
    summativeLearningAreaStudentsResults,
    gradeStreamLearningAreaName,
    assessmentName,
  } = props;
  const { setOpenSummativeLearningAreaReport } = props;

  const { total_points, mean_score } = summativeLearningAreaResultDetails;

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: "Summative learning area report",
  });

  return (
    <MaxDialog
      isOpen={openSummativeLearningAreaReport}
      maxWidth="1400px"
      maxHeight="85vh"
    >
      <div class="dialog">
        <table
          className="table__listing"
          rules="all"
          border="1"
          id="summative_learning_area_report"
          ref={printArea}
        >
          <ReportHeader />
          <tr>
            <th colSpan="100%" style={{ padding: "5px", color: "purple" }}>
              {assessmentName} - {gradeStreamLearningAreaName}
            </th>
          </tr>
          <tr class="table__listingHeader">
            <th>Position</th>
            <th>Adm No:</th>
            <th>Name</th>
            <th className="tc">
              Marks Scored <br />
              <span style={{ fontSize: "0.8rem", color: "#f2e70f" }}>
                Out of {formatWithCommas(total_points)}{" "}
              </span>
            </th>
            <th className="tc">
              % Score <br />{" "}
              <span style={{ fontSize: "0.8rem", color: "#f2e70f" }}>
                Out of 100%
              </span>
            </th>
            <th className="tc">
              CBC Score <br />{" "}
              <span style={{ fontSize: "0.8rem", color: "#f2e70f" }}>
                Out of 4
              </span>
            </th>
          </tr>
          {summativeLearningAreaStudentsResults?.map((result, index) => (
            <tr class="table__listingItem" key={result?.id}>
              <td>{result?.class_stream_position}</td>
              <td>{result?.admission_number}</td>
              <td>{result?.name}</td>
              <td className="tc">{result?.marks_scored}</td>
              <td className="tc">
                {formatWithCommas(result?.percent_score)} %
              </td>
              <td className="tc">{formatWithCommas(result?.cbc_score)}</td>
            </tr>
          ))}
          <tr className="table__listingFooter">
            <th colSpan="100%" className="tc">
              Mean Score: <span className="purple"> {mean_score}</span>
            </th>
          </tr>
        </table>
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenSummativeLearningAreaReport(false)}
          >
            Close
          </button>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button export_to_excel_button"
            table="summative_learning_area_report"
            filename="summative_learning_area_report"
            sheet="summative_learning_area_report"
            buttonText="Export To Excel"
          />
          <button
            type="button"
            className="continue__button"
            onClick={handlePrint}
          >
            Print/Save
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

export default SummativeLearningAreaReport;
