import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get_teachers } from "../../../../redux/actions/auth";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import GroupsAllocated from "../Shared/GroupsAllocated";
import AddTeacher from "./components/AddTeacher";
import EditTeacher from "./components/EditTeacher";

const Teachers = (props) => {
  const { teachers, userId, loading } = props;
  const { getTeachers, startLoading } = props;

  const [currentTeacher, setCurrentTeacher] = useState({});

  const [openAddTeacher, setOpenAddTeacher] = useState(false);
  const [openGroupsAllocated, setOpenGroupsAllocated] = useState(false);
  const [openEditTeacher, setOpenEditTeacher] = useState(false);

  useEffect(() => {
    if (userId) {
      startLoading();
      getTeachers(userId);
    }
  }, [startLoading, userId, getTeachers]);

  const handleOpenEditTeacher = (teacher) => {
    setCurrentTeacher(teacher);
    setOpenEditTeacher(true);
  };

  const handleOpenGroups = (staff) => {
    setOpenGroupsAllocated(true);
    setCurrentTeacher(staff);
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div class="table__parentHeader">
          <button
            type="button"
            className="add__button"
            onClick={() => setOpenAddTeacher(true)}
          >
            Add Teacher
          </button>

          <h3>Maintain teachers here</h3>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}

        {teachers?.length > 0 ? (
          <table
            className="table__listing"
            style={{ maxWidth: "1000px", margin: "auto" }}
            rules="all"
            border="1"
          >
            <thead>
              <tr className="table__listingHeader">
                <th>Name</th>
                <th>Phone</th>
                <th>Login Status</th>
                <th>Account Status</th>
                <th>Edit</th>
                <th>Groups</th>
              </tr>
            </thead>
            <tbody>
              {teachers?.map((teacher) => (
                <tr class="table__listingItem" key={teacher?.id}>
                  <td>
                    {teacher?.first_name} {teacher?.last_name}
                  </td>
                  <td>{teacher?.phone_number}</td>
                  <td>
                    {teacher?.is_suspended && (
                      <span className="red">suspended</span>
                    )}
                    {!teacher?.is_suspended && (
                      <span className="green">active</span>
                    )}
                  </td>
                  <td>
                    {teacher?.is_deactivated && (
                      <span className="red">Inactive</span>
                    )}
                    {!teacher?.is_deactivated && (
                      <span className="green">active</span>
                    )}
                  </td>
                  <td
                    className="dodgerblue button__sp bd"
                    onClick={() => handleOpenEditTeacher(teacher)}
                  >
                    Edit
                  </td>

                  <td
                    onClick={() => handleOpenGroups(teacher)}
                    className="dodgerblue bd button__sp"
                  >
                    Groups
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No teachers available</h4>
        )}
      </div>

      {/* child components */}

      {openAddTeacher && (
        <AddTeacher
          openAddTeacher={openAddTeacher}
          setOpenAddTeacher={setOpenAddTeacher}
        />
      )}
      {openEditTeacher && (
        <EditTeacher
          openEditTeacher={openEditTeacher}
          setOpenEditTeacher={setOpenEditTeacher}
          currentTeacher={currentTeacher}
          setCurrentTeacher={setCurrentTeacher}
        />
      )}

      {openGroupsAllocated && (
        <GroupsAllocated
          openGroupsAllocated={openGroupsAllocated}
          setOpenGroupsAllocated={setOpenGroupsAllocated}
          currentStaff={currentTeacher}
          userType="Teacher"
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    teachers: state?.auth?.teachers,
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTeachers: (userId) => dispatch(get_teachers(userId)),
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payloadL: true }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Teachers);
