import moment from "moment";
import React, { useRef } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import MaxDialog from "../../../../../components/common/MaxDialog";

const LearnerReportSummary = (props) => {
  const { openLearnerReportSummary, currentLearner, currentLearningAreaName } =
    props;
  const { setOpenLearnerReportSummary, starting_date, ending_date } = props;

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: "Learner activities report",
  });

  return (
    <MaxDialog
      isOpen={openLearnerReportSummary}
      maxWidth="1200px"
      maxHeight="85vh"
    >
      <div class="dialog">
        <table
          class="table__listing"
          rules="all"
          border="1"
          ref={printArea}
          id="activities_report"
        >
          <tr>
            <th
              colSpan="100%"
              style={{
                textAlign: "center",
                color: "dodgerblue",
                padding: "8px",
              }}
            >
              {currentLearner?.name} - {currentLearningAreaName} Activities
              Report
            </th>
          </tr>
          <tr>
            <th
              colSpan="100%"
              style={{
                textAlign: "center",
                color: "purple",
                textDecoration: "underline",
                margin: "10px",
                padding: "8px",
              }}
            >
              From {moment(starting_date).format("LL")} to{" "}
              {moment(ending_date).format("LL")}
            </th>
          </tr>
          <tr>
            <th colSpan="100%" style={{ textAlign: "center", padding: "6px" }}>
              <strong>KEY: EE</strong> - Exceeds Expectations,{" "}
              <strong>ME</strong> - Meets Expectations, <strong>AE</strong> -
              Approaches Expectations, <strong>BE</strong>- Below Expectations
            </th>
          </tr>
          <tr class="table__listingItem">
            <th></th>
            <th></th>
            <th className="tc">EE</th>
            <th className="tc">ME</th>
            <th className="tc">AE</th>
            <th className="tc">BE</th>
            <th>Comments</th>
          </tr>
          {currentLearner?.strands?.map((strand) => (
            <tbody key={strand?.id}>
              <tr className="table__listingItem bg-lightgray">
                <th>{strand?.num}</th>
                <th>{strand?.title}</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th className="purple">CBC Score: {strand?.cbc_score}</th>
              </tr>
              {strand?.sub_strands?.map((sub_strand) => (
                <>
                  <tr className="table__listingItem">
                    <th>{sub_strand?.num}</th>
                    <th>{sub_strand?.title}</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="dodgerblue bd">
                      cbc score: {sub_strand?.cbc_score}
                    </td>
                  </tr>
                  {sub_strand?.student_rubrics?.map((rubric) => (
                    <tr key={rubric?.id} className="table__listingItem">
                      <td></td>
                      <td>{rubric?.title}</td>
                      <td className="tc">
                        {rubric?.performance_level ===
                          "exceeds_expectations" && (
                          <span>
                            <i class="bx bx-check"></i>
                          </span>
                        )}
                      </td>
                      <td className="tc">
                        {rubric?.performance_level === "meets_expectations" && (
                          <span>
                            <i class="bx bx-check"></i>
                          </span>
                        )}
                      </td>
                      <td className="tc">
                        {rubric?.performance_level ===
                          "approaches_expectations" && (
                          <span>
                            <i class="bx bx-check"></i>
                          </span>
                        )}
                      </td>
                      <td className="tc">
                        {rubric?.performance_level === "below_expectations" && (
                          <span>
                            <i class="bx bx-check"></i>
                          </span>
                        )}
                      </td>
                      <td>{rubric?.comments}</td>
                    </tr>
                  ))}
                </>
              ))}
            </tbody>
          ))}
        </table>
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenLearnerReportSummary(false)}
          >
            Close
          </button>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button export_to_excel_button"
            table="activities_report"
            filename="Activities_Report"
            sheet="activities_report"
            buttonText="Export To Excel"
          />
          <button
            type="button"
            className="continue__button"
            onClick={handlePrint}
          >
            Print/Save
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

export default LearnerReportSummary;
