import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  admin_get_grade_levels,
  admin_get_learning_areas,
  delete_learning_area,
} from "../../../../redux/actions/course";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import AddLearningArea from "./components/AddLearningArea";
import EditLearningArea from "./components/EditLearningArea";
import SelectStrandGrade from "./components/SelectStrandGrade";

const LearningAreas = (props) => {
  const { loading, userId, admin_learning_areas, admin_grade_levels } = props;
  const {
    startLoading,
    getLearningAreas,
    deleteLearningArea,
    adminGetGradeLevels,
  } = props;

  const [openSelectStrandGrade, setOpenSelectStrandGrade] = useState(false);
  const [currentLearningArea, setCurrentLearningArea] = useState({});
  const [openAddLearningArea, setOpenAddLearningArea] = useState(false);
  const [openEditLearningArea, setOpenEditLearningArea] = useState(false);
  const [filteredAdminGradeLevels, setFilteredAdminGradeLevels] = useState([]);

  useEffect(() => {
    if (userId && admin_learning_areas?.length === 0) {
      startLoading();
      getLearningAreas(userId);
    }
  }, [startLoading, getLearningAreas, userId, admin_learning_areas?.length]);

  useEffect(() => {
    if (userId && admin_grade_levels?.length === 0) {
      startLoading();
      adminGetGradeLevels(userId);
    }
  }, [startLoading, userId, admin_grade_levels?.length, adminGetGradeLevels]);

  const handleOpenSelectStrandGrade = (learning_area) => {
    setCurrentLearningArea(learning_area);
    if (learning_area?.level === "upper_primary") {
      setFilteredAdminGradeLevels(
        admin_grade_levels?.filter((item) => item?.level === "upper_primary")
      );
    } else if (learning_area?.level === "lower_primary") {
      setFilteredAdminGradeLevels(
        admin_grade_levels?.filter((item) => item?.level === "lower_primary")
      );
    } else if (learning_area?.level === "pre_primary") {
      setFilteredAdminGradeLevels(
        admin_grade_levels?.filter((item) => item?.level === "pre_primary")
      );
    } else if (learning_area?.level === "pre_lower_primary") {
      setFilteredAdminGradeLevels(
        admin_grade_levels?.filter(
          (item) =>
            item?.level === "pre_primary" || item?.level === "lower_primary"
        )
      );
    }

    setOpenSelectStrandGrade(true);
  };

  const handleDeleteLearningArea = (learningAreaId) => {
    if (
      window.confirm(
        "Once you delete this learning area, you cannot recover it, do you want to continue?"
      )
    ) {
      startLoading();
      deleteLearningArea(userId, learningAreaId);
    }
  };

  const handleOpenEditLearningArea = (learning_area) => {
    setCurrentLearningArea(learning_area);
    setOpenEditLearningArea(true);
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div class="table__parentHeader">
          <button
            type="button"
            className="add__button"
            onClick={() => setOpenAddLearningArea(true)}
          >
            Add Learning Area
          </button>
          <h3>Maintain Learning Areas</h3>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {admin_learning_areas?.length > 0 ? (
          <table class="table__listing" rules="all" border="1">
            <thead>
              <tr class="table__listingHeader">
                <th>Name</th>
                <th class="tc">Status</th>
                <th className="tc">Strands</th>
                <th className="tc">Edit</th>
                <th className="tc">Delete</th>
              </tr>
            </thead>
            <tbody>
              {admin_learning_areas?.map((learning_area) => (
                <tr class="table__listingItem" key={learning_area?.id}>
                  <td>{learning_area?.name}</td>
                  <td class="tc">
                    {learning_area?.is_active ? (
                      <span className="green">Active</span>
                    ) : (
                      <span className="red">Inactive</span>
                    )}
                  </td>
                  <td
                    className="dodgerblue tc button__sp bd"
                    onClick={() => handleOpenSelectStrandGrade(learning_area)}
                  >
                    Strands
                  </td>
                  <td
                    className="dodgerblue button__sp bd tc"
                    onClick={() => handleOpenEditLearningArea(learning_area)}
                  >
                    Edit
                  </td>
                  <td className="tc">
                    <i
                      class="bx bxs-trash-alt red button__sp"
                      onClick={() =>
                        handleDeleteLearningArea(learning_area?.id)
                      }
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No learning areas available</h4>
        )}
      </div>
      {/* child components */}
      {openAddLearningArea && (
        <AddLearningArea
          openAddLearningArea={openAddLearningArea}
          setOpenAddLearningArea={setOpenAddLearningArea}
        />
      )}
      {openEditLearningArea && (
        <EditLearningArea
          openEditLearningArea={openEditLearningArea}
          setOpenEditLearningArea={setOpenEditLearningArea}
          currentLearningArea={currentLearningArea}
          setCurrentLearningArea={setCurrentLearningArea}
        />
      )}
      {openSelectStrandGrade && (
        <SelectStrandGrade
          openSelectStrandGrade={openSelectStrandGrade}
          setOpenSelectStrandGrade={setOpenSelectStrandGrade}
          currentLearningArea={currentLearningArea}
          filteredAdminGradeLevels={filteredAdminGradeLevels}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    admin_learning_areas: state?.course?.admin_learning_areas,
    admin_grade_levels: state?.course?.admin_grade_levels,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    getLearningAreas: (userId) => dispatch(admin_get_learning_areas(userId)),
    adminGetGradeLevels: (userId) => dispatch(admin_get_grade_levels(userId)),
    deleteLearningArea: (userId, learningAreaId) =>
      dispatch(delete_learning_area(userId, learningAreaId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LearningAreas);
