import React, { useState } from "react";
import moment from "moment";
import MaxDialog from "../../../../../components/common/MaxDialog";
import { formatWithCommas } from "../../../../../shared/sharedFunctions";
import LearnerReportSummary from "./LearnerReportSummary";

const FormativeReportLearners = (props) => {
  const {
    learnersResults,
    currentLearningArea,
    starting_date,
    ending_date,
    openFormativeReportLearners,
  } = props;
  const { setOpenFormativeReportLearners } = props;

  const [openLearnerReportSummary, setOpenLearnerReportSummary] =
    useState(false);
  const [currentLearner, setCurrentLearner] = useState({});

  const handleOpenLearnerReportSummary = (learner) => {
    setCurrentLearner(learner);
    setOpenLearnerReportSummary(true);
  };
  return (
    <>
      <MaxDialog
        isOpen={openFormativeReportLearners}
        maxWidth="1200px"
        maxHeight="85vh"
      >
        <div className="dialog">
          <table class="table__listing" rules="all" border="1">
            <thead>
              <tr>
                <th colSpan="100%" style={{ padding: "5px", color: "purple" }}>
                  {currentLearningArea?.grade_stream_name} -{" "}
                  {currentLearningArea?.learning_area_name} Learner Reports
                </th>
              </tr>
              <tr>
                <th colSpan="100%" style={{ padding: "5px", color: "purple" }}>
                  Assessed from {moment(starting_date).format("LL")} to{" "}
                  {moment(ending_date).format("LL")}
                </th>
              </tr>

              <tr class="table__listingHeader">
                <th>Name</th>
                <th className="tc">CBC / %</th>
                <th className="tc">Report</th>
              </tr>
            </thead>
            <tbody>
              {learnersResults?.map((learner) => (
                <tr class="table__listingItem" key={learner?.id}>
                  <td>{learner?.name}</td>
                  <td className="tc">
                    {learner?.cbc_score === null ? (
                      <span>-</span>
                    ) : (
                      <span>
                        {" "}
                        {formatWithCommas(learner?.cbc_score)} /{" "}
                        {formatWithCommas(learner?.percent_score)}%
                      </span>
                    )}
                  </td>
                  <td
                    className="dodgerblue bd button__sp tc"
                    onClick={() => handleOpenLearnerReportSummary(learner)}
                  >
                    Open
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenFormativeReportLearners(false)}
            >
              Close
            </button>
          </div>
        </div>
      </MaxDialog>
      {openLearnerReportSummary && (
        <LearnerReportSummary
          openLearnerReportSummary={openLearnerReportSummary}
          setOpenLearnerReportSummary={setOpenLearnerReportSummary}
          currentLearner={currentLearner}
          currentLearningAreaName={`${currentLearningArea?.grade_stream_name} - ${currentLearningArea?.learning_area_name}`}
          starting_date={starting_date}
          ending_date={ending_date}
        />
      )}
    </>
  );
};

export default FormativeReportLearners;
