import API from "../shared/axios";

// signup
export const signupUser = (newUser) => API.post("/api/user/signup/", newUser);
// activate user account
export const activateAccount = (activation_token) =>
  API.post("/api/user/activate-user-account/", { activation_token });

// resend account activation email
export const resendActivation = (email) =>
  API.post("/api/user/resend-account-activation-link/", { email });

// sign in user
export const signIn = (loginData) => API.post("/api/user/login/", loginData);

// send password reset email
export const resetPassword = (email) =>
  API.post("/api/user/user-request-password-reset/", { email });

// set new password using reset link sent from above
export const setPassword = (newPasswords, password_token) =>
  API.post("/api/user/user-set-new-password/", {
    ...newPasswords,
    password_token,
  });
// user change password
export const changePassword = (passwords, userId) =>
  API.post(`/api/user/change-user-password/${userId}/`, passwords);

export const addSchoolTerm = (userId, body) =>
  API.post(`/api/course/school-admin/maintain-school-terms/${userId}/`, body);

export const addLearningArea = (userId, body) =>
  API.post(`/api/course/school-admin/maintain-learning-areas/${userId}/`, body);

export const addSummativeAssessment = (userId, body) =>
  API.post(`/api/exam/teacher/maintain-summative-assessments/${userId}/`, body);

export const addGradeLevel = (userId, body) =>
  API.post(`/api/course/school-admin/maintain-grade-levels/${userId}/`, body);

export const addGradeStream = (userId, body) =>
  API.post(
    `/api/course/teacher/teacher-maintain-grade-streams/${userId}/`,
    body
  );

export const addTeacher = (userId, body) =>
  API.post(`/api/user/school-admin/maintain-teachers/${userId}/`, body);

export const addStudent = (userId, body) =>
  API.post(`/api/user/teacher/teacher-maintain-students/${userId}/`, body);

export const addOtherStaff = (userId, body) =>
  API.post(`/api/user/school-admin/maintain-other-staff/${userId}/`, body);
