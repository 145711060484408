import * as actionTypes from "../actions/types";
import { updateObject } from "../utility";

const initialState = {
  summative_assessments: [], // refers to the assessments created by a teacher
};

const addSummativeAssessment = (state, payload) => {
  return updateObject(state, {
    summative_assessments: [...state.summative_assessments, payload],
  });
};

const updateSummativeAssessment = (state, payload) => {
  return updateObject(state, {
    summative_assessments: state?.summative_assessments?.map((assessment) =>
      assessment?.id === payload?.id ? payload : assessment
    ),
  });
};

const setSummativeAssessments = (state, payload) => {
  return updateObject(state, {
    summative_assessments: payload,
  });
};

const deleteSummativeAssessment = (state, payload) => {
  return updateObject(state, {
    summative_assessments: state?.summative_assessments?.filter(
      (item) => item?.id !== payload
    ),
  });
};

const examReducer = (state = initialState, action) => {
  switch (action?.type) {
    case actionTypes.ADD_SUMMATIVE_ASSESSMENT:
      return addSummativeAssessment(state, action?.payload);
    case actionTypes.UPDATE_SUMMATIVE_ASSESSMENT:
      return updateSummativeAssessment(state, action?.payload);
    case actionTypes.SET_SUMMATIVE_ASSESSMENTS:
      return setSummativeAssessments(state, action?.payload);
    case actionTypes.DELETE_SUMMATIVE_ASSESSMENT:
      return deleteSummativeAssessment(state, action?.payload);
    default:
      return state;
  }
};

export default examReducer;
