import React, { useState } from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import MaxDialog from "../../../../../components/common/MaxDialog";
import { showError } from "../../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";
import EditStudent from "./EditStudent";

const GradeStreamStudents = (props) => {
  const {
    openGradeStreamStudents,
    students,
    currentGradeStream,
    userId,
    loading,
  } = props;
  const { startLoading, setOpenGradeStreamStudents, setStudents, stopLoading } =
    props;

  const [currentStudent, setCurrentStudent] = useState(null);
  const [openEditStudent, setOpenEditStudent] = useState(false);

  const handleOpenEditStudent = (student) => {
    setCurrentStudent(student);
    setOpenEditStudent(true);
  };

  const handleDeleteStudent = async (studentUserId) => {
    if (
      window.confirm(
        "Deleting a student is not recoverable. Are you sure you want to continue?"
      )
    ) {
      startLoading();
      await API.delete(
        `/api/user/teacher/teacher-delete-student/${userId}/${studentUserId}/`
      )
        .then((res) => {
          setStudents(students?.filter((item) => item?.id !== studentUserId));
          window.alert(res?.data?.detail);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  return (
    <>
      <MaxDialog
        isOpen={openGradeStreamStudents}
        maxWidth="1200px"
        maxHeight="85vh"
      >
        <div className="dialog" id={loading && "formSubmitting"}>
          <h3>{currentGradeStream?.name} students</h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <table className="table__listing" rules="all" border="1">
            <thead>
              <tr className="table__listingHeader">
                <th>Adm No:</th>
                <th>Name</th>
                <th>Grade Stream</th>
                <th className="tc">Edit</th>
                <th className="tc">Delete</th>
              </tr>
            </thead>
            <tbody>
              {students?.map((student) => (
                <tr className="table__listingItem" key={student?.id}>
                  <td>{student?.admission_number}</td>
                  <td>
                    {student?.first_name} {student?.last_name}
                  </td>
                  <td>{student?.grade_stream_name}</td>
                  <td
                    className="tc dodgerblue bd button__sp"
                    onClick={() => handleOpenEditStudent(student)}
                  >
                    Edit
                  </td>
                  <td className="tc">
                    <i
                      className="bx bxs-trash-alt red button__sp"
                      onClick={() => handleDeleteStudent(student?.id)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenGradeStreamStudents(false)}
            >
              Close
            </button>
          </div>
        </div>
      </MaxDialog>
      {/* child components */}
      {openEditStudent && (
        <EditStudent
          openEditStudent={openEditStudent}
          setOpenEditStudent={setOpenEditStudent}
          currentStudent={currentStudent}
          setCurrentStudent={setCurrentStudent}
          students={students}
          setStudents={setStudents}
          currentGradeStream={currentGradeStream}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GradeStreamStudents);
