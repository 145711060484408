import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../../components/common/MaxDialog";

const EditRubric = (props) => {
  const { openEditRubric, currentRubric, loading } = props;
  const { setOpenEditRubric, setCurrentRubric, handleEditRubric } = props;

  const {
    title,
    exceeds_expectations,
    meets_expectations,
    approaches_expectations,
    below_expectations,
    is_active,
  } = currentRubric;

  const handleChange = (e) => {
    setCurrentRubric({ ...currentRubric, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setCurrentRubric({
      ...currentRubric,
      [e.target.name]: !e.target.defaultChecked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleEditRubric(currentRubric);
  };

  return (
    <MaxDialog isOpen={openEditRubric} maxWidth="1000px" maxHeight="85vh">
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Edit rubric details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Rubric Title</label>
          <input
            type="text"
            name="title"
            onChange={handleChange}
            value={title}
            required
          />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">
            <u>Performance Levels</u>
          </label>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Exceeds Expectations</label>
            <textarea
              name="exceeds_expectations"
              onChange={handleChange}
              value={exceeds_expectations}
              required
            />
          </span>
          <span>
            <label for="">Meets Expectations</label>
            <textarea
              name="meets_expectations"
              onChange={handleChange}
              value={meets_expectations}
              required
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__row">
          <span>
            <label for="">Approaches Expectations</label>
            <textarea
              name="approaches_expectations"
              onChange={handleChange}
              value={approaches_expectations}
              required
            />
          </span>
          <span>
            <label for="">Below Expectations</label>
            <textarea
              name="below_expectations"
              onChange={handleChange}
              value={below_expectations}
              required
            />
          </span>
        </div>
        <div class="dialog__checkBoxListingItem">
          <input
            type="checkbox"
            name="is_active"
            onChange={handleCheckboxChange}
            defaultChecked={is_active}
          />
          <label for="">Uncheck this box to deactivate this rubric</label>
        </div>
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenEditRubric(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Update
          </button>
        </div>
      </form>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditRubric);
