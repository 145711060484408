import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../../components/common/MaxDialog";
import { showError } from "../../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";
import AddRubric from "./AddRubric";
import EditRubric from "./EditRubric";

const AdminRubrics = (props) => {
  const { openRubrics, currentSubStrand, loading, userId } = props;
  const { setOpenRubrics, startLoading, stopLoading } = props;

  const [rubrics, setRubrics] = useState([]);

  const [openAddRubric, setOpenAddRubric] = useState(false);
  const [openEditRubric, setOpenEditRubric] = useState(false);
  const [currentRubric, setCurrentRubric] = useState({});

  useEffect(() => {
    if (userId && currentSubStrand?.id) {
      startLoading();
      const fetchRubrics = async () => {
        await API.get(
          `/api/course/school-admin/admin-get-rubrics/${userId}/${currentSubStrand?.id}/`
        )
          .then((res) => {
            setRubrics(res?.data?.rubrics_data);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchRubrics();
    }
  }, [currentSubStrand?.id, userId, startLoading, stopLoading]);

  const handleOpenEditRubric = (rubric) => {
    setCurrentRubric(rubric);
    setOpenEditRubric(true);
  };

  const handleDeleteRubric = async (rubricId) => {
    if (
      window.confirm(
        "Once a rubric is deleted it cannot be recovered. Are you sure you want to continue?"
      )
    ) {
      startLoading();
      API.delete(
        `/api/course/school-admin/delete-rubric/${userId}/${rubricId}/`
      )
        .then((res) => {
          setRubrics(rubrics?.filter((item) => item?.id !== rubricId));
          window.alert(res?.data?.detail);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  const handleAddNewRubric = async (body, resetForm) => {
    startLoading();
    API.post(`/api/course/school-admin/maintain-rubrics/${userId}/`, body)
      .then((res) => {
        setRubrics([...rubrics, res?.data?.new_rubric_data]);
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  const handleEditRubric = async (body) => {
    startLoading();
    API.patch(`/api/course/school-admin/maintain-rubrics/${userId}/`, body)
      .then((res) => {
        setRubrics(
          rubrics?.map((item) =>
            item?.id === res?.data?.updated_rubric_data?.id
              ? res?.data?.updated_rubric_data
              : item
          )
        );
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MaxDialog isOpen={openRubrics} maxWidth="1200px" maxHeight="85vh">
        <div class="dialog" id={loading ? "pageSubmitting" : ""}>
          <h3>{currentSubStrand?.title} - Rubrics</h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          {rubrics?.length > 0 ? (
            <table class="table__listing" rules="all" border="1">
              <thead>
                <tr class="table__listingHeader">
                  <th>Title</th>
                  <th class="tc">Status</th>
                  <th className="tc">Edit</th>
                  <th className="tc">Delete</th>
                </tr>
              </thead>
              <tbody>
                {rubrics?.map((rubric) => (
                  <tr class="table__listingItem" key={rubric?.id}>
                    <td>{rubric?.title}</td>
                    <td class="tc">
                      {rubric?.is_active ? (
                        <span className="green">Active</span>
                      ) : (
                        <span className="red">Inactive</span>
                      )}
                    </td>
                    <td
                      className="dodgerblue button__sp bd tc"
                      onClick={() => handleOpenEditRubric(rubric)}
                    >
                      Edit
                    </td>
                    <td className="tc">
                      <i
                        class="bx bxs-trash-alt red button__sp"
                        onClick={() => handleDeleteRubric(rubric?.id)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 class="not__available">No rubrics found</h4>
          )}
          <div class="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenRubrics(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="continue__button"
              onClick={() => setOpenAddRubric(true)}
            >
              Add Rubric
            </button>
          </div>
        </div>
      </MaxDialog>

      {/* child components */}
      {openAddRubric && (
        <AddRubric
          openAddRubric={openAddRubric}
          setOpenAddRubric={setOpenAddRubric}
          currentSubStrand={currentSubStrand}
          handleAddNewRubric={handleAddNewRubric}
        />
      )}
      {openEditRubric && (
        <EditRubric
          openEditRubric={openEditRubric}
          setOpenEditRubric={setOpenEditRubric}
          currentRubric={currentRubric}
          setCurrentRubric={setCurrentRubric}
          handleEditRubric={handleEditRubric}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminRubrics);
