// user types
export const OPEN_SIGNUP = "OPEN_SIGNUP";
export const CLOSE_SIGNUP = "CLOSE_SIGNUP";
export const OPEN_LOGIN = "OPEN_LOGIN";
export const CLOSE_LOGIN = "CLOSE_LOGIN";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const LOGOUT = "LOGOUT";
export const OPEN_FORGOT_PASSWORD = "OPEN_FORGOT_PASSWORD";
export const CLOSE_FORGOT_PASSWORD = "CLOSE_FORGOT_PASSWORD";
export const OPEN_RESEND_ACTIVATION = "OPEN_RESEND_ACTIVATION";
export const CLOSE_RESEND_ACTIVATION = "CLOSE_RESEND_ACTIVATION";
export const OPEN_PASSWORD_RESET_CONFIRM = "OPEN_PASSWORD_RESET_CONFIRM";
export const CLOSE_PASSWORD_RESET_CONFIRM = "CLOSE_PASSWORD_RESET_CONFIRM";
export const OPEN_CHANGE_PASSWORD = "OPEN_CHANGE_PASSWORD";
export const CLOSE_CHANGE_PASSWORD = "CLOSE_CHANGE_PASSWORD";
export const CHANGE_STAFF_GROUPS = "CHANGE_STAFF_GROUPS";

export const SET_TEACHERS = "SET_TEACHERS";
export const ADD_TEACHER = "ADD_TEACHER";
export const ADD_STUDENT = "ADD_STUDENT";
export const EDIT_STUDENT = "EDIT_STUDENT";
export const UPDATE_TEACHER = "UPDATE_TEACHER";
export const DELETE_STUDENT = "DELETE_STUDENT";

// shared types
export const TOGGLE_LOADING = "TOGGLE_LOADING";

// course types
export const ADD_SCHOOL_TERM = "ADD_SCHOOL_TERM";
export const EDIT_SCHOOL_TERM = "EDIT_SCHOOL_TERM";
export const DELETE_SCHOOL_TERM = "DELETE_SCHOOL_TERM";
export const ADD_GRADE_LEVEL = "ADD_GRADE_LEVEL";
export const EDIT_GRADE_LEVEL = "EDIT_GRADE_LEVEL";
export const DELETE_GRADE_LEVEL = "DELETE_GRADE_LEVEL";
export const ADD_STREAM_NAME = "ADD_STREAM_NAME";
export const EDIT_STREAM_NAME = "EDIT_STREAM_NAME";
export const DELETE_STREAM_NAME = "DELETE_STREAM_NAME";
export const ADD_GRADE_STREAM = "ADD_GRADE_STREAM";
export const EDIT_GRADE_STREAM = "EDIT_GRADE_STREAM";
export const DELETE_GRADE_STREAM = "DELETE_GRADE_STREAM";
export const SET_ADMIN_SCHOOL_TERMS = "SET_ADMIN_SCHOOL_TERMS";
export const SET_SCHOOL_TERMS = "SET_SCHOOL_TERMS";
export const SET_ADMIN_GRADE_LEVELS = "SET_ADMIN_GRADE_LEVELS";
export const SET_GRADE_STREAMS = "SET_GRADE_STREAMS";
export const SET_ADMIN_LEARNING_AREAS = "SET_ADMIN_LEARNING_AREAS";
export const SET_LEARNING_AREAS = "SET_LEARNING_AREAS";
export const SET_TEACHER_LEARNING_AREAS = "SET_TEACHER_LEARNING_AREAS";
export const ADD_LEARNING_AREA = "ADD_LEARNING_AREA";
export const EDIT_LEARNING_AREA = "EDIT_LEARNING_AREA";
export const DELETE_LEARNING_AREA = "DELETE_LEARNING_AREA";

// exam types
export const ADD_SUMMATIVE_ASSESSMENT = "ADD_SUMMATIVE_ASSESSMENT";
export const DELETE_SUMMATIVE_ASSESSMENT = "DELETE_SUMMATIVE_ASSESSMENT";
export const UPDATE_SUMMATIVE_ASSESSMENT = "UPDATE_SUMMATIVE_ASSESSMENT";
export const SET_SUMMATIVE_ASSESSMENTS = "SET_SUMMATIVE_ASSESSMENTS";
