import { useState } from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../components/common/MaxDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";
import TeacherRubrics from "./TeacherRubrics";

const TeacherSubStrands = (props) => {
  const {
    openSubStrands,
    sub_strands,
    currentGradeStreamLearningAreaId,
    gradeStreamLearningAreaName,
    currentStrandName,
    userId,
  } = props;
  const { setOpenSubStrands, startLoading, stopLoading } = props;

  const [openRubrics, setOpenRubrics] = useState(false);
  const [currentSubStrand, setCurrentSubStrand] = useState({});

  const handleOpenRubrics = async (sub_strand) => {
    startLoading();
    await API.get(
      `/api/course/teacher/teacher-get-rubrics/${userId}/${sub_strand?.id}/`
    )
      .then((res) => {
        setCurrentSubStrand({
          ...sub_strand,
          rubrics: res?.data?.rubrics_data,
        });
        setOpenRubrics(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MaxDialog isOpen={openSubStrands} maxWidth="1200px" maxHeight="80vh">
        <div class="dialog">
          <h3>{gradeStreamLearningAreaName}</h3>

          <div className="tc" style={{ lineHeight: "25px" }}>
            <p>
              <strong>Strand: </strong>
              {currentStrandName}
            </p>
            <p>
              <strong>Section: </strong>SubStrands
            </p>
          </div>

          {sub_strands?.length > 0 ? (
            <table class="table__listing" rules="all" border="1">
              <thead>
                <tr class="table__listingHeader">
                  <th>#</th>
                  <th>Title</th>
                  <th>Rubrics</th>
                </tr>
              </thead>
              <tbody>
                {sub_strands?.map((sub_strand) => (
                  <tr class="table__listingItem" key={sub_strand?.id}>
                    <td>{sub_strand?.num}</td>
                    <td>{sub_strand?.title}</td>
                    <td
                      className="dodgerblue bd button__sp"
                      onClick={() => handleOpenRubrics(sub_strand)}
                    >
                      Rubrics
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 class="not__available">No sub strands available</h4>
          )}
          <div class="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenSubStrands(false)}
            >
              Close
            </button>
          </div>
        </div>
      </MaxDialog>
      {/* child components */}
      {openRubrics && (
        <TeacherRubrics
          openRubrics={openRubrics}
          setOpenRubrics={setOpenRubrics}
          rubrics={currentSubStrand?.rubrics}
          currentGradeStreamLearningAreaId={currentGradeStreamLearningAreaId}
          gradeStreamLearningAreaName={gradeStreamLearningAreaName}
          currentSubStrandName={currentSubStrand?.title}
          currentStrandName={currentStrandName}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherSubStrands);
