import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import {
  delete_summative_assessment,
  teacher_get_summative_assessments,
} from "../../../../redux/actions/exam";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import AddAssessment from "./components/AddAssessment";
import UpdateAssessment from "./components/UpdateAssessment";

const Exams = (props) => {
  const { userId, loading, summative_assessments } = props;
  const {
    startLoading,
    teacherGetSummativeAssessments,
    deleteSummativeAssessment,
  } = props;

  const [openAddAssessment, setOpenAddAssessment] = useState(false);
  const [openUpdateAssessment, setOpenUpdateAssessment] = useState(false);
  const [currentAssessment, setCurrentAssessment] = useState({});

  useEffect(() => {
    if (userId && summative_assessments?.length === 0) {
      startLoading();
      teacherGetSummativeAssessments(userId);
    }
  }, [
    userId,
    summative_assessments?.length,
    teacherGetSummativeAssessments,
    startLoading,
  ]);

  const handleOpenUpdateAssessment = (assessment) => {
    setCurrentAssessment(assessment);
    setOpenUpdateAssessment(true);
  };

  const handleDeleteSummativeAssessment = (assessmentId) => {
    if (
      window.confirm(
        "Deleted assessment is not recoverable, are you sure you want to continue?"
      )
    ) {
      startLoading();
      deleteSummativeAssessment(userId, assessmentId);
    }
  };

  return (
    <>
      <div className="table__parent" id={loading && "formSubmitting"}>
        <div className="table__parentHeader">
          <button
            type="button"
            className="add__button"
            onClick={() => setOpenAddAssessment(true)}
          >
            Create Assessment
          </button>
          <h3>My summative assessments</h3>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {summative_assessments?.length > 0 ? (
          <table className="table__listing" rules="all" border="1">
            <thead>
              <tr className="table__listingHeader">
                <th>Name</th>
                <th className="tc">Status</th>
                <th className="tc">Delete</th>
              </tr>
            </thead>
            <tbody>
              {summative_assessments?.map((assessment) => (
                <tr className="table__listingItem" key={assessment?.id}>
                  <td>{assessment?.name}</td>
                  <td className="tc">
                    {assessment?.status === "ongoing" && (
                      <span
                        onClick={() => handleOpenUpdateAssessment(assessment)}
                        className="dodgerblue button__sp bd"
                      >
                        Update
                      </span>
                    )}
                    {assessment?.status === "completed" && (
                      <span className="green bd">Completed</span>
                    )}
                  </td>
                  <td className="tc">
                    <i
                      className="bx bxs-trash-alt red button__sp"
                      onClick={() =>
                        handleDeleteSummativeAssessment(assessment?.id)
                      }
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 className="not__available">
            No summative assessments created yet
          </h4>
        )}
      </div>
      {openAddAssessment && (
        <AddAssessment
          openAddAssessment={openAddAssessment}
          setOpenAddAssessment={setOpenAddAssessment}
        />
      )}
      {openUpdateAssessment && (
        <UpdateAssessment
          openUpdateAssessment={openUpdateAssessment}
          setOpenUpdateAssessment={setOpenUpdateAssessment}
          currentAssessment={currentAssessment}
          setCurrentAssessment={setCurrentAssessment}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    summative_assessments: state?.exam?.summative_assessments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    teacherGetSummativeAssessments: (userId) =>
      dispatch(teacher_get_summative_assessments(userId)),
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    deleteSummativeAssessment: (userId, assessmentId) =>
      dispatch(delete_summative_assessment(userId, assessmentId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Exams);
