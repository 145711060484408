import { connect } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import MinDialog from "../../../../../components/common/MinDialog";

const AddStrand = (props) => {
  const { loading, currentGradeLevel, currentLearningArea, openAddStrand } =
    props;
  const { setOpenAddStrand, handleAddNewStrand, static_school_terms } = props;

  const [strandDetails, setStrandDetails] = useState({
    num: "",
    title: "",
    static_school_term: "",
  });

  const { num, title, static_school_term } = strandDetails;

  const handleChange = (e) => {
    setStrandDetails({ ...strandDetails, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setStrandDetails({
      num: "",
      title: "",
      static_school_term: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // we call the below function from parent component
    handleAddNewStrand(
      {
        ...strandDetails,
        learning_area: currentLearningArea?.id,
        grade_level: currentGradeLevel?.id,
      },
      resetForm
    );
  };
  return (
    <MinDialog isOpen={openAddStrand} maxWidth="800px" maxHeight="500px">
      <form
        className="dialog"
        onSubmit={handleSubmit}
        id={loading ? "formSubmitting" : ""}
      >
        <h3>
          New strand for {currentGradeLevel?.name} {currentLearningArea?.name}
        </h3>
        <div class="dialog__rowSingleItem">
          <label for="">Select strand term</label>
          <select
            name="static_school_term"
            onChange={handleChange}
            value={static_school_term}
            required
          >
            <option value="">--select--</option>
            {static_school_terms?.map((item) => (
              <option value={item?.id} key={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Strand Number</label>
          <input
            type="number"
            name="num"
            onChange={handleChange}
            value={num}
            min="0"
            required
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Strand Title</label>
          <input
            type="text"
            name="title"
            onChange={handleChange}
            value={title}
            required
          />
        </div>
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenAddStrand(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    static_school_terms: state?.course?.static_school_terms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStrand);
