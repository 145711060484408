import { CircularProgress } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { edit_grade_level } from "../../../../../redux/actions/course";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";

const EditGradeLevel = (props) => {
  const { openEditGradeLevel, currentGradeLevel, userId, loading } = props;
  const {
    setOpenEditGradeLevel,
    setCurrentGradeLevel,
    startLoading,
    editGradeLevel,
  } = props;

  const { name, is_active, level } = currentGradeLevel;

  const handleChange = (e) => {
    setCurrentGradeLevel({
      ...currentGradeLevel,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e) => {
    setCurrentGradeLevel({
      ...currentGradeLevel,
      [e.target.name]: !e.target.defaultChecked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    editGradeLevel(userId, currentGradeLevel);
  };

  return (
    <MinDialog isOpen={openEditGradeLevel} maxWidth="500px" maxHeight="400px">
      <form
        class="dialog"
        onSubmit={handleSubmit}
        id={loading ? "formSubmitting" : ""}
      >
        <div class="dialog__rowSingleItem">
          <label for="">Grade name/number</label>
          <input
            type="text"
            name="name"
            onChange={handleChange}
            value={name}
            required
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Grade level type</label>
          <select name="level" onChange={handleChange} value={level} required>
            <option value="">--select--</option>
            <option value="pre_primary">Pre Primary</option>
            <option value="lower_primary">Lower Primary</option>
            <option value="upper_primary">Upper Primary</option>
          </select>
        </div>
        <div class="dialog__checkBoxListingItem">
          <input
            type="checkbox"
            name="is_active"
            onChange={handleCheckboxChange}
            defaultChecked={is_active}
          />
          <label for="">Uncheck this box to deactivate this grade level</label>
        </div>
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenEditGradeLevel(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Update
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    editGradeLevel: (userId, body) => dispatch(edit_grade_level(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGradeLevel);
