import React, { useState } from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import MaxDialog from "../../../../../components/common/MaxDialog";
import { showError } from "../../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";

const UpgradeStudents = (props) => {
  const { openUpgradeStudents, loading, userId, grade_streams } = props;
  const { setOpenUpgradeStudents, startLoading, stopLoading } = props;

  const [fromGradeStreamId, setFromGradeStreamId] = useState(""); // grade stream to upgrade from
  const [toGradeStreamId, setToGradeStreamId] = useState(""); // grade stream to upgrade to

  const [gradeStreamStudents, setGradeStreamStudents] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);

  const getGradeStreamStudents = async (e) => {
    e.preventDefault();
    startLoading();
    await API.get(
      `/api/course/teacher/teacher-get-grade-stream-students/${userId}/${fromGradeStreamId}/`
    )
      .then((res) => {
        setGradeStreamStudents(res?.data?.grade_stream_students);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  const handleChange = (e, itemIndex) => {
    const arrayToMutate = [...gradeStreamStudents];
    arrayToMutate[itemIndex] = {
      ...arrayToMutate[itemIndex],
      isChecked: e.target.checked,
    };
    setGradeStreamStudents(arrayToMutate);
  };

  const handleSelectAll = (e) => {
    setSelectedAll(e.target.checked);
    if (e.target.checked) {
      setGradeStreamStudents(
        gradeStreamStudents?.map((item) => ({ ...item, isChecked: true }))
      );
    } else {
      setGradeStreamStudents(
        gradeStreamStudents?.map((item) => ({ ...item, isChecked: false }))
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (toGradeStreamId === "" || fromGradeStreamId === "") {
      return window.alert(
        "You must select grade stream to upgrade from and upgrade to"
      );
    }
    if (fromGradeStreamId === toGradeStreamId) {
      return window.alert(
        "Grade stream to upgrade from cannot be the same as grade stream to upgrade to"
      );
    }
    startLoading();
    const student_Ids = gradeStreamStudents
      ?.filter((item) => item?.isChecked)
      .map((item) => item?.id);
    await API.patch(
      `/api/course/teacher/teacher-upgrade-grade-stream-students/${userId}/${fromGradeStreamId}/${toGradeStreamId}/`,
      // we get a list of id's for the selected students
      student_Ids
    )
      .then((res) => {
        setGradeStreamStudents(res?.data?.grade_stream_students);
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MaxDialog
        isOpen={openUpgradeStudents}
        maxWidth="1200px"
        maxHeight="85vh"
      >
        <div className="dialog" id={loading ? "formSubmitting" : ""}>
          <h3>Upgrade students from one grade stream to another</h3>
          <form>
            <div className="dialog__row" style={{ maxWidth: "700px" }}>
              <span>
                <label htmlFor="">Upgrade from</label>
                <select
                  name=""
                  onChange={(e) => setFromGradeStreamId(e.target.value)}
                  value={fromGradeStreamId}
                  required
                >
                  <option value="">--select--</option>
                  {grade_streams?.map((item) => (
                    <option value={item?.id} key={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </span>
              <span>
                <label htmlFor="">Upgrade to</label>
                <select
                  name=""
                  onChange={(e) => setToGradeStreamId(e.target.value)}
                  value={toGradeStreamId}
                  required
                >
                  <option value="">--select--</option>
                  {grade_streams?.map((item) => (
                    <option value={item?.id} key={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </span>
              <span>
                <label htmlFor=""></label>
                <button
                  type="submit"
                  className="add__button"
                  onClick={getGradeStreamStudents}
                >
                  Get Students
                </button>
              </span>
            </div>
          </form>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          {gradeStreamStudents?.length > 0 && (
            <>
              <table className="table__listing" rules="all" border="1">
                <thead>
                  <tr className="table__listingHeader">
                    <th>Adm No:</th>
                    <th>Name</th>
                    <th className="tc">
                      Select{" "}
                      <input
                        type="checkbox"
                        onChange={handleSelectAll}
                        checked={selectedAll}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {gradeStreamStudents?.map((student, index) => (
                    <tr className="table__listingItem" key={student?.id}>
                      <td>{student?.admission_number}</td>
                      <td>{student?.name}</td>
                      <td className="tc">
                        <input
                          type="checkbox"
                          onChange={(e) => handleChange(e, index)}
                          checked={student?.isChecked}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          <div className="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenUpgradeStudents(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="continue__button"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </MaxDialog>
      {/* child components */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    grade_streams: state?.course?.grade_streams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeStudents);
