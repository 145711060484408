// import installed packages
import { useEffect, useState } from "react";
import { connect } from "react-redux";

// import styles
import "./Profile.scss";
// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
// import components/pages
import ChangePassword from "../../components/users/ChangePassword";
// import redux API
import { update_user } from "../../redux/actions/auth";
import {
  OPEN_CHANGE_PASSWORD,
  TOGGLE_LOADING,
} from "../../redux/actions/types";

const Profile = (props) => {
  const {
    first_name,
    last_name,
    surname,
    email,
    phone_number,
    userId,
    loading,
    users_groups,
  } = props; // extract state from props
  const { startLoading, updateUser, openChangePassword } = props; // extract dispatch actions from props
  // internal state
  const [updatedUser, setUpdatedUser] = useState({});

  useEffect(() => {
    setUpdatedUser({
      first_name,
      last_name,
      surname,
      email,
      phone_number,
    });
  }, [first_name, last_name, email, surname, phone_number]);

  // function to update user details
  const updateUserDetails = (e) => {
    e.preventDefault();
    startLoading();
    updateUser({ ...updatedUser, userId });
  };

  // handle change function
  const handleChange = (e) => {
    setUpdatedUser({ ...updatedUser, [e.target.name]: e.target.value });
  };

  return (
    <>
      <form
        className="profile"
        id={loading ? "formSubmitting" : ""}
        onSubmit={updateUserDetails}
      >
        <h2>Profile Details</h2>
        <div className="profile__row">
          <span>
            <label htmlFor="">First Name</label>
            <input
              type="text"
              name="first_name"
              value={updatedUser?.first_name}
              onChange={handleChange}
              required
            />
          </span>
          <span>
            <label htmlFor="">Last Name</label>
            <input
              type="text"
              name="last_name"
              value={updatedUser?.last_name}
              onChange={handleChange}
              required
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "30%" }}
          />
        )}
        <div className="profile__row">
          <span>
            <label htmlFor="">Surname</label>
            <input
              type="name"
              name="surname"
              value={updatedUser?.surname}
              onChange={handleChange}
              required
            />
          </span>
          {["School Admin", "System Admin"]?.some((allowed_group) =>
            users_groups?.includes(allowed_group)
          ) && (
            <span>
              <label htmlFor="">Email</label>
              <input
                type="email"
                name="email"
                value={updatedUser?.email}
                onChange={handleChange}
                required
              />
            </span>
          )}
        </div>
        <div className="profile__Buttons">
          <button
            type="button"
            className="change__password"
            onClick={openChangePassword}
          >
            Change Password
          </button>
          <button type="submit" className="update">
            Update Profile
          </button>
        </div>
      </form>
      {/* linked components */}
      <ChangePassword />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    first_name: state.auth.user?.first_name,
    last_name: state.auth.user?.last_name,
    surname: state.auth.user?.surname,
    phone_number: state.auth.user?.phone_number,
    email: state.auth.user?.email,
    userId: state.auth.user?.id,
    loading: state.shared?.loading,
    users_groups: state?.auth?.user?.users_groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    updateUser: (updatedUser) => dispatch(update_user(updatedUser)),
    openChangePassword: () => dispatch({ type: OPEN_CHANGE_PASSWORD }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
