import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";

const AddSubStrand = (props) => {
  const { openAddSubStrand, currentStrand, loading } = props;
  const { setOpenAddSubStrand, handleAddNewSubStrand } = props;

  const [subStrandDetails, setSubStrandDetails] = useState({
    num: "",
    title: "",
  });
  const { num, title } = subStrandDetails;

  const handleChange = (e) => {
    setSubStrandDetails({
      ...subStrandDetails,
      [e.target.name]: e.target.value,
    });
  };

  const resetForm = () => {
    setSubStrandDetails({
      num: "",
      title: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddNewSubStrand(
      { ...subStrandDetails, strand: currentStrand?.id },
      resetForm
    );
  };

  return (
    <MinDialog isOpen={openAddSubStrand} maxWidth="800px" maxHeight="500px">
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>New substrand for - {currentStrand?.title}</h3>
        <div class="dialog__rowSingleItem">
          <label for="">SubStrand Number</label>
          <input
            type="number"
            name="num"
            onChange={handleChange}
            value={num}
            required
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">SubStrand Title</label>
          <input
            type="text"
            name="title"
            onChange={handleChange}
            value={title}
            required
          />
        </div>
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenAddSubStrand(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSubStrand);
