import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { edit_school_term } from "../../../../../redux/actions/course";
import { TOGGLE_LOADING } from "../../../../../redux/actions/types";

const EditSchoolTerm = (props) => {
  const { openEditSchoolTerm, currentSchoolTerm, loading, userId } = props;
  const {
    setOpenEditSchoolTerm,
    setCurrentSchoolTerm,
    editSchoolTerm,
    startLoading,
  } = props;

  const { name, starting_date, ending_date, is_active } = currentSchoolTerm;

  const handleChange = (e) => {
    setCurrentSchoolTerm({
      ...currentSchoolTerm,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e) => {
    setCurrentSchoolTerm({
      ...currentSchoolTerm,
      [e.target.name]: !e.target.defaultChecked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    editSchoolTerm(userId, currentSchoolTerm);
  };

  return (
    <MinDialog isOpen={openEditSchoolTerm} maxWidth="500px">
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Edit school term details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Name</label>
          <input
            type="text"
            name="name"
            onChange={handleChange}
            value={name}
            required
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Starting Date</label>
          <input
            type="date"
            name="starting_date"
            onChange={handleChange}
            value={starting_date}
            required
          />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Ending Date</label>
          <input
            type="date"
            name="ending_date"
            onChange={handleChange}
            value={ending_date}
            required
          />
        </div>
        <div class="dialog__checkBoxListingItem">
          <input
            type="checkbox"
            name="is_active"
            onChange={handleCheckboxChange}
            defaultChecked={is_active}
          />
          <label for="">Uncheck this box to deactivate school term</label>
        </div>
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenEditSchoolTerm(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Update
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    editSchoolTerm: (userId, body) => dispatch(edit_school_term(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSchoolTerm);
