import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { showError } from "../../../../../redux/actions/shared";
import {
  TOGGLE_LOADING,
  UPDATE_SUMMATIVE_ASSESSMENT,
} from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";

const UpdateAssessment = (props) => {
  const {
    openUpdateAssessment,
    currentAssessment,
    school_terms,
    userId,
    loading,
  } = props;
  const {
    setOpenUpdateAssessment,
    setCurrentAssessment,
    startLoading,
    stopLoading,
  } = props;

  const { school_term, name } = currentAssessment;
  const dispatch = useDispatch();

  const [closeAssessmentUpdating, setCloseAssessmentUpdating] = useState(false);

  const handleChange = (e) => {
    setCurrentAssessment({
      ...currentAssessment,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (closeAssessmentUpdating) {
      // if checkbox is ticked, we give the user an option to cancel before proceeding
      if (
        window.confirm(
          "Make sure all the results have been input before closing this update, this process cannot be reversed. Do you want to continue?"
        )
      ) {
        startLoading();
        updateAssessmentDetails();
      }
    } else {
      startLoading();
      updateAssessmentDetails();
    }
  };

  const updateAssessmentDetails = async () => {
    await API.patch(
      `/api/exam/teacher/maintain-summative-assessments/${userId}/`,
      { ...currentAssessment, closeAssessmentUpdating }
    )
      .then((res) => {
        dispatch({
          type: UPDATE_SUMMATIVE_ASSESSMENT,
          payload: res?.data?.updated_summative_assessment_data,
        });

        window.alert(res?.data?.detail);
        // if true close the form to ensure function is not called again
        if (closeAssessmentUpdating) {
          setOpenUpdateAssessment(false);
        }
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openUpdateAssessment} maxWidth="600px" maxHeight="400px">
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>{currentAssessment?.name}</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Name</label>
          <input
            type="text"
            onChange={handleChange}
            value={name}
            name="name"
            required
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">School Term</label>
          <select
            name="school_term"
            onChange={handleChange}
            value={school_term}
            required
          >
            <option value="">--select--</option>
            {school_terms?.map((term) => (
              <option value={term?.id} key={term?.id}>
                {term?.name}
              </option>
            ))}
          </select>
        </div>
        <br />
        <div class="dialog__checkBoxListingItem">
          <input
            type="checkbox"
            onChange={(e) =>
              setCloseAssessmentUpdating(!e.target.defaultChecked)
            }
            defaultChecked={closeAssessmentUpdating}
          />
          <label for="">
            Check this box to close updating of this summative assessment
          </label>
        </div>
        <br />
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenUpdateAssessment(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Update
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    admin_school_terms: state?.course?.admin_school_terms,
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    school_terms: state?.course?.school_terms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAssessment);
