import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import MaxDialog from "../../../../components/common/MaxDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";

const SummativeAssessmentStudents = (props) => {
  const {
    openSummativeAssessmentStudents,
    gradeStreamLearningAreaStudents,
    currentGradeStreamLearningAreaId,
    currentSummativeAssessmentId,
    userId,
    loading,
    summativeAssessmentLearningAreaData,
    summativeAssessmentHeading,
  } = props;
  const {
    setOpenSummativeAssessmentStudents,
    setGradeStreamLearningAreaStudents,
    startLoading,
    stopLoading,
  } = props;

  const [gradingDetails, setGradingDetails] = useState({
    assessment_mode: "",
    total_points: "",
  });

  const { assessment_mode, total_points, status } = gradingDetails;

  const [expectationDetails, setExpectationDetails] = useState({
    start_be: "",
    end_be: "",
    start_ae: "",
    end_ae: "",
    start_me: "",
    end_me: "",
    start_ee: "",
    end_ee: "",
  });

  const {
    start_be,
    end_be,
    start_ae,
    end_ae,
    start_me,
    end_me,
    start_ee,
    end_ee,
  } = expectationDetails;

  // in case the result has already been submitted before, we get the existing data and set it
  useEffect(() => {
    if (summativeAssessmentLearningAreaData !== null) {
      setGradingDetails({
        assessment_mode: summativeAssessmentLearningAreaData?.assessment_mode,
        total_points: summativeAssessmentLearningAreaData?.total_points,
        status: summativeAssessmentLearningAreaData?.status,
      });
      setExpectationDetails({
        start_be: summativeAssessmentLearningAreaData?.start_be,
        end_be: summativeAssessmentLearningAreaData?.end_be,
        start_ae: summativeAssessmentLearningAreaData?.start_ae,
        end_ae: summativeAssessmentLearningAreaData?.end_ae,
        start_me: summativeAssessmentLearningAreaData?.start_me,
        end_me: summativeAssessmentLearningAreaData?.end_me,
        start_ee: summativeAssessmentLearningAreaData?.start_ee,
        end_ee: summativeAssessmentLearningAreaData?.end_ee,
      });
    }
  }, [summativeAssessmentLearningAreaData]);

  const handleChange = (e) => {
    setGradingDetails({ ...gradingDetails, [e.target.name]: e.target.value });
    // option 1 is where total points are already filled in and the current selection is for mode of assessment which happens to be automated
    if (
      e.target.value === "automated" &&
      total_points !== "" &&
      total_points !== 0
    ) {
      const gap = Math.round(total_points / 4);
      setExpectationDetails({
        start_be: 0,
        end_be: gap,
        start_ae: gap + 1,
        end_ae: gap * 2,
        start_me: gap * 2 + 1,
        end_me: gap * 3,
        start_ee: gap * 3 + 1,
        end_ee: total_points,
      });
    }
    // option 2 is where assessment mode is already filled in and it's automated and current selection is for total_points
    else if (
      e.target.name === "total_points" &&
      assessment_mode === "automated" &&
      e.target.value !== "" &&
      e.target.value !== 0
    ) {
      const gap = Math.round(e.target.value / 4);
      setExpectationDetails({
        start_be: 0,
        end_be: gap,
        start_ae: gap + 1,
        end_ae: gap * 2,
        start_me: gap * 2 + 1,
        end_me: gap * 3,
        start_ee: gap * 3 + 1,
        end_ee: e.target.value,
      });
    }
  };

  const handleStudentsMarksChange = (e, studentIndex) => {
    if (Number(e.target.value) > Number(total_points)) {
      return window.alert("Marks input exceeds total points");
    }
    const arrayToMutate = [...gradeStreamLearningAreaStudents];

    arrayToMutate[studentIndex] = {
      ...arrayToMutate[studentIndex],
      [e.target.name]: e.target.value,
    };
    setGradeStreamLearningAreaStudents(arrayToMutate);
  };

  const handleExpectationChanges = (e) => {
    setExpectationDetails({
      ...expectationDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    API.post(
      `/api/exam/teacher/teacher-maintain-summative-assessment/${userId}/`,
      {
        ...gradingDetails,
        expectationDetails,
        gradeStreamLearningAreaStudents,
        submissionType: "initial",
        assessmentId: currentSummativeAssessmentId,
        gradeStreamLearningAreaId: currentGradeStreamLearningAreaId,
      }
    )
      .then((res) => {
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MaxDialog
      isOpen={openSummativeAssessmentStudents}
      maxWidth="1400px"
      maxHeight="85vh"
    >
      <form
        class="dialog"
        onSubmit={handleSubmit}
        id={loading ? "formSubmitting" : ""}
      >
        <h3>{summativeAssessmentHeading}</h3>
        <p style={{ textAlign: "center" }}>
          <strong>KEY: EE</strong> - Exceeds Expectations, <strong>ME</strong> -
          Meets Expectations, <strong>AE</strong> - Approaches Expectations,{" "}
          <strong>BE</strong>- Below Expectations
        </p>
        <div class="dialog__row">
          <span>
            <label for="">Assessment Mode</label>
            <select
              name="assessment_mode"
              onChange={handleChange}
              value={assessment_mode}
              required
            >
              <option value="">--select--</option>
              <option value="automated">Automated</option>
              <option value="manual">Manual</option>
            </select>
          </span>
          <span>
            <label for="">Total Points</label>
            <input
              type="number"
              name="total_points"
              min="0"
              onChange={handleChange}
              value={total_points}
              required
            />
          </span>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Start BE</label>
            <input
              type="number"
              name="start_be"
              min="0"
              value={start_be}
              onChange={handleExpectationChanges}
              disabled={assessment_mode === "automated"}
              required
            />
          </span>
          <span>
            <label for="">End BE</label>
            <input
              type="number"
              name="end_be"
              min="0"
              onChange={handleExpectationChanges}
              value={end_be}
              disabled={assessment_mode === "automated"}
              required
            />
          </span>
          <span>
            <label for="">Start AE</label>
            <input
              type="number"
              name="start_ae"
              min="0"
              onChange={handleExpectationChanges}
              value={start_ae}
              disabled={assessment_mode === "automated"}
              required
            />
          </span>
          <span>
            <label for="">End AE</label>
            <input
              type="number"
              name="end_ae"
              min="0"
              onChange={handleExpectationChanges}
              value={end_ae}
              disabled={assessment_mode === "automated"}
              required
            />
          </span>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Start ME</label>
            <input
              type="number"
              name="start_me"
              min="0"
              onChange={handleExpectationChanges}
              value={start_me}
              disabled={assessment_mode === "automated"}
              required
            />
          </span>
          <span>
            <label for="">End ME</label>
            <input
              type="number"
              name="end_me"
              min="0"
              onChange={handleExpectationChanges}
              value={end_me}
              disabled={assessment_mode === "automated"}
              required
            />
          </span>
          <span>
            <label for="">Start EE</label>
            <input
              type="number"
              name="start_ee"
              min="0"
              onChange={handleExpectationChanges}
              value={start_ee}
              disabled={assessment_mode === "automated"}
              required
            />
          </span>
          <span>
            <label for="">End EE</label>
            <input
              type="number"
              name="end_ee"
              min="0"
              onChange={handleExpectationChanges}
              value={end_ee}
              disabled={assessment_mode === "automated"}
              required
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <table
          class="table__listing"
          rules="all"
          border="1"
          style={{ maxWidth: "93%" }}
        >
          <thead>
            <tr class="table__listingHeader">
              <th>Adm No:</th>
              <th>Name</th>
              <th style={{ maxWidth: "100px", textAlign: "center" }}>
                Marks Scored
              </th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {gradeStreamLearningAreaStudents?.map((student, index) => (
              <tr class="table__listingItem" key={student?.id}>
                <td>{student?.admission_number}</td>
                <td>{student?.name}</td>
                <td className="tc">
                  <input
                    type="number"
                    min="0"
                    style={{ maxWidth: "100px" }}
                    name="marks_scored"
                    onChange={(e) => handleStudentsMarksChange(e, index)}
                    value={student?.marks_scored}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    style={{ width: "100%" }}
                    name="comments"
                    onChange={(e) => handleStudentsMarksChange(e, index)}
                    value={student?.comments}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {status === "submitted" && (
          <p style={{ textAlign: "center" }}>
            <strong>Note: </strong> You have already submitted results for this
            learning area, to edit results please contact the admin
          </p>
        )}
        <div class="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenSummativeAssessmentStudents(false)}
          >
            Close
          </button>
          <button
            type="submit"
            className="continue__button"
            disabled={status === "submitted"}
          >
            Submit
          </button>
        </div>
      </form>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummativeAssessmentStudents);
