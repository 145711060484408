import moment from "moment";
import React, { useRef, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import PrintAllStudentReportCards from "../../../../../components/common/AssessmentReports/PrintAllStudentReportCards";
import MaxDialog from "../../../../../components/common/MaxDialog";
import ReportHeader from "../../../../../shared/ReportHeader/ReportHeader";
import { formatWithCommas } from "../../../../../shared/sharedFunctions";
import StudentOverallReportCard from "./StudentOverallReportCard";

const OverallSummativeReport = (props) => {
  const { openOverallSummativeReport, reportData } = props;
  const { setOpenOverallSummativeReport } = props;

  const {
    grade_stream_name,
    summative_assessment_details,
    overall_mean_score,
    learning_areas_means,
  } = reportData;

  const [openStudentOverallReportCard, setOpenStudentOverallReportCard] =
    useState(false);
  const [currentStudentResult, setCurrentStudentResult] = useState({});
  const [openPrintAllStudentReportCards, setOpenPrintAllStudentReportCards] =
    useState(false);

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: "Overall summative report",
  });

  const handleOpenStudentOverallReportCard = (student_result) => {
    setCurrentStudentResult(student_result);
    setOpenStudentOverallReportCard(true);
  };

  return (
    <>
      <MaxDialog
        isOpen={openOverallSummativeReport}
        maxWidth="1400px"
        maxHeight="85vh"
      >
        <div class="dialog">
          <table
            className="table__listing"
            rules="all"
            border="1"
            id="overall_summative_report"
            ref={printArea}
          >
            <ReportHeader />
            <thead>
              <tr>
                <th colSpan="100%" style={{ padding: "5px" }}>
                  Grade Stream:{" "}
                  <span style={{ padding: "5px", color: "purple" }}>
                    {grade_stream_name}
                  </span>
                </th>
              </tr>
              <tr>
                <th colSpan="100%" style={{ padding: "5px" }}>
                  School Term:{" "}
                  <span style={{ padding: "5px", color: "purple" }}>
                    {moment(
                      summative_assessment_details?.term_start_date
                    ).format("LL")}{" "}
                    to{" "}
                    {moment(summative_assessment_details?.term_end_date).format(
                      "LL"
                    )}
                  </span>
                </th>
              </tr>
              <tr>
                <th colSpan="100%" style={{ padding: "5px" }}>
                  Assessment:{" "}
                  <span style={{ padding: "5px", color: "purple" }}>
                    {summative_assessment_details?.name} Overall Results
                  </span>
                </th>
              </tr>
            </thead>
            <thead>
              <tr className="table__listingHeader">
                <th className="tc">Position</th>
                <th>Adm No:</th>
                <th>Name</th>
                <th className="tc">
                  CBC Score <br />{" "}
                  <span style={{ fontSize: "0.8rem", color: "#f2e70f" }}>
                    Out of 4
                  </span>
                </th>
                <th className="tc">
                  Total Marks <br />
                  <span style={{ fontSize: "0.8rem", color: "#f2e70f" }}>
                    Out of{" "}
                    {
                      summative_assessment_details?.summative_assessment_total_points
                    }
                  </span>
                </th>

                {reportData?.learning_areas_items?.map((learning_area) => (
                  <th key={learning_area?.id} className="tc">
                    <span>{learning_area?.short_name}</span> <br />
                    <span style={{ fontSize: "0.8rem", color: "#f2e70f" }}>
                      Out of {learning_area?.total_points}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {reportData?.grade_stream_students_overall_results_data?.map(
                (student_result) => (
                  <tr key={student_result?.id} className="table__listingItem">
                    <td className="tc">
                      {student_result?.class_stream_position}
                    </td>
                    <td
                      className="button__sp dodgerblue bd"
                      onClick={() =>
                        handleOpenStudentOverallReportCard(student_result)
                      }
                    >
                      {student_result?.admission_number}
                    </td>
                    <td>{student_result?.student_name}</td>
                    <td className="tc">
                      {formatWithCommas(student_result?.cbc_score)}
                    </td>
                    <td className="tc">
                      {formatWithCommas(student_result?.marks_scored)}
                    </td>

                    {student_result?.learning_areas_results?.map(
                      (learning_area_result_item) => (
                        <td key={learning_area_result_item?.id} className="tc">
                          {learning_area_result_item?.marks_scored}
                        </td>
                      )
                    )}
                  </tr>
                )
              )}
              <tr className="table__listingFooter">
                <th className="tc" colSpan="4">
                  Mean Scores
                </th>

                <th className="tc">{overall_mean_score}</th>
                {/* mean scores for the various learning areas */}
                {learning_areas_means?.map((learning_area_item) => (
                  <th
                    className="tc"
                    key={learning_area_item?.learning_area__short_name}
                  >
                    {" "}
                    {learning_area_item?.mean}
                  </th>
                ))}
              </tr>
            </tbody>
          </table>
          <div class="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenOverallSummativeReport(false)}
            >
              Close
            </button>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button export_to_excel_button"
              table="overall_summative_report"
              filename="overall_summative_report"
              sheet="overall_summative_report"
              buttonText="Export To Excel"
            />
            <button
              type="button"
              className="continue__button"
              onClick={handlePrint}
            >
              Print/Save
            </button>
          </div>

          <div
            className="form__Buttons"
            style={{ justifyContent: "space-around" }}
          >
            <button
              type="button"
              className="continue__button"
              onClick={() => setOpenPrintAllStudentReportCards(true)}
              style={{ minWidth: "fit-content" }}
            >
              Print All Students Report Cards
            </button>
          </div>
        </div>
      </MaxDialog>
      {/* child components */}
      {openStudentOverallReportCard && (
        <StudentOverallReportCard
          openStudentOverallReportCard={openStudentOverallReportCard}
          setOpenStudentOverallReportCard={setOpenStudentOverallReportCard}
          currentStudentResult={currentStudentResult}
          summativeAssessmentDetails={summative_assessment_details}
          gradeStreamName={grade_stream_name}
        />
      )}
      {openPrintAllStudentReportCards && (
        <PrintAllStudentReportCards
          openPrintAllStudentReportCards={openPrintAllStudentReportCards}
          setOpenPrintAllStudentReportCards={setOpenPrintAllStudentReportCards}
          studentResults={
            reportData?.grade_stream_students_overall_results_data
          }
          summativeAssessmentDetails={summative_assessment_details}
          gradeStreamName={grade_stream_name}
        />
      )}
    </>
  );
};

export default OverallSummativeReport;
