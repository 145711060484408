import { connect } from "react-redux";

const TeacherLinks = ({
  Link,
  pathname,
  toggleSubmenu,
  openSubMenu,
  grade_streams,
}) => {
  return (
    <>
      <Link
        to="#"
        className="nav__link"
        onClick={() => toggleSubmenu("Data Areas")}
      >
        <i className="bx bxs-school"></i>
        <span className="nav__name">Data Areas</span>
      </Link>
      {openSubMenu === "Data Areas" && (
        <>
          <Link
            to="/teacher/grade-streams/"
            className={
              `${pathname}` === "/teacher/grade-streams/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Grade Streams</span>
          </Link>
          <Link
            to="/teacher/students/"
            className={
              `${pathname}` === "/teacher/students/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Students</span>
          </Link>
          <Link
            to="/teacher/exams/"
            className={
              `${pathname}` === "/teacher/exams/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Exams</span>
          </Link>
        </>
      )}
      <Link
        to="#"
        className="nav__link"
        onClick={() => toggleSubmenu("Assessments")}
      >
        <i className="bx bx-clipboard"></i>
        <span className="nav__name">Assessments</span>
      </Link>
      {openSubMenu === "Assessments" && (
        <>
          <Link
            to="/teacher/formative-assessments/"
            className={
              `${pathname}` === "/teacher/formative-assessments/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Formative</span>
          </Link>
          <Link
            to="/teacher/summative-assessments/"
            className={
              `${pathname}` === "/teacher/summative-assessments/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Summative</span>
          </Link>
          <Link
            to="/teacher/pending-submissions/"
            className={
              `${pathname}` === "/teacher/pending-submissions/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Submissions</span>
          </Link>
        </>
      )}
      <Link
        to="#"
        className="nav__link"
        onClick={() => toggleSubmenu("Reports")}
      >
        <i className="bx bxs-report"></i>
        <span className="nav__name">Reports</span>
      </Link>
      {openSubMenu === "Reports" && (
        <>
          <Link
            to="/teacher/formative-reports/"
            className={
              `${pathname}` === "/teacher/formative-reports/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Formative</span>
          </Link>
          <Link
            to="/teacher/summative-reports/"
            className={
              `${pathname}` === "/teacher/summative-reports/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Summative</span>
          </Link>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    grade_streams: state?.auth?.user?.grade_streams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherLinks);
